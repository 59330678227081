import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./purchasedProductCard.css";
import { Link } from "react-router-dom";
import { AES } from "crypto-js";

const PurchasedProductCard = ({ product }) => {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const encryptedProduct = AES.encrypt(
    JSON.stringify(product),
    process.env.REACT_APP_SECRET_KEY
  ).toString();
  return (
    <>
      <div className="purchased-product-card-div">
        <div className="purchased-product-card-img-container">
          <img
            src={product.image}
            className="purchased-product-card-img"
            alt="product"
          />
        </div>
        <div className="purchased-product-card-text-container">
          <div className="purchased-product-card-text-content">
            <p className="purchased-product-card-title">{product.name}</p>
            <p className="purchased-product-card-subtitle">
              {product.category}
            </p>
          </div>
          <div className="purchased-product-card-btn-container">
            <button
              className="purchased-product-card-view-btn"
              variant="primary"
              onClick={handleShow}
            >
              View template
            </button>
            <Link to={`/UseTemplate/${encodeURIComponent(encryptedProduct)}`}>
              <button className="button4">Use Template</button>
            </Link>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="purchased-product-card-modal-title">
            {product.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <img
              src={product.image}
              className="purchased-product-card-modal-img"
              alt="product"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="purchased-product-card-view-btn"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PurchasedProductCard;
