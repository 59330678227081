import * as React from "react";
import "./aboutUs.css";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import HeroComponent from "../../components/heroComponent/heroComponent";
import hero2 from "../../assets/hero/hero2.png";
import Curve from "../../components/curve/curve";
import FlippedCurve from "../../components/curve/flippedCurve";
import AboutUsSection from "../../components/aboutUsSection/aboutUsSection";
import AboutUsSectionWhite from "../../components/aboutUsSection/aboutUsSectionWhite";
import Team from "../../components/aboutUsSection/team";
import Banner from "../../components/banner/banner";
import img1 from "../../assets/img/img1.png";
import img3 from "../../assets/img/img3.png";
import SEO from "../../components/seo/seo";
const AboutUs = ({ changeBg }) => {
  const color = "#e4ebf5";
  React.useEffect(() => {
    changeBg(color);
  }, []);
  return (
    <>
      {/* <SEO
        title="Aboout Us"
        description="Learn more about our team, values, and mission"
      /> */}
      <div className="overflow-x stars-bg">
        <Row>
          <HeroComponent
            title="MarCloud Technologies"
            subtitle="Learn more about our team, values, and mission"
            img={hero2}
            imgStyle="aboutus-hero-img"
          />
        </Row>
        <div className="lightblue-bg">
          <Team />
          <FlippedCurve />
          <AboutUsSection
            title="Us, in a nutshell"
            subtitle="Consultants and developers unite
          "
            content={
              <div>
                MarCloud Technologies is a sub-brand of MarCloud Consulting, one
                of the world’s leading Salesforce Marketing Cloud consultancy
                companies. We offer effective solutions in the form of mobile
                apps, web applications and custom on-platform development, as
                well as training solutions to better use our products and
                Salesforce marketing and sales tools.
              </div>
            }
            img={img1}
            width="140px"
          />
          <Curve />
          <AboutUsSectionWhite
            title="Our mission"
            subtitle="To boldly go where no Salesforce consultant has gone before "
            content={
              <div>
                As experienced consultants, we know that however powerful
                Salesforce’s platforms are, there will always be a need to build
                out functionality; to enhance existing features; and to create
                solutions that work better with the needs of organisations who
                want to fully leverage their own data and systems. We felt that
                pain and we wanted to do something interesting and useful in
                this area. So we did. We gathered a team of developers to work
                with our consultants to develop technical solutions to common
                problems that we encounter. Our mission is to provide better
                solutions and a better experience for marketers and sales teams
                using Salesforce platforms (and also make our consultants look
                good!).
              </div>
            }
          />
          <FlippedCurve />
          <AboutUsSection
            title="What we do"
            subtitle="Piloting solutions to enable sales and marketing success"
            content={
              <div>
                MarCloud Technologies was created to provide solutions for many
                of the common pain points endured by marketing and sales teams
                who use Salesforce products. We all know that Salesforce
                provides the most complete ecosystem when it comes to sales and
                marketing, but we also know that sometimes we find ourselves
                stuck looking for a solution that doesn’t exist or where the
                workaround is inefficient. That’s where MarCloud Technologies
                fits in. Our team of expert software engineers work in
                conjunction with our experienced and talented consultants to
                provide genuinely useful tools to help you with your day-to-day
                marketing and sales workflows.
              </div>
            }
            img={img3}
            width="140px"
          />
          <Curve />
          <AboutUsSectionWhite
            title="Mobile development"
            subtitle="Brought to you by technical and Salesforce experts"
            content={
              <div>
                It’s not uncommon for a salesperson or marketer to spend as much
                time interacting with technology via their smartphone as their
                PC. At MarCloud Technologies, we embrace mobile as a valuable
                tool that most marketers and salespeople will use routinely.
                That’s why we have a team of talented developers who specialise
                in developing niche applications to support the use of
                Salesforce’s marketing and sales platforms. Check out our{" "}
                <span className="aboutus-link">
                  <Link to="/PardotProspectScanner">
                    Pardot Prospect Scanner
                  </Link>
                </span>
                ; our new powerful scanning solution for Marketing Cloud Account
                Engagement that can be used to scan business cards directly into
                Pardot, and also functions as an event attendance scanner so you
                can fully integrate live events, booth sign-ups and other
                attendance-based activities with your Salesforce ecosystem.
              </div>
            }
          />
          <FlippedCurve />
          <AboutUsSection
            title="On-platform development"
            subtitle="Creating robust and powerful automation solutions"
            content={
              <div>
                <p>
                  Alongside our mobile apps, we have an on-platform development
                  team that works with clients to develop robust, powerful
                  automation solutions for Salesforce when the out-of-box
                  solution simply isn’t enough. Whether you are looking for
                  custom web forms, automated integrations with third party
                  solutions, or scripts to help you migrate from legacy
                  software,we have experts who can provide you with a hi-tech
                  solution. To find out more, contact our consultancy team who
                  will be able to understand your requirements and put together
                  a solutions package.
                </p>

                <Link to="/ContactUs">
                  <button className="button4">Get in touch </button>
                </Link>
              </div>
            }
          />
          <Curve />
          <AboutUsSectionWhite
            title="Training and development"
            subtitle="On-demand training courses to upskill your team"
            content={
              <div>
                MarCloud Technologies is building a growing library of
                specialist self-serve training courses for Marketing Cloud and
                Pardot. Delivered by specialists in their field, you can easily
                upskill your team to get the best out of some of the more
                technical aspects of Marketing Cloud.
              </div>
            }
          />
          <FlippedCurve />
          <Banner />
        </div>
      </div>
    </>
  );
};
export default AboutUs;
