import React from "react";
import "./screenshots.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import login from "../../../assets/ss/login.png";
import qr from "../../../assets/ss/qr.png";
import success from "../../../assets/ss/success.png";
import home from "../../../assets/ss/home.png";
import prospect from "../../../assets/ss/prospect.png";
import campaign from "../../../assets/ss/campaign.png";
import list from "../../../assets/ss/list.png";

import Carousel from "react-bootstrap/Carousel";

const Screenshots = () => {
  return (
    <div className="ss-div">
      <Row className="margin-0 padding-0">
        <Col
          lg="12"
          className="margin-0 padding-0 flex justify-center text-align-center"
        >
          <div className="pps-ss-div">
            <h2> Psst… here’s a sneak peek</h2>
            <div className="orange-line"></div>
            <h3>
            A clean user interface and premium features await.
            </h3>
          </div>
        </Col>
      </Row>
      <Carousel className="pps-carousel">
        <Carousel.Item>
          <div className="flex justify-center align pps-carousel-img-div">
            <img className="d-block" src={login} alt="First slide" />
            <img className="d-block" src={success} alt="First slide" />
          </div>
          <Carousel.Caption>
            <h3>Simple and Authorised Login</h3>
            <p>Secure access via OAuth 2.0</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="flex justify-center align pps-carousel-img-div">
            <img className="d-block" src={home} alt="First slide" />
            <img className="d-block" src={qr} alt="First slide" />
          </div>
          <Carousel.Caption>
            <h3>Scan or upload photos from your Phone</h3>
            <p>
              Get the option to scan a prospect or enter their information
              manually
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="flex justify-center align pps-carousel-img-div">
            <img className="d-block" src={prospect} alt="First slide" />
          </div>
          <Carousel.Caption>
            <h3>Review Prospect</h3>
            <p>
              Edit, Add or Remove system and custom fields
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="flex justify-center align  pps-carousel-img-div">
            <img className="d-block" src={campaign} alt="First slide" />
            <img className="d-block" src={list} alt="First slide" />
          </div>
          <Carousel.Caption>
            <h3>Assign scan to list or campaign</h3>
            <p>Assign prospect to a list or campaign of your choice</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Screenshots;
