import React from "react";
import "./homeHero.css";
import hero from "../../../assets/hero/hero1.png";
import clouds from "../../../assets/bg/clouds.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="stars-bg margin-0 padding-0 hero-div">
      <Row className="text-align-center div-width hero-top">
        <Col
          lg="6"
          md="12"
          className="flex justify-center align hero-top-left-div"
        >
          <div>
            <h1 className="margin-0">MarCloud Technologies,</h1>
            <h1>ready for take-off</h1>
            <h3>
              Discover out-of-this-world technical solutions for
              <span> Salesforce CRM</span> &{" "}
              <span> Salesforce Marketing Cloud</span>
            </h3>
            <Link to="/EmailTemplates">
              <button className="button4">Buy Templates</button>
            </Link>
          </div>
        </Col>
        <Col lg="6" md="12" className="flex justify-center hero-top-right-div">
          <img src={hero} className="hero-space-ship" alt="space ship" />
        </Col>
      </Row>
      <Row className="hero-bottom margin-0 padding-0">
        <Col lg="12" className="flex justify-center overflow-x hero-clouds-div">
          <div>
            <img src={clouds} className="hero-clouds" alt="cloud" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Hero;
