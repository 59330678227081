import * as React from "react";
import "./emailTemplateCard.css";
import Col from "react-bootstrap/Col";

const DummyEmailTemplateCard = (props) => {
  return (
    <Col xs="12" md="6" lg="4" className="et-col">
      <div className="et-card-div">
        <div className="et-card-img-container">
          <img src={props.img} className="et-card-img" alt="email template" />
        </div>
        <div className="et-card-text-container">
          <div className="et-card-text-content">
            <p className="et-card-title">{props.name}</p>
            <p className="et-card-subtitle">{props.description}</p>
          </div>
          <div className="et-card-btn-container">
            <button className="et-view-btn" variant="primary">
              Coming soon
            </button>
          </div>
        </div>
      </div>
    </Col>
  );
};
export default DummyEmailTemplateCard;
