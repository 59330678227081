import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

const ChooseBuid = ({ buids, buidClick, chosenBuid }) => {
  return (
    <div className="preview-div text-align-left">
      <h3 className="flex align">Choose a Business Unit</h3>
      {buids.length === 0 && (
        <div className="preview-nobu">
          <div>
            You don't have any business units saved to your MarCloud
            Technologies account yet.
          </div>
          <div>
            In order to use your purchased products you need to create a new
            business unit from your <Link to="/Account">Account</Link> page.
          </div>
        </div>
      )}
      <Row className="padding-0 margin-0">
        {buids.map((buid, index) => (
          <>
            <Col
              key={index}
              lg={6}
              md={4}
              sm={6}
              xs={12}
              className="flex justify-center align text-align-center preview-left-col"
            >
              <div
                onClick={() => buidClick(buid)}
                className={`preview-card ${
                  chosenBuid === buid && "preview-card-active"
                }`}
              >
                <p className="bold title">{buid.shortName}</p>
                <p className="subtitle">{buid.longName}</p>
                <p className="address">
                  {buid.address1}, {buid.address2}
                </p>
              </div>
            </Col>
          </>
        ))}
      </Row>
    </div>
  );
};

export default ChooseBuid;
