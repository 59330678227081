import * as React from "react";
import "./privacyPolicy.css";

const PrivacyPolicy = ({ changeBg }) => {
  const color = "transparent";
  React.useEffect(() => {
    changeBg(color);
  });
  return (
    <div>
      <div className="div-width flex justify-center align ">
        <div className="pp">
          <h2 className="title">Privacy Policy</h2>
          <div className="blue-line"></div>
          <div className="legal-div">
            <div>
              MarCloud Limited understands that your privacy is important to you
              and that you care about how your personal data is used. We respect
              and value the privacy of everyone who visits this website,
              https://marcloudconsulting.com (“Our Site”) and will only collect
              and use personal data in ways that are described here, and in a
              way that is consistent with our obligations and your rights under
              the law.
            </div>
            <div
              id="ppms_cm_privacy_settings"
              class="ppms_cm_privacy_settings_widget"
              data-editor-centralize="true"
              data-main-container="true"
              data-root="true"
            >
              <div
                class="ppms_cm_privacy_settings_widget_content"
                data-disable-select="true"
                id="ppms-97245a0a-bd75-4111-919d-b1dc5a30f5a3"
              >
                <h1
                  class="ppms_cm_privacy_settings_form_link_header"
                  id="ppms_cm_privacy_settings_form_link_header_id"
                >
                  Privacy settings
                </h1>
                <p
                  class="ppms_cm_privacy_settings_form_link_text"
                  id="ppms_cm_privacy_settings_form_link_text_id"
                >
                  We collect and process your data on this site to better
                  understand how it is used. We always ask you for consent to do
                  that. You can change your privacy settings here.
                </p>
                <button
                  class="ppms_cm_privacy_settings_button_show"
                  id="ppms_cm_privacy_settings_button"
                >
                  Manage settings
                </button>
              </div>
            </div>
            <div>
              Please read this Privacy Policy carefully and ensure that you
              understand it. Your acceptance of this Privacy Policy is requested
              upon entering our site.
            </div>

            <ul className="margin-0">
              <li>
                <p className="bold">Definitions and Interpretation</p>
                <div>
                  In this Policy the following terms shall have the following
                  meanings:
                </div>
                <table className="pp-table">
                  <tr>
                    <th>"Account"</th>
                    <td>
                      means an account required to access and/or use certain
                      areas and features of our Site;
                    </td>
                  </tr>
                  <tr>
                    <th>"Cookie"</th>
                    <td>
                      means a small text file placed on your computer or device
                      by our Site when you visit certain parts of our Site
                      and/or when you use certain features of our Site. Details
                      of the Cookies used by our Site are set out in Part 14,
                      below; and
                    </td>
                  </tr>
                  <tr>
                    <th>"Cookie Law"</th>
                    <td>
                      means the relevant parts of the Privacy and Electronic
                      Communications  EC Directive) Regulations 2003;
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <p className="bold">Information About Us</p>
                <div>
                  Our Site is owned and operated by MarCloud Limited, a limited
                  company registered in England under company number 11505110
                </div>
                <div>
                  Registered address: C/O Uhy Hacker Young St James Building, 79
                  Oxford Street, Manchester, United Kingdom, M1 6HT
                </div>
                <div>
                  Main trading address: The Plaza, 100 Old Hall St, Liverpool L3
                  9QJ
                </div>
                <div>Email address: privacy@marcloudconsulting.com</div>
                <div>
                  Postal address: The Plaza, 100 Old Hall St, Liverpool L3 9QJ
                </div>
                <div>Representative: Jochen Curth</div>
              </li>
              <li>
                <p className="bold">What Does This Policy Cover?</p>
                <div>
                  This Privacy Policy applies only to your use of our Site. Our
                  Site may contain links to other websites. Please note that we
                  have no control over how your data is collected, stored, or
                  used by other websites and we advise you to check the privacy
                  policies of any such websites before providing any data to
                  them.
                </div>
              </li>
              <li>
                <p className="bold">What Is Personal Data?</p>
                <div>
                  Personal data is defined by the UK GDPR and the Data
                  Protection Act 2018 (collectively, “the Data Protection
                  Legislation”) as ‘any information relating to an identifiable
                  person who can be directly or indirectly identified in
                  particular by reference to an identifier’.
                </div>
                <div>
                  Personal data is, in simpler terms, any information about you
                  that enables you to be identified. Personal data covers
                  obvious information such as your name and contact details, but
                  it also covers less obvious information such as identification
                  numbers, electronic location data, and other online
                  identifiers.
                </div>
              </li>
              <li>
                <p className="bold">What Are My Rights?</p>
                <div>
                  Under the Data Protection Legislation, you have the following
                  rights, which we will always work to uphold:
                </div>
                <div>
                  The right to be informed about our collection and use of your
                  personal data. This Privacy Policy should tell you everything
                  you need to know, but you can always contact us to find out
                  more or to ask any questions using the details in Part 15.
                </div>
                <div>
                  The right to access the personal data we hold about you. Part
                  13 will tell you how to do this.
                </div>
                <div>
                  The right to have your personal data rectified if any of your
                  personal data held by us is inaccurate or incomplete. Please
                  contact us using the details in Part 15 to find out more.
                </div>
                <div>
                  The right to be forgotten, i.e., the right to ask us to delete
                  or otherwise dispose of any of your personal data that we
                  hold. Please contact us using the details in Part 15 to find
                  out more.
                </div>
                <div>
                  The right to restrict (i.e., prevent) the processing of your
                  personal data.
                </div>
                <div>
                  The right to object to us using your personal data for a
                  particular purpose or purposes.
                </div>
                <div>
                  The right to withdraw consent. This means that, if we are
                  relying on your consent as the legal basis for using your
                  personal data, you are free to withdraw that consent at any
                  time.
                </div>
                <div>
                  The right to data portability. This means that, if you have
                  provided personal data to us directly, we are using it with
                  your consent or for the performance of a contract, and that
                  data is processed using automated means, you can ask us for a
                  copy of that personal data to re-use with another service or
                  business in many cases.
                </div>
                <div>
                  Rights relating to automated decision-making and profiling. We
                  do not use your personal data in this way.
                </div>
                <div>
                  For more information about our use of your personal data or
                  exercising your rights as outlined above, please contact us
                  using the details provided in Part 15.
                </div>
                <div>
                  It is important that your personal data is kept accurate and
                  up-to-date. If any of the personal data we hold about you
                  changes, please keep us informed as long as we have that data.
                </div>
                <div>
                  Further information about your rights can also be obtained
                  from the Information Commissioner’s Office or your local
                  Citizens Advice Bureau.
                </div>
                <div>
                  If you have any cause for complaint about our use of your
                  personal data, you have the right to lodge a complaint with
                  the Information Commissioner’s Office. We would welcome the
                  opportunity to resolve your concerns ourselves, however, so
                  please contact us first, using the details in Part 15.
                </div>
              </li>
              <li>
                <p className="bold">What Data Do You Collect and How?</p>
                <div>
                  Depending upon your use of our Site, we may collect and hold
                  some or all of the personal and non-personal data set out in
                  the table below, using the methods also set out in the table.
                  Please also see below for more information about our use of
                  Cookies and similar technologies and our Cookie Policy. We do
                  not collect any ‘special category’ or ‘sensitive’ personal
                  data, personal data relating to children or data relating to
                  criminal convictions and/or offences.
                </div>
                <table className="pp-table">
                  <tr>
                    <th>Data Collected</th>
                    <th>How We Collect the Data</th>
                  </tr>
                  <tr>
                    <td>Identity Information including first and last name.</td>
                    <td>Forms on the website</td>
                  </tr>
                  <tr>
                    <td>
                      Contact information including email address and telephone
                      number.
                    </td>
                    <td>Forms on the website</td>
                  </tr>
                  <tr>
                    <td>
                      Business information including business name, job title
                      and profession.
                    </td>
                    <td>Forms on the website</td>
                  </tr>
                  <tr>
                    <td>Profile information including business interests.</td>
                    <td>Account Engagement website tracking</td>
                  </tr>
                  <tr>
                    <td>
                      Technical information including IP address, browser type
                      and version, operating system
                    </td>
                    <td>Piwik Pro and Account Engagement website tracking</td>
                  </tr>
                  <tr>
                    <td>
                      Transactional data related to your purchases, including
                      your name, billing address, shipping address, email
                      address, and payment information
                    </td>
                    <td>Forms on the website</td>
                  </tr>
                </table>
              </li>

              <li>
                <p className="bold">How Do You Use My Personal Data?</p>
                <div>
                  Under the Data Protection Legislation, we must always have a
                  lawful basis for using personal data. The following table
                  describes how we may use your personal data, and our lawful
                  bases for doing so:
                </div>
                <table className="pp-table">
                  <tr>
                    <th>What We Do</th>
                    <th>What Data We Use</th>
                    <th>Our Lawful Basis</th>
                  </tr>
                  <tr>
                    <td>
                      Personalising and tailoring your experience on our Site.
                    </td>
                    <td>First name, last name, job title, industry, company</td>
                    <td>
                      To better illustrate our services and how they’re
                      applicable to your industry or sector
                    </td>
                  </tr>
                  <tr>
                    <td>Administering our business</td>
                    <td>Processing Employee and Customer Data</td>
                    <td>
                      Performance of the contract, and/or Legitimate Interest
                      being, processing employee or client data; direct
                      marketing; or administrative transfers within a group of
                      companies, and further in relation to fraud prevention;
                      network and information security; and indicating possible
                      criminal acts or threats to public security
                    </td>
                  </tr>
                  <tr>
                    <td>Supplying our services to you.</td>
                    <td>Processing Employee and Customer Data</td>
                    <td>
                      Performance of the contract, and/or Legitimate Interest
                      being, processing employee or client data; direct
                      marketing; or administrative transfers within a group of
                      companies, and further in relation to fraud prevention;
                      network and information security; and indicating possible
                      criminal acts or threats to public security
                    </td>
                  </tr>
                  <tr>
                    <td>Communicating with you.</td>
                    <td>Email address, first name, last name, job title</td>
                    <td>
                      We ask for consent to market to you on all of our forms
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Supplying you with information by email you have opted-in
                      to (you may opt-out at any time by clicking unsubscribe in
                      the emails we send).
                    </td>
                    <td>Email address, first name, last name, job title</td>
                    <td>
                      Marketing, Information updates, Introduction to third
                      parties.
                    </td>
                  </tr>
                </table>
                <div>
                  With your permission or where permitted by law, we may also
                  use your personal data for marketing purposes, which may
                  include contacting you by email with information, news, and
                  offers on our services. You will not be sent any unlawful
                  marketing or spam. We will always work to fully protect your
                  rights and comply with our obligations under the Data
                  Protection Legislation and the Privacy and Electronic
                  Communications (EC Directive) Regulations 2003, and you will
                  always have the opportunity to opt-out. We will always obtain
                  your express opt-in consent before sharing your personal data
                  with third parties for marketing purposes and you will be able
                  to opt-out at any time.
                </div>
                <div>
                  Third Parties including Account Engagement, Piwik Proand
                  LinkedIn whose content appears on our Site may use third-party
                  Cookies, as detailed below in Part 14. Please refer to Part 14
                  for more information on controlling cookies. Please note that
                  we do not control the activities of such third parties, nor
                  the data that they collect and use themselves, and we advise
                  you to check the privacy policies of any such third parties.
                </div>
                <div>
                  We will only use your personal data for the purpose(s) for
                  which it was originally collected unless we reasonably believe
                  that another purpose is compatible with that or those original
                  purpose(s) and need to use your personal data for that
                  purpose. If we do use your personal data in this way and you
                  wish us to explain how the new purpose is compatible with the
                  original, please contact us using the details in Part 15.
                </div>
                <div>
                  If we need to use your personal data for a purpose that is
                  unrelated to, or incompatible with, the purpose(s) for which
                  it was originally collected, we will inform you and explain
                  the legal basis which allows us to do so.
                </div>
                <div>
                  In some circumstances, where permitted or required by law, we
                  may process your personal data without your knowledge or
                  consent. This will only be done within the bounds of the Data
                  Protection Legislation and your legal rights.
                </div>
              </li>

              <li>
                <p className="bold">How Long Will You Keep My Personal Data?</p>
                <div>
                  We will not keep your personal data for any longer than is
                  necessary in light of the reason(s) for which it was first
                  collected. Your personal data will therefore be kept for the
                  following periods (or, where there is no fixed period, the
                  following factors will be used to determine how long it is
                  kept):
                </div>
                <table className="pp-table">
                  <tr>
                    <th>Type of Data</th>
                    <th>How Long We Keep It</th>
                  </tr>
                  <tr>
                    <td>Identity Information including name and title.</td>
                    <td>3 years</td>
                  </tr>
                  <tr>
                    <td>
                      Contact information including address, email address and
                      telephone number.
                    </td>
                    <td>3 years</td>
                  </tr>
                  <tr>
                    <td>
                      Business information including business name, job title,
                      profession and seniority.
                    </td>
                    <td>3 years</td>
                  </tr>
                  <tr>
                    <td>
                      Technical information including IP address and browser
                      type.
                    </td>
                    <td>3 years</td>
                  </tr>
                </table>
              </li>
              <li>
                <p className="bold">
                  How and Where Do You Store or Transfer My Personal Data?
                </p>
                <div>
                  We will store some of your personal data within the European
                  Economic Area (the “EEA”). The EEA consists of all EU member
                  states, plus Norway, Iceland, and Liechtenstein. This means
                  that your personal data will be fully protected under the EU
                  GDPR and/or to equivalent standards by law. Transfers of
                  personal data to the EEA from the UK are permitted without
                  additional safeguards.
                </div>
                <div>
                  We will use specific approved contracts which ensure the same
                  levels of personal data protection that apply under the Data
                  Protection Legislation. For further information, please refer
                  to the Information Commissioner’s Office.
                </div>

                <div>
                  Please contact us using the details below in Part 15 for
                  further information about the particular data protection
                  safeguard[s] used by us when transferring your personal data
                  to a third country.
                </div>

                <div>
                  The security of your personal data is essential to us, and to
                  protect your data, we take a number of important measures,
                  including the following:
                </div>
                <ul>
                  <li>
                    <div>
                      Limiting access to your personal data to those employees,
                      agents, contractors, and other third parties with a
                      legitimate need to know and ensuring that they are subject
                      to duties of confidentiality;
                    </div>
                  </li>
                  <li>
                    <div>
                      procedures for dealing with data breaches (the accidental
                      or unlawful destruction, loss, alteration, unauthorised
                      disclosure of, or access to, your personal data) including
                      notifying you and/or the Information Commissioner’s Office
                      where we are legally required to do so;
                    </div>
                  </li>
                </ul>
              </li>

              <li>
                <p className="bold">Do You Share My Personal Data?</p>
                <div>
                  We will not share any of your personal data with any third
                  parties for any purposes, subject to the following
                  exception[s].
                </div>
                <div>
                  If we sell, transfer, or merge parts of our business or
                  assets, your personal data may be transferred to a third
                  party. Any new owner of our business may continue to use your
                  personal data in the same way(s) that we have used it, as
                  specified in this Privacy Policy.
                </div>
                <div>
                  In some limited circumstances, we may be legally required to
                  share certain personal data, which might include yours, if we
                  are involved in legal proceedings or complying with legal
                  obligations, a court order, or the instructions of a
                  government authority.
                </div>
                <div>
                  We may sometimes contract with the following third parties to
                  supply certain services.
                </div>
                <table className="pp-table">
                  <tr>
                    <th>Recipient</th>
                    <th>Activity Carried Out</th>
                    <th>Sector</th>
                    <th>Location</th>
                  </tr>
                  <tr>
                    <td>
                      Freelance MarCloud Marketing Manager – data processor
                    </td>
                    <td>Email marketing via Account Engagement</td>
                    <td>Marketing</td>
                    <td>Australia</td>
                  </tr>
                </table>
                <div>
                  If any of your personal data is shared with a third party, as
                  described above, we will take steps to ensure that your
                  personal data is handled safely, securely, and in accordance
                  with your rights, our obligations, and the third party’s
                  obligations under the law, as described above in Part 9.
                </div>
                <div>
                  If any personal data is transferred outside of the UK, we will
                  take suitable steps in order to ensure that your personal data
                  is treated just as safely and securely as it would be within
                  the UK and under the Data Protection Legislation, as explained
                  above in Part 9.
                </div>
                <div>
                  If we sell, transfer, or merge parts of our business or
                  assets, your personal data may be transferred to a third
                  party. Any new owner of our business may continue to use your
                  personal data in the same way(s) that we have used it, as
                  specified in this Privacy Policy.
                </div>
                <div>
                  In some limited circumstances, we may be legally required to
                  share certain personal data, which might include yours, if we
                  are involved in legal proceedings or complying with legal
                  obligations, a court order, or the instructions of a
                  government authority.
                </div>
              </li>
              <li>
                <p className="bold">How Can I Control My Personal Data?</p>
                <div>
                  In addition to your rights under the Data Protection
                  Legislation, set out in Part 5, when you submit personal data
                  via our Site, you may be given options to restrict our use of
                  your personal data. In particular, we aim to give you strong
                  controls on our use of your data for direct marketing purposes
                  (including the ability to opt-out of receiving emails from us
                  which you may do by unsubscribing using the links provided in
                  our emails and at the point of providing your details.
                </div>
                <div>
                  You may also wish to sign up to one or more of the preference
                  services operating in the UK: The Telephone Preference Service
                  (“the TPS”), the Corporate Telephone Preference Service (“the
                  CTPS”), and the Mailing Preference Service (“the MPS”). These
                  may help to prevent you receiving unsolicited marketing.
                  Please note, however, that these services will not prevent you
                  from receiving marketing communications that you have
                  consented to receiving.
                </div>
              </li>
              <li>
                <p className="bold">Can I Withhold Information?</p>
                <div>
                  You may access certain areas of our Site without providing any
                  personal data at all. However, to use all features and
                  functions available on our Site you may be required to submit
                  or allow for the collection of certain data.
                </div>
                <div>
                  You may restrict our use of Cookies. For more information, see
                  Part 14 and our Cookie Policy.
                </div>
              </li>
              <li>
                <p className="bold">How Can I Access My Personal Data?</p>
                <div>
                  If you want to know what personal data we have about you, you
                  can ask us for details of that personal data and for a copy of
                  it (where any such personal data is held). This is known as a
                  “subject access request”.
                </div>
                <div>
                  All subject access requests should be made in writing and sent
                  to the email or postal addresses shown in Part 15.
                </div>

                <div>
                  There is not normally any charge for a subject access request.
                  If your request is ‘manifestly unfounded or excessive’ (for
                  example, if you make repetitive requests) a fee may be charged
                  to cover our administrative costs in responding.
                </div>

                <div>
                  We will respond to your subject access request within 3 weeks
                  and, in any case, not more than one month of receiving it.
                  Normally, we aim to provide a complete response, including a
                  copy of your personal data within that time. In some cases,
                  however, particularly if your request is more complex, more
                  time may be required up to a maximum of three months from the
                  date we receive your request. You will be kept fully informed
                  of our progress.
                </div>
              </li>
              <li>
                <p className="bold">How Do You Use Cookies?</p>
                <div>
                  Our Site may place and access certain first-party Cookies on
                  your computer or device. First-party Cookies are those placed
                  directly by us and are used only by us. We use Cookies to
                  facilitate and improve your experience of our Site and to
                  provide and improve our services. We have carefully chosen
                  these Cookies and have taken steps to ensure that your privacy
                  and personal data is protected and respected at all times.
                </div>
                <div>
                  By using our Site, you may also receive certain third-party
                  Cookies on your computer or device. Third-party Cookies are
                  those placed by websites, services, and/or parties other than
                  us. Third-party Cookies are used on our Site for marketing
                  automation purposes. For more details, please refer to the
                  table below. These Cookies are not integral to the functioning
                  of our Site and your use and experience of our Site will not
                  be impaired by refusing consent to them.
                </div>
                <div>
                  All Cookies used by and on our Site are used in accordance
                  with current Cookie Law.
                </div>
                <div>
                  Before Cookies are placed on your computer or device, you will
                  be shown a pop-up requesting your consent to set those
                  Cookies. By giving your consent to the placing of Cookies you
                  are enabling us to provide the best possible experience and
                  service to you. You may, if you wish, deny consent to the
                  placing of Cookies; however certain features of our Site may
                  not function fully or as intended.
                </div>
                <div>
                  Certain features of our Site depend on Cookies to function.
                  Cookie Law deems these Cookies to be “strictly necessary”.
                  These Cookies are shown in the table below. Your consent will
                  not be sought to place these Cookies, but it is still
                  important that you are aware of them. You may still block
                  these Cookies by changing your internet browser’s settings as
                  detailed below, but please be aware that our Site may not work
                  properly if you do so. We have taken great care to ensure that
                  your privacy is not at risk by allowing them.
                </div>
                <div>
                  The following first-party Cookies may be placed on your
                  computer or device:
                </div>
                <table className="pp-large-table">
                  <thead>
                    <tr>
                      <th>Provider</th>
                      <th>Name of Cookie</th>
                      <th>Purpose</th>
                      <th>Strictly Necessary</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Account</th>
                      <th>Account</th>
                      <td>A session cookie named</td>
                      <td>yes</td>
                    </tr>
                    <tr>
                      <th className="pp-no-border">Engagement</th>
                      <th>Engagement</th>
                      <td>
                        Account Engagement is set in your browser while you’re
                        logged in to Account Engagement as a user or when a
                        visitor accesses a form, landing page, or page with
                        Account Engagement tracking code. The cookie denotes an
                        active session and isn’t used for tracking.
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <th></th>
                      <th>{`Lpv<accountid>`} </th>
                      <td>
                        This LPV cookie is set to keep Account Engagement from
                        tracking multiple page views on a single asset over a
                        30-minute session. For example, if a visitor reloads a
                        landing page several times over a 30-minute period, this
                        cookie keeps each reload from being tracked as a page
                        view.
                      </td>
                      <td>yes</td>
                    </tr>
                    <tr>
                      <th></th>
                      <th>{`pi_opt_in<accou ntid>`} </th>
                      <td>
                        If Tracking Opt-in preferences is enabled, the pi_opt_in
                        cookie is set with a true or false value when the
                        visitor opts in or out of tracking. If a visitor opts
                        in, the value is set to true, and the visitor is cookied
                        and tracked. If the visitor opts out or ignores the
                        opt-in banner, the opt-in cookie value is set to false.
                        The visitor cookie is disabled, and the visitor is not
                        tracked.
                      </td>
                      <td>yes</td>
                    </tr>
                    <tr>
                      <th></th>
                      <th>{`visitor_id<accountid>`}</th>
                      <td>
                        The visitor cookie includes a unique visitor ID and the
                        unique identifier for your account. For example, the
                        cookie name visitor_id12345 stores the visitor ID
                        1010101010. The account identifier, 12345, makes sure
                        that the visitor is tracked on the correct Account
                        Engagement account. The visitor value is the visitor_id
                        in your Account Engagement account. This cookie is set
                        for visitors by the Account Engagement tracking code.
                      </td>
                      <td>yes</td>
                    </tr>
                    <tr>
                      <th></th>
                      <th>{`visitor_id<accountid>-hash`}</th>
                      <td>
                        The visitor hash cookie contains the account ID and
                        stores a unique hash. For example, the cookie name
                        visitor_id12345-hash stores the hash
                        “855c3697d9979e78ac404c4b a2c66533”, and the account ID
                        is 12345. This cookie is a security measure to make sure
                        that a malicious user can’t fake a visitor from Account
                        Engagement and access corresponding prospect
                        information.
                      </td>
                      <td>yes</td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  and the following third-party Cookies may be placed on your
                  computer or device:
                </div>
                <table className="pp-large-table">
                  <tr>
                    <th>Name of Cookie</th>
                    <th>Provider</th>
                    <th>Purpose</th>
                  </tr>
                  <tr>
                    <th>LinkedIn Insight Tag</th>
                    <td>LinkedIn</td>
                    <td>
                      Marketing, tracking conversions from our paid ads. More
                      information on LinkedIn cookies here:
                      https://www.linkedin.com/ legal/l/cookie-table
                    </td>
                  </tr>
                  <tr>
                    <th>Account Engagement</th>
                    <td>Account Engagement</td>
                    <td>
                      A session cookie named Account Engagement is set in your
                      browser while you’re logged in to Account Engagement as a
                      user or when a visitor accesses a form, landing page, or
                      page with Account Engagement tracking code. The cookie
                      denotes an active session and isn’t used for tracking.
                    </td>
                  </tr>
                  <tr>
                    <th>{`Lpv<accountid>`}</th>
                    <td>Account Engagement</td>
                    <td>
                      This LPV cookie is set to keep Account Engagement from
                      tracking multiple page views on a single asset over a
                      30-minute session. For example, if a visitor reloads a
                      landing page several times over a 30-minute period, this
                      cookie keeps each reload from being tracked as a page
                      view.
                    </td>
                  </tr>
                  <tr>
                    <th>{`pi_opt_in<accountid>`}</th>
                    <td>Account Engagement</td>
                    <td>
                      If Tracking Opt-in preferences is enabled, the pi_opt_in
                      cookie is set with a true or false value when the visitor
                      opts in or out of tracking. If a visitor opts in, the
                      value is set to true, and the visitor is cookied and
                      tracked. If the visitor opts out or ignores the opt-in
                      banner, the opt-in cookie value is set to false. The
                      visitor cookie is disabled, and the visitor is not
                      tracked.
                    </td>
                  </tr>
                  <tr>
                    <th>{`visitor_id<accountid>`}</th>
                    <td>Account Engagement</td>
                    <td>
                      The visitor cookie includes a unique visitor ID and the
                      unique identifier for your account. For example, the
                      cookie name visitor_id12345 stores the visitor ID
                      1010101010. The account identifier, 12345, makes sure that
                      the visitor is tracked on the correct Account Engagement
                      account. The visitor value is the visitor_id in your
                      Account Engagement account. This cookie is set for
                      visitors by the Account Engagement tracking code.
                    </td>
                  </tr>
                  <tr>
                    <th>{`visitor_id<accountid>-ha sh`}</th>
                    <td>Account Engagement</td>
                    <td>
                      The visitor hash cookie contains the account ID and stores
                      a unique hash. For example, the cookie name
                      visitor_id12345-hash stores the hash “855c3697d9979e78ac40
                      4c4ba2c66533”, and the account ID is 12345. This cookie is
                      a security measure to make sure that a malicious user
                      can’t fake a visitor from Account Engagement and access
                      corresponding prospect information.
                    </td>
                  </tr>
                </table>
                <div>
                  Our Site uses analytics services provided by Account
                  Engagement and Piwik Pro. Website analytics refers to a set of
                  tools used to collect and analyse anonymous usage information,
                  enabling us to better understand how our Site is used. This,
                  in turn, enables us to improve our Site and the services
                  offered through it.
                </div>
                <div>
                  The analytics services used by our Site uses Cookies to gather
                  the required information. You do not have to allow us to use
                  these Cookies, however whilst our use of them does not pose
                  any risk to your privacy or your safe use of our Site, it does
                  enable us to continually improve our Site, making it a better
                  and more useful experience for you.
                </div>
                <div>
                  The analytics services used by our Site uses the following
                  Cookies:
                </div>
                <table className="pp-large-table">
                  <tr>
                    <th>Cookie</th>
                    <th>Non-anonymous visitor</th>
                    <th>Anonymous visitor</th>
                    <th>Default cookie lifetime</th>
                    <th>Cookie type</th>
                  </tr>
                  <tr>
                    <td>{`_pk_id.<appID>.<domainHash>`}</td>
                    <td>Always</td>
                    <td>
                      <div>
                        Always (1) (if the 30-minute cookie option is turned on)
                      </div>
                      <div>Never (if the cookie-free option is turned on)</div>
                    </td>
                    <td>
                      <div>13 months for non-anonymous visitors</div>
                      <div>
                        30 minutes for anonymous visitors if the 30-minute
                        cookie option is turned on
                      </div>
                    </td>
                    <td>First-party</td>
                  </tr>
                  <tr>
                    <td>{`ppms_privacy_<appID>`}</td>
                    <td>Always</td>
                    <td>Always</td>
                    <td>12 months</td>
                    <td>First-party</td>
                  </tr>
                  <tr>
                    <td>stg_traffic_source_priority</td>
                    <td>Optional</td>
                    <td>Optional</td>
                    <td>30 minutes</td>
                    <td>First-party</td>
                  </tr>
                  <tr>
                    <td>stg_last_interaction</td>
                    <td>Optional</td>
                    <td>Optional</td>
                    <td>365 days</td>
                    <td>First-party</td>
                  </tr>
                  <tr>
                    <td>stg_returning_visitor</td>
                    <td>Optional</td>
                    <td>Optional</td>
                    <td>365 days</td>
                    <td>First-party</td>
                  </tr>
                  <tr>
                    <td>{`stg_fired__<conditionID>`}</td>
                    <td>Optional</td>
                    <td>Optional</td>
                    <td>Until the session ends</td>
                    <td>First-party</td>
                  </tr>
                  <tr>
                    <td>stg_utm_campaign</td>
                    <td>Optional</td>
                    <td>Optional</td>
                    <td>Until the session ends</td>
                    <td>First-party</td>
                  </tr>
                  <tr>
                    <td>stg_pk_campaign</td>
                    <td>Optional</td>
                    <td>Optional</td>
                    <td>Until the session ends</td>
                    <td>First-party</td>
                  </tr>
                  <tr>
                    <td>stg_externalReferrer</td>
                    <td>Optional</td>
                    <td>Optional</td>
                    <td>Until the session ends</td>
                    <td>First-party</td>
                  </tr>
                  <tr>
                    <td>_stg_optout</td>
                    <td>Optional</td>
                    <td>Optional</td>
                    <td>365 days</td>
                    <td>First-party</td>
                  </tr>
                </table>
                <div>
                  In addition to the controls that we provide, you can choose to
                  enable or disable Cookies in your internet browser. Most
                  internet browsers also enable you to choose whether you wish
                  to disable all Cookies or only third-party Cookies. By
                  default, most internet browsers accept Cookies, but this can
                  be changed. For further details, please consult the help menu
                  in your internet browser or the documentation that came with
                  your device.
                </div>
                <div>
                  You can choose to delete Cookies on your computer or device at
                  any time, however you may lose any information that enables
                  you to access our Site more quickly and efficiently including,
                  but not limited to, login and personalisation settings.
                </div>
                <div>
                  It is recommended that you keep your internet browser and
                  operating system up-to-date and that you consult the help and
                  guidance provided by the developer of your internet browser
                  and manufacturer of your computer or device if you are unsure
                  about adjusting your privacy settings.
                </div>
              </li>
              <li>
                <p className="bold">How Do I Contact You?</p>
                <div>
                  To contact us about anything to do with your personal data and
                  data protection, including to make a subject access request,
                  please use the following details [for the attention of Jochen
                  Curth]:
                </div>
                <div>Email address: privacy@marcloudconsulting.com</div>
                <div>
                  Postal Address: The Plaza, 100 Old Hall St, Liverpool L3 9QJ
                </div>
              </li>

              <li>
                <p className="bold">Changes to this Privacy Policy</p>
                <div>
                  We may change this Privacy Notice from time to time. This may
                  be necessary, for example, if the law changes, or if we change
                  our business in a way that affects personal data protection.
                </div>
                <div>
                  Any changes will be immediately posted on our Site and you
                  will be deemed to have accepted the terms of the Privacy
                  Policy on your first use of our Site following the
                  alterations. We recommend that you check this page regularly
                  to keep up-to-date. This Privacy Policy was last updated on
                  09/04/2024.
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
