import * as React from "react";
import "./appReceipt.css";
import { useLocation } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Auth from "../../utils/auth";
import apple from "../../assets/logo/apple.png";
import google from "../../assets/logo/google.png";
import phone from "../../assets/ss/phone.png";
import { NEW_APP_SUBSCRIPTION } from "../../utils/mutations";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
const AppReceipt = ({ changeBg }) => {
  const color = "transparent";
  const [src, setSrc] = React.useState();
  const [receipt, setReceipt] = React.useState();
  const [
    newAppSubscription,
    { error: newAppSubscriptionError, data: newAppSubscriptionData },
  ] = useMutation(NEW_APP_SUBSCRIPTION);
  const location = useLocation();

  React.useEffect(() => {
    changeBg(color);
    const isLoggedIn = Auth.loggedIn();
    if (!isLoggedIn) {
      const prevPage = encodeURIComponent(window.location.href);
      window.location.assign(`/login?prev=${prevPage}`);
    }
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get("session_id");
    const src = searchParams.get("src");
    setSrc(src);

    const fetchData = async () => {
      try {
        const response = await fetch("/app-get-receipt", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            checkoutSessionId: sessionId,
          }),
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const jsonR = await response.json();
        setReceipt(jsonR.receipt);
        await newAppSubscription({
          variables: {
            stripeReference: jsonR.subscriptionId,
          },
        });
      } catch (error) {
        console.log(error);
      }
    };
    if (sessionId) {
      fetchData();
    }
  }, [location.search]);
  return (
    <div className="stars-bg">
      <Row className="div-width flex justify-center text-align-center align et app-receipt-header">
        <Col
          xl="7"
          lg="9"
          md="11"
          xs="11"
          className="flex justify-center align"
        >
          <div>
            <h2>Successfully subscribed to Pardot Proscpect Scanner App</h2>
            <div className="blue-line"></div>
            <p className="app-receipt-subtitle">
              A receipt was sent to your email. Your subscription will be
              automatically renewed every month.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="div-width flex justify-center text-align-center align">
        <Col xl="7" lg="9" md="11" xs="11" className="app-receipt">
          <Row>
            <div className="flex align justify-space-between app-receipt-header-div">
              <div className="text-align-left">
                <h3>What to do next? </h3>
              </div>
            </div>
          </Row>
          <Row>
            <Col md="8" xs="12" className="margin-0 padding-0">
              <ol className="text-align-left black-text">
                <li>
                  If you haven't already, please create a connected app in
                  Salesforce and add the details to your MarCloud account. More
                  details and guide available{" "}
                  <Link to="/Account" className="bold">
                    here
                  </Link>
                  .
                </li>
                <li>
                  Download the Pardot Prospect Scanner App from {" "}
                  {/* <a
                    href="https://apps.apple.com/gb/app/pardot-prospect-scanner/id1663176407"
                    className="bold"
                    target="_blank"
                    rel="noreferrer"
                  >
                    App Store
                  </a>{" "}
                  or{" "} */}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.marcloudtechnologies.pardotprospectscanner&pli=1"
                    className="bold"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Google Play
                  </a>
                  .
                </li>
                <li>
                  Login to the app using the same MarCloud Technologies username
                  you are using here on the website.
                </li>
                <li>Start scanning!</li>
              </ol>
              <div className="app-receipt-logo-container">
                {/* <a
                  href="https://apps.apple.com/gb/app/pardot-prospect-scanner/id1663176407"
                  className="bold"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={apple} />
                </a> */}
                <a
                  href="https://play.google.com/store/apps/details?id=com.marcloudtechnologies.pardotprospectscanner&pli=1"
                  className="bold"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={google} />
                </a>
              </div>
            </Col>
            <Col md="4" xs="12" className="margin-0 padding-0">
              <img src={phone} className="app-receipt-phone" />
            </Col>
          </Row>
        </Col>
      </Row>
      {receipt && (
        <Row className="div-width flex justify-center text-align-center align">
          <div>
            <a href={receipt} target="_blank" rel="noreferrer">
              <button className="button4 app-receipt-button">
                Download Receipt
              </button>
            </a>
          </div>
        </Row>
      )}
    </div>
  );
};
export default AppReceipt;
