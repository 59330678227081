import * as React from "react";
import "./buidCard.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { AES, enc } from "crypto-js";
import { useMutation } from "@apollo/client";
import { UPDATE_BUID } from "../../utils/mutations";
import { FiEdit2 } from "react-icons/fi";
import ErrorComponent from "../../components/error/error";

const EditBuid = (props) => {
  const buid = props.buid || [];

  const [updateBuid] = useMutation(UPDATE_BUID);

  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();
  const [editModal, setEditModal] = React.useState(false);

  const handleCloseEditModal = () => {
    setEditModal(false);
    setError(false);
    setErrorMessage(<></>);
  };
  const handleEditModal = () => setEditModal(true);

  const [buidFormData, setBuidFormData] = React.useState({
    buid: AES.decrypt(buid.buid, process.env.REACT_APP_SECRET_KEY).toString(
      enc.Utf8
    ),
    shortName: buid.shortName,
    longName: buid.longName,
    address1: buid.address1,
    address2: buid.address2,
    copyRightText: buid.copyRightText,
    privacyPolicyLink: buid.privacyPolicy,
  });

  const handleBuidChange = async (e) => {
    setError(false);
    setErrorMessage(<></>);
    setBuidFormData({
      ...buidFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveChanges = async (e) => {
    try {
      e.preventDefault();
      await updateBuid({
        variables: {
          buidId: buid._id || "",
          buid: {
            buid: buidFormData.buid,
            shortName: buidFormData.shortName,
            longName: buidFormData.longName,
            address1: buidFormData.address1,
            address2: buidFormData.address2,
            copyRightText: buidFormData.copyRightText,
            privacyPolicy: buidFormData.privacyPolicyLink,
          },
        },
      });
      setEditModal(false);
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message || "Error saving changes!");
      setError(true);
    }
  };

  return (
    <>
      <Button
        className="flex align account-form-save"
        onClick={handleEditModal}
      >
        <FiEdit2
          size="20px"
          style={{ marginRight: "5px" }}
          className="buid-card-edit-icon"
        />
        Edit
      </Button>

      <Modal
        show={editModal}
        onHide={handleCloseEditModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className="buid-card-edit-modal"
      >
        <Form onSubmit={handleSaveChanges}>
          <Modal.Header closeButton>
            <Modal.Title>
              Edit <span className="bold"> {buid.longName}</span> Business Unit
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group as={Col} xs={12} className="mb-3">
                <Form.Label>Buid</Form.Label>
                <Form.Control
                  type="text"
                  name="buid"
                  required
                  onChange={handleBuidChange}
                  value={buidFormData.buid}
                  placeholder="Disabled input"
                  aria-label="Disabled input example"
                  disabled
                  readOnly
                />
              </Form.Group>

              <Form.Group as={Col} md={6} xs={12} className="mb-3">
                <Form.Label>Short Name</Form.Label>
                <Form.Control
                  type="text"
                  name="shortName"
                  required
                  onChange={handleBuidChange}
                  value={buidFormData.shortName}
                />
              </Form.Group>
              <Form.Group as={Col} md={6} xs={12} className="mb-3">
                <Form.Label>Long Name</Form.Label>
                <Form.Control
                  type="text"
                  name="longName"
                  required
                  onChange={handleBuidChange}
                  value={buidFormData.longName}
                />
              </Form.Group>

              <Form.Group as={Col} md={6} xs={12} className="mb-3">
                <Form.Label>Address 1</Form.Label>
                <Form.Control
                  type="text"
                  name="address1"
                  required
                  onChange={handleBuidChange}
                  value={buidFormData.address1}
                />
              </Form.Group>
              <Form.Group as={Col} md={6} xs={12} className="mb-3">
                <Form.Label>Address 2</Form.Label>
                <Form.Control
                  type="text"
                  name="address2"
                  required
                  onChange={handleBuidChange}
                  value={buidFormData.address2}
                />
              </Form.Group>

              <Form.Group as={Col} xs={12} className="mb-3">
                <Form.Label>Copyright text</Form.Label>
                <Form.Control
                  type="text"
                  name="copyRightText"
                  required
                  onChange={handleBuidChange}
                  value={buidFormData.copyRightText}
                />
              </Form.Group>

              <Form.Group as={Col} xs={12} className="mb-3">
                <Form.Label>Privacy Policy link</Form.Label>
                <Form.Control
                  type="text"
                  name="privacyPolicyLink"
                  required
                  onChange={handleBuidChange}
                  value={buidFormData.privacyPolicyLink}
                />
              </Form.Group>
            </Row>
            {error && <ErrorComponent errorMessage={errorMessage} />}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="account-form-cancel"
              onClick={handleCloseEditModal}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              className="account-form-save"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export default EditBuid;
