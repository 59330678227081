import { gql } from "@apollo/client";
export const UserFragment = gql`
  fragment UserFragment on User {
    _id
    firstName
    lastName
    companyName
    email
    password
    clientId
    clientSecret
    lastLogin
    createdAt
    updatedAt
    confirmed
    passwordToken
    instanceUrl
    buids {
      _id
      buid
      shortName
      longName
      address1
      address2
      copyRightText
      privacyPolicy
      brandings {
        _id
        name
        buttonStyle
        highlightColor
        lightColor
        midColor
        darkColor
        copyFont
        displayFont
        preferenceLink
        logo
        facebook
        instagram
        twitter
        linkedIn
      }
    }
    purchases {
      _id
      purchaseDate
      stripeReference
      total
      currency
      products {
        _id
        name
        category
        internalReference
        price
        stripeId
        publishCount
        previewCount
      }
    }
    appSubscriptions {
      _id
      stripeReference
      totalCredits
      buidId
      lastLogin
    }
  }
`;
export const GET_USER = gql`
  query GetUser($userId: ID!) {
    getUser(userId: $userId) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;
