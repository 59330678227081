import * as React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import SelectionModal from "../../components/searchList/searchList";
import { AES, enc } from "crypto-js";
import loadingGif from "../../assets/loading1.gif";
import ErrorComponent from "../../components/error/error";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { NEW_PUBLISH } from "../../utils/mutations";

const PublishTemplate = ({
  campaigns,
  domains,
  folders,
  businessUnit,
  branding,
  product,
  onBack,
}) => {
  const [newPublish, { error: newPublishError, data: newPublishData }] =
    useMutation(NEW_PUBLISH);

  const [isFolderModalOpen, setIsFolderModalOpen] = React.useState(false);
  const [selectedFolder, setSelectedFolder] = React.useState(null);

  const [isCampaignModalOpen, setIsCampaignModalOpen] = React.useState(false);
  const [selectedCampaign, setSelectedCampaign] = React.useState(null);

  const [isDomainModalOpen, setIsDomainModalOpen] = React.useState(false);
  const [selectedDomain, setSelectedDomain] = React.useState(null);

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [result, setResult] = React.useState({});

  const [formData, setFormData] = React.useState({
    templateName: "",
    senderName: "",
    senderEmail: "",
    replyEmail: "",
    subjectLine: "",
    folder: selectedFolder ? selectedFolder.name : "",
    domain: selectedDomain ? selectedDomain.domain : "",
    campaign: selectedCampaign ? selectedCampaign.name : "",
    isDrip: true,
    isList: true,
    isOneToOne: true,
    isAutoResponder: true,
  });
  const handleChange = async (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };
  const handleFolderSelect = (folder) => {
    setSelectedFolder(folder);
    setFormData((prevData) => ({
      ...prevData,
      folder: folder.name,
    }));
  };

  const handleCampaignSelect = (campaign) => {
    setSelectedCampaign(campaign);
    setFormData((prevData) => ({
      ...prevData,
      campaign: campaign.name,
    }));
  };

  const handleDomainSelect = (domain) => {
    setSelectedDomain(domain);
    setFormData((prevData) => ({
      ...prevData,
      domain: domain.domain,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const requestData = {
      name: formData.templateName,
      senderName: formData.senderName,
      sendingAddress: formData.senderEmail,
      replyAddress: formData.replyEmail,
      templateRef: product.internalReference,
      address1: businessUnit.address1,
      address2: businessUnit.address2,
      copyrightText: businessUnit.copyRightText,
      longName: businessUnit.longName,
      profile: branding,
      subject: formData.subjectLine,
      folder: selectedFolder.id,
      domain: selectedDomain.id,
      campaign: selectedCampaign.id,
      accessToken: sessionStorage.getItem("accessToken"),
      buid: AES.decrypt(
        businessUnit.buid,
        process.env.REACT_APP_SECRET_KEY
      ).toString(enc.Utf8),
      isDrip: formData.isDrip,
      isList: formData.isList,
      isOneToOne: formData.isOneToOne,
      isAutoResponder: formData.isAutoResponder,
    };
    try {
      const response = await fetch("/publish", {
        method: "POST",
        body: JSON.stringify({ requestData }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const resultData = await response.json();
        const result = resultData.sfResult;
        // console.log(result);
        setResult(result);
        setLoading(false);
        setSuccess(true);
        await newPublish({
          variables: { purchaseId: product.purchaseId, productId: product._id },
        });
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      console.error("Error:", error);
    }
  };
  return (
    <>
      {success ? (
        <>
          <div className="preview-div text-align-left preview-publish-success">
            <div className="flex align">
              <IoIosCheckmarkCircleOutline size="60px" color="green" />
              <p className="margin-0 preview-publish-success-title">
                Published
              </p>
            </div>
            <div className="preview-publish-success-message">
              Your template has been succesfully published in your Account
              Engagement instance!
              {result && (
                <ul className="padding-0">
                  <li>
                    <span className="bold">Name: </span> {result.name}
                  </li>
                  <li>
                    <span className="bold">Id: </span> {result.templateId}
                  </li>
                  <li>
                    <span className="bold">Folder Name: </span>{" "}
                    {result.folderName}
                  </li>
                </ul>
              )}
              <Link to="/Purchases">
                <button className="button4">Publish another template</button>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <>
          <h3>Preview and publish template</h3>
          <h2>{product.name}</h2>
          <div className="preview-div text-align-left preview-form">
            <Form className="white-text" onSubmit={handleSubmit}>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label>Email template name</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="name"
                    name="templateName"
                    value={formData.templateName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Sender name</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="name"
                    name="senderName"
                    value={formData.senderName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Sender email address</Form.Label>
                  <Form.Control
                    type="email"
                    // placeholder="email address"
                    name="senderEmail"
                    value={formData.senderEmail}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Reply to email</Form.Label>
                  <Form.Control
                    type="email"
                    // placeholder="email address"
                    name="replyEmail"
                    value={formData.replyEmail}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Subject Line</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="subject"
                    name="subjectLine"
                    value={formData.subjectLine}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3 align">
                  <Form.Label column sm={4}>
                    Select Folder
                  </Form.Label>
                  <div
                    className="flex align preview-browse"
                    onClick={() => setIsFolderModalOpen(true)}
                  >
                    <Form.Control
                      type="text"
                      // placeholder="folder"
                      name="folder"
                      value={formData.folder}
                      required
                      disabled
                    />
                    <button
                      type="button"
                      className="button4"
                      onClick={() => setIsFolderModalOpen(true)}
                    >
                      Browse
                    </button>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3 align">
                  <Form.Label column sm={4}>
                    Select Campaign
                  </Form.Label>
                  <div
                    className="flex align preview-browse"
                    onClick={() => setIsCampaignModalOpen(true)}
                  >
                    <Form.Control
                      type="text"
                      // placeholder="campaign"
                      name="campaign"
                      value={formData.campaign}
                      required
                      disabled
                    />
                    <button
                      type="button"
                      className="button4"
                      onClick={() => setIsCampaignModalOpen(true)}
                    >
                      Browse
                    </button>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 align">
                  <Form.Label column sm={4}>
                    Select Domain
                  </Form.Label>
                  <div
                    className="flex align preview-browse"
                    onClick={() => setIsDomainModalOpen(true)}
                  >
                    <Form.Control
                      type="text"
                      // placeholder="domain"
                      name="domain"
                      value={formData.domain}
                      required
                      disabled
                    />
                    <button
                      type="button"
                      className="button4"
                      onClick={() => setIsDomainModalOpen(true)}
                    >
                      Browse
                    </button>
                  </div>
                </Form.Group>

                <Form.Group as={Col} xs={6} md={3} className="mb-3">
                  <Form.Check
                    label="Drip"
                    name="isDrip"
                    checked={formData.isDrip}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} xs={6} md={3} className="mb-3">
                  <Form.Check
                    label="List"
                    name="isList"
                    checked={formData.isList}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} xs={6} md={3} className="mb-3">
                  <Form.Check
                    label="One-To-One"
                    name="isOneToOne"
                    checked={formData.isOneToOne}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} xs={6} md={3} className="mb-3">
                  <Form.Check
                    label="Auto-Responder"
                    name="isAutoResponder"
                    checked={formData.isAutoResponder}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
              {error && (
                <ErrorComponent errorMessage="Publish error, please try again!" />
              )}
              {loading ? (
                <img src={loadingGif} width="50px" />
              ) : (
                <>
                  <button className="preview-btn" onClick={onBack}>
                    Back
                  </button>
                  <button
                    className="button4 preview-continue-btn"
                    type="submit"
                  >
                    Publish to Account Engagement
                  </button>
                </>
              )}
            </Form>
          </div>
        </>
      )}

      <SelectionModal
        items={folders}
        isOpen={isFolderModalOpen}
        onClose={() => setIsFolderModalOpen(false)}
        onSelect={handleFolderSelect}
        renderItem={(folder) => folder.name}
        category="Folder"
      />

      <SelectionModal
        items={campaigns}
        isOpen={isCampaignModalOpen}
        onClose={() => setIsCampaignModalOpen(false)}
        onSelect={handleCampaignSelect}
        renderItem={(campaign) => campaign.name}
        category="Campaign"
      />

      <SelectionModal
        items={domains}
        isOpen={isDomainModalOpen}
        onClose={() => setIsDomainModalOpen(false)}
        onSelect={handleDomainSelect}
        renderItem={(domain) => domain.domain}
        category="Domain"
      />
    </>
  );
};

export default PublishTemplate;
