import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useMutation } from "@apollo/client";
import { EDIT_DETAILS } from "../../../utils/mutations";
import ErrorComponent from "../../../components/error/error";
import loadingGif from "../../../assets/loading1.gif";
import { TiTick } from "react-icons/ti";
import { BiUser } from "react-icons/bi";

const DetailsForm = (props) => {
  const [editDetails, { error: editError, data: editData }] =
    useMutation(EDIT_DETAILS);
  const [loading, setLoading] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState();
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();
  const [expanded, setExpanded] = React.useState(false);
  const [formData, setFormData] = React.useState({
    firstName: props.user.firstName,
    lastName: props.user.lastName,
    companyName: props.user.companyName,
  });

  const handleChange = async (e) => {
    setSuccessMessage(<></>);
    setError(false);
    setErrorMessage(<></>);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await editDetails({
        variables: {
          ...formData,
        },
      });
      setLoading(false);
      setSuccessMessage(
        <div className="flex align password-error">
          <TiTick size="40px" color="green" className="password-error-icon" />
          Details updated successfully
        </div>
      );
      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.message || "Error changing password!");
      setError(true);
      console.error(error);
    }
  };

  const handleCancel = async (event) => {
    event.preventDefault();
    setSuccessMessage(null);
    setError(false);
    setErrorMessage(<></>);
    setFormData({
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      companyName: props.user.companyName,
    });
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <div className="account-div">
        <div
          className="flex align justify-space-between"
          onClick={toggleExpanded}
        >
          <div className="flex align">
            <span>
              <BiUser color="#052e57" size="40px" />
            </span>
            <div>
              <h2>Account</h2>
              <h3>View and edit your account details here</h3>
            </div>
          </div>
          {expanded ? "▲" : "▼"}
        </div>
        {expanded && (
          <div>
            <Form onSubmit={handleFormSubmit}>
              <Row>
                <Form.Group as={Col} xs={12} className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.firstName}
                    onChange={handleChange}
                    name="firstName"
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} xs={12} className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.lastName}
                    onChange={handleChange}
                    name="lastName"
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} xs={12} className="mb-3">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.companyName}
                    onChange={handleChange}
                    name="companyName"
                    required
                  />
                </Form.Group>
              </Row>

              {successMessage}
              {error && <ErrorComponent errorMessage={errorMessage} />}
              {loading ? (
                <img src={loadingGif} width="50px" />
              ) : (
                <>
                  <Button
                    variant="primary"
                    type="submit"
                    className="account-form-save"
                  >
                    Save changes
                  </Button>
                  <Button
                    variant="warning"
                    type="button"
                    className="account-form-cancel"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Form>
          </div>
        )}
      </div>
    </>
  );
};

export default DetailsForm;
