import React from "react";
import "./branding.css";
import { FaCircle } from "react-icons/fa";
import EditBranding from "./editBranding";
import DeleteBranding from "./deleteBranding";

const Branding = (props) => {
  const branding = props.branding;
  const buidId = props.buidId;
  const length = props.length;
  const buid = props.buid;

  const [disableDelete, setDisableDelete] = React.useState(false);
  
  React.useEffect(() => {
    setDisableDelete(length === 1);
  }, [length]);

  return (
    <>
      <div className="buid-branding-card">
        <div>
          <p className="subtitle">Branding</p>
          <p className="title">{branding.name}</p>
          <div className="margin-0 padding-0 flex justify-center">
            <FaCircle color={branding.highlightColor} />
            <FaCircle color={branding.lightColor} />
            <FaCircle color={branding.midColor} />
            <FaCircle color={branding.darkColor} />
          </div>
        </div>
        <div className="flex justify-end buid-branding-bottom">
          <EditBranding branding={branding} buidId={buidId} buid={buid} />
          {disableDelete ? (
            <></>
          ) : (
            <DeleteBranding branding={branding} buidId={buidId} />
          )}
        </div>
      </div>
    </>
  );
};
export default Branding;
