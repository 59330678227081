import React from "react";
import "./section2.css";
import hero from "../../../assets/img/img9.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MdOutlineMailOutline } from "react-icons/md";
import { AiOutlineForm } from "react-icons/ai";
import { BsCode } from "react-icons/bs";
import { ImHtmlFive2 } from "react-icons/im";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { Link } from "react-router-dom";
import {BiData} from "react-icons/bi";
import{BiConversation} from "react-icons/bi"
const Section2 = () => {
  return (
    <div className="cs-icons">
      <Row className="margin-0 padding-0">
        <Col
          lg="12"
          className="margin-0 padding-0 flex justify-center text-align-center"
        >
          <div className="cs-icons-div">
            <h2>Salesforce Marketing Cloud</h2>
            <div className="orange-line"></div>
          </div>
        </Col>
      </Row>
      <Row className="margin-0 padding-0 flex justify-center align cs-icons-row">
        <Col lg="4" md="4" sm="12" className="margin-0 padding-0 cs-icons-col1">
          <div className="flex align cs-icons-col1-item">
            <div className="cs-icons-col1-text-div">
              <h3>Custom Preference Centre</h3>
            </div>
            <div className="cs-icons-col1-icon-div">
              <MdOutlineMailOutline size="40px" className="cs-icon" />
            </div>
          </div>

          <div className="flex align cs-icons-col1-item">
            <div className="cs-icons-col1-text-div">
              <h3>
                Custom form handling (including Sales Cloud integration logic)
              </h3>
            </div>
            <div className="cs-icons-col1-icon-div">
              <AiOutlineForm size="40px" className="cs-icon" />
            </div>
          </div>

          <div className="flex align cs-icons-col1-item">
            <div className="cs-icons-col1-text-div">
              <h3> AMPscript components</h3>
            </div>
            <div className="cs-icons-col1-icon-div">
              <BsCode size="40px" className="cs-icon" />
            </div>
          </div>

          <div className="flex align cs-icons-col1-item">
            <div className="cs-icons-col1-text-div">
              <h3> SSJS data management scripts</h3>
            </div>
            <div className="cs-icons-col1-icon-div">
              <BiData size="40px" className="cs-icon" />
            </div>
          </div>
        </Col>

        <Col
          lg="4"
          md="4"
          sm="12"
          className="margin-0 padding-0 flex justify-center align cs-icons-img-col"
        >
          <div>
            <img src={hero} className="cs-icons-img" />
          </div>
        </Col>

        <Col lg="4" md="4" sm="12" className="margin-0 padding-0 cs-icons-col2">
          <div className="flex align cs-icons-col2-item cs-icons-col2-item-exception">
            <div className="cs-icons-col2-icon-div">
              <ImHtmlFive2 size="40px" className="cs-icon" />
            </div>
            <div>
              <h3>HTML/CSS email templates and landing pages</h3>
            </div>
          </div>

          <div className="flex align cs-icons-col2-item">
            <div className="cs-icons-col2-icon-div">
              <AiOutlineConsoleSql size="40px" className="cs-icon" />
            </div>
            <div>
              <h3>SQL queries</h3>
            </div>
          </div>

          <div className="flex align cs-icons-col2-item">
            <div className="cs-icons-col2-icon-div">
              <AiOutlineCloudUpload size="40px" className="cs-icon" />
            </div>
            <div>
              <h3>Enhanced import/export configuration</h3>
            </div>
          </div>

          <div className="flex align cs-icons-col2-item">
            <div className="cs-icons-col2-icon-div">
              <BiConversation size="40px" className="cs-icon" />
            </div>
            <div>
              <h3>Enhanced Salesforce conversion logic</h3>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="flex justify-center cs-text">
        <Col lg="10">
        We have an excellent record of providing robust, custom-developed solutions to clients big and small. If you have hit a dead end with out-of-box functionality and don’t know where to go, contact us 
          <div>
            <Link to="/ContactUs">
              <button className="button4">Contact us</button>
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Section2;
