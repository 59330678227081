import * as React from "react";
import "./buidCard.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useMutation } from "@apollo/client";
import { DELETE_BUID } from "../../utils/mutations";
import { MdDeleteOutline } from "react-icons/md";
import ErrorComponent from "../../components/error/error";

const DeleteBuid = (props) => {
  const buid = props.buid || [];

  const [deleteBuid] = useMutation(DELETE_BUID);

  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();
  const [deleteModal, setDeleteModal] = React.useState(false);

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
    setError(false);
    setErrorMessage(<></>);
  };
  const handleDeleteModal = () => setDeleteModal(true);

  const handleDeleteBuid = async (e) => {
    try {
      await deleteBuid({
        variables: {
          buidId: buid._id,
        },
      });
      setDeleteModal(false);
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message || "Error deleting buid!");
      setError(true);
    }
  };

  return (
    <>
      <Button
        className="flex align account-form-cancel"
        onClick={handleDeleteModal}
        variant="warning"
      >
        <MdDeleteOutline
          size="20px"
          className="buid-card-delete-icon"
          style={{ marginRight: "5px" }}
        />{" "}
        Delete
      </Button>

      <Modal
        show={deleteModal}
        onHide={handleCloseDeleteModal}
        className="buid-card-delete-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="title">
            Delete <span className="bold">{buid.longName}</span> Business Unit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you would like to delete{" "}
          <span className="bold">{buid.longName}</span> business unit?
          {error && <ErrorComponent errorMessage={errorMessage} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteBuid}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default DeleteBuid;
