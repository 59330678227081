import * as React from "react";
import "./password.css";
import qs from "qs";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { AES } from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import { useMutation } from "@apollo/client";
import { SAVE_PASSWORD_TOKEN } from "../../utils/mutations.js";
import { BiErrorCircle } from "react-icons/bi";
import { AiFillCheckCircle } from "react-icons/ai";
import confirmation from "../../assets/img/img8.png";
import loadingGif from "../../assets/loading1.gif";

const Email = ({ changeBg }) => {
  const color = "transparent";
  React.useEffect(() => {
    changeBg(color);
  });
  let token = "";
  function generateResetToken() {
    token = uuidv4();
    return token;
  }
  const actionUrl = process.env.REACT_APP_PASSWORD_RESET_FORM_HANDLER;
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
  const [formData, setFormData] = React.useState({ email: "" });
  const [formSubmitted, setIsFormSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [saveToken, { error }] = useMutation(SAVE_PASSWORD_TOKEN);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      generateResetToken();
      await saveToken({
        variables: {
          email: formData.email,
          token: token,
        },
      });
      const reset_data = qs.stringify({
        email: formData.email,
        date: Date.now(),
      });
      const encrypted_reset_data = encodeURIComponent(
        AES.encrypt(reset_data, SECRET_KEY).toString()
      );
      const prospect_data = {
        email: formData.email,
        encryptedConfirmationString: encrypted_reset_data,
      };
      setFormData({ email: "" });
      setIsFormSubmitted(true);
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: actionUrl,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: prospect_data,
      };
      await axios.request(config);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };
  const handleButtonClick = () => {
    window.location.reload();
  };
  return (
    <div className="stars-bg">
      <Row
        className={
          formSubmitted
            ? "div-width text-align-center flex justify-center password"
            : "div-width text-align-center flex justify-center password display-none"
        }
      >
        <Col
          lg="6"
          md="6"
          sm="12"
          xs="12"
          className="flex justify-center align"
        >
          <div className="signup-confirmation">
            <h2>
              <AiFillCheckCircle size="60px" className="password-check" /> Reset
              Password Link Sent
            </h2>
            <p>
              We've sent you an email to reset your password. To create your new
              password, click the link in the email and enter a new one.
            </p>
            <p>
              Didn't receive the email? Check your junk email or click below to
              resend the email.
            </p>
            <button className="button4" onClick={handleButtonClick}>
              Resend Email
            </button>
          </div>
        </Col>
        <Col
          lg="6"
          md="6"
          sm="12"
          xs="12"
          className="flex justify-center align"
        >
          <div>
            <img src={confirmation} width="350px" />
          </div>
        </Col>
      </Row>

      <Row
        className={
          formSubmitted
            ? "div-width text-align-center flex justify-center password display-none"
            : "div-width text-align-center flex justify-center password "
        }
      >
        <Col lg="5" md="8" sm="10" xs="10">
          <h2>Forgot Password</h2>
          <div className="blue-line"></div>
          <p className="password-subtitle">
            Forgot your password? Please enter your email and you will receive a
            link to create a new password via email.
          </p>
          <Form onSubmit={handleFormSubmit}>
            {error && (
              <div className="flex align justify-center confirmation-error">
                <BiErrorCircle
                  size="40px"
                  color="orange"
                  className="confirmation-error-icon"
                />
                {error.message}
              </div>
            )}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="email"
                placeholder="Email"
                name="email"
                className="password-form-label"
                required
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>
            {loading ? (
              <img src={loadingGif} width="100px" />
            ) : (
              <Button
                variant="primary"
                type="submit"
                className="button4 password-button"
              >
                Reset Password
              </Button>
            )}
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Email;
