import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useMutation } from "@apollo/client";
import { EDIT_CONSUMER_DETAILS } from "../../../utils/mutations";
import ErrorComponent from "../../../components/error/error";
import loadingGif from "../../../assets/loading1.gif";
import { TiTick } from "react-icons/ti";
import { RiShieldKeyholeFill } from "react-icons/ri";
import { AES, enc } from "crypto-js";
import { IoShieldCheckmark } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import "./consumerDetails.css";

const ConsumerDetails = (props) => {
  let decryptedClientSecret = "";
  if (props.user.clientSecret) {
    decryptedClientSecret = AES.decrypt(
      props.user.clientSecret,
      process.env.REACT_APP_SECRET_KEY
    ).toString(enc.Utf8);
  }

  let decryptedClientId = "";
  if (props.user.clientId) {
    decryptedClientId = AES.decrypt(
      props.user.clientId,
      process.env.REACT_APP_SECRET_KEY
    ).toString(enc.Utf8);
  }

  const [editDetails] = useMutation(EDIT_CONSUMER_DETAILS);
  const [verifiedLoading, setVerifiedLoading] = React.useState(true);
  const [verified, setVerified] = React.useState();
  const [warningModal, setWarningModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState();
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();
  const [expanded, setExpanded] = React.useState(false);
  const [formData, setFormData] = React.useState({
    clientId: decryptedClientId,
    clientSecret: decryptedClientSecret,
    instanceUrl: props.user.instanceUrl,
  });
  React.useEffect(() => {
    if (
      props.user.clientId &&
      props.user.clientSecret &&
      props.user.clientId.trim() !== "" &&
      props.user.clientSecret.trim() !== "" &&
      props.user.instanceUrl &&
      props.user.instanceUrl.trim() !== ""
    ) {
      setVerified(true);
      setVerifiedLoading(false);
    } else {
      setVerified(false);
      setVerifiedLoading(false);
    }
  }, [props.user.clientId, props.user.clientSecret, props.user.instanceUrl]);

  const handleCloseWarningModal = () => {
    setWarningModal(false);
    setError(false);
    setErrorMessage(<></>);
  };

  const handleChange = async (e) => {
    setSuccessMessage(<></>);
    setError(false);
    setErrorMessage(<></>);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (
      props.user.clientId ||
      props.user.clientSecret ||
      props.user.instanceUrl
    ) {
      if (formData.instanceUrl !== props.user.instanceUrl) {
        setWarningModal(true);
      } else {
        handleProceed();
      }
    } else {
      handleProceed();
    }
  };
  const handleProceed = async () => {
    setWarningModal(false);
    setLoading(true);
    try {
      await editDetails({
        variables: {
          ...formData,
        },
      });
      setLoading(false);
      setSuccessMessage(
        <div className="flex align password-error">
          <TiTick size="40px" color="green" className="password-error-icon" />
          Details updated successfully
        </div>
      );
      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.message || "Error updating consumer details!");
      setError(true);
      console.error(error);
    }
  };
  const handleCancel = async (event) => {
    event.preventDefault();
    setSuccessMessage(<></>);
    setError(false);
    setErrorMessage(<></>);
    setFormData({
      clientId: decryptedClientId,
      clientSecret: decryptedClientSecret,
      instanceUrl: props.user.instanceUrl,
    });
  };
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <div className="account-div">
        <div
          className="flex align justify-space-between"
          onClick={toggleExpanded}
        >
          <div className="flex align justify-space-between account-cd-header">
            <div className="flex align">
              <span>
                <RiShieldKeyholeFill color="#052e57" size="40px" />
              </span>
              <div>
                <h2>Consumer Details</h2>
                <h3>View and edit your consumer details here</h3>
              </div>
            </div>
            <div>
              {!verifiedLoading && verified ? (
                <IoShieldCheckmark
                  size="40px"
                  color="green"
                  className="account-cd-verified"
                />
              ) : (
                <BiErrorCircle
                  size="40px"
                  color="orange"
                  className="error-icon"
                />
              )}
            </div>
          </div>

          {expanded ? "▲" : "▼"}
        </div>
        {expanded && (
          <div>
            <div className="account-cd-steps">
              <p className="title">
                Please complete the following essential setup steps, which are
                required only once:
              </p>
              <ol>
                <li>
                  Establish a connected app on your Salesforce organization by
                  referring to our comprehensive guide available at{" "}
                  <a
                    className="bold underline black-text"
                    target="_blank"
                    rel="noreferrer"
                    href="https://app.getguru.com/card/cMbLMe7i/Set-up-a-Connected-App"
                  >
                    Guide Link
                  </a>
                  .
                </li>
                <li>
                  Ensure that the Redirect URI for the connected app is
                  configured to{" "}
                  <span className="bold">
                    https://marcloudtechnologies.com/sfauth
                  </span>
                </li>
                <li>
                  Carefully retrieve and securely store both your
                  <span className="bold"> Client ID</span> and
                  <span className="bold"> Client Secret</span>. You will need to
                  input these credentials into your account consumer details
                  below.
                </li>
              </ol>
            </div>
            <Form onSubmit={handleFormSubmit}>
              <Form.Group as={Col} xs={12} className="mb-3">
                <Form.Label>Client Id</Form.Label>
                <div className="flex align">
                  <Form.Control
                    type="text"
                    value={formData.clientId}
                    onChange={handleChange}
                    name="clientId"
                    required
                  />
                </div>
              </Form.Group>
              <Form.Group as={Col} xs={12} className="mb-3">
                <Form.Label>Client Secret</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.clientSecret}
                  onChange={handleChange}
                  name="clientSecret"
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} className="mb-3">
                <Form.Label>Instance Url</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.instanceUrl}
                  onChange={handleChange}
                  name="instanceUrl"
                  required
                  aria-describedby="helpBlock"
                />
                <Form.Text id="helpBlock" muted>
                  https://yourdomain.my.salesforce.com
                </Form.Text>
              </Form.Group>
              {successMessage}
              {error && <ErrorComponent errorMessage={errorMessage} />}
              {loading ? (
                <img src={loadingGif} width="50px" alt="loading" />
              ) : (
                <>
                  <Button
                    variant="primary"
                    type="submit"
                    className="account-form-save"
                  >
                    Save changes
                  </Button>
                  <Button
                    variant="warning"
                    type="button"
                    className="account-form-cancel"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Form>
          </div>
        )}
      </div>

      <Modal
        show={warningModal}
        onHide={handleCloseWarningModal}
        className="buid-card-delete-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="title">Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You have changed your instance url, this will result in deleting your
          saved business units to your MarCloud Technologies account. Are you
          sure you want to proceed?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseWarningModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleProceed}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConsumerDetails;
