import * as React from "react";
import "./receipts.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Auth from "../../utils/auth";
import jwtDecode from "jwt-decode";
import { GET_USER } from "../../utils/queries";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

const Receipts = ({ changeBg }) => {
  const color = "transparent";
  const [userId, setUserId] = React.useState(null);
  const [result, setResult] = React.useState();
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    changeBg(color);
    const isLoggedIn = Auth.loggedIn();
    if (!isLoggedIn) {
      const prevPage = encodeURIComponent(window.location.href);
      window.location.assign(`/login?prev=${prevPage}`);
    }
    const decodeToken = () => {
      const token = localStorage.getItem("id_token");
      if (token) {
        try {
          const decoded = jwtDecode(token);
          setUserId(decoded.data._id);
        } catch (error) {
          console.error("Error decoding token:", error.message);
        }
      }
    };
    decodeToken();
  });

  const {
    loading: queryLoading,
    data,
  } = useQuery(GET_USER, {
    variables: { userId },
  });

  React.useEffect(() => {
    const getReceipts = async () => {
      if (data) {
        const purchases = data.getUser.purchases;
        const paymentIntents = [];
        for (const purchase of purchases) {
          paymentIntents.push(purchase.stripeReference);
        }
        try {
          const response = await fetch("/get-receipts", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ paymentIntents }),
          });
          if (!response.ok) {
            throw new Error("Failed to fetch receipts");
          }
          const result = await response.json();
          setResult(result.receipts);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching receipts:", error.message);
          return null;
        }
      }
    };
    getReceipts();
  }, [data]);

  return (
    <div>
      <Row className="div-width text-align-center flex justify-center">
        <div className="text-align-left receipts">
          {queryLoading || loading ? (
            <></>
          ) : (
            <>
              {result && result.length > 0 && (
                <Row className="flex align receipt-titles">
                  <Col xs="4">
                    <h2>Date</h2>
                  </Col>
                  <Col xs="3">
                    <h2>Total</h2>
                  </Col>
                  <Col xs="5">
                    <h2>Receipt</h2>
                  </Col>
                </Row>
              )}
              {result &&
                result.length > 0 &&
                result.map((receipt, index) => (
                  <Row className="flex align receipt-row" key={index}>
                    <Col className="receipt-date" xs="4">
                      {receipt.date}
                    </Col>
                    <Col className="receipt-date" xs="3">
                      {receipt.total} {receipt.currency ? receipt.currency.toUpperCase() : ''}
                    </Col>
                    <Col>
                      <a
                        href={receipt.receipt}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="button4">
                          View and download receipt
                        </button>
                      </a>
                    </Col>
                  </Row>
                ))}
              {result && result.length === 0 && (
                <>
                  <Row className="flex align receipt-titles">
                    <Col xs="12">
                      <h2>Receipts</h2>
                    </Col>
                  </Row>
                  <Row className="flex align">
                    <div>
                      <p>You don't have any receipts yet.</p>
                      <Link to="/EmailTemplates">
                        <button className="button4 receipts-browse-btn">
                          Browse Products
                        </button>
                      </Link>
                    </div>
                  </Row>
                </>
              )}
            </>
          )}
        </div>
      </Row>
    </div>
  );
};

export default Receipts;
