import React from "react";
import "./icons.css";
import phone from "../../../assets/ss/phone.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BsPersonFillLock } from "react-icons/bs";
import { BiScan } from "react-icons/bi";
import { TbSettingsAutomation } from "react-icons/tb";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { MdLibraryAdd } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import apple from "../../../assets/qr/apple.png";
import google from "../../../assets/qr/google.png";

const Icons = () => {
  return (
    <div className="pps-icons">
      <Row className="margin-0 padding-0">
        <Col
          lg="12"
          className="margin-0 padding-0 flex justify-center text-align-center"
        >
          <div className="pps-icons-div">
            <h2> Top app features</h2>
            <div className="orange-line"></div>
            <h3>Download and try it out for free</h3>
          </div>
        </Col>
      </Row>
      <Row className="margin-0 padding-0 flex justify-center align pps-icons-row">
        <Col
          lg="4"
          md="4"
          sm="12"
          className="margin-0 padding-0 pps-icons-col1"
        >
          <div className="flex align pps-icons-col1-item">
            <div className="pps-icons-col1-text-div">
              <h3>Scan or upload photos from your phone</h3>
            </div>
            <div className="pps-icons-col1-icon-div">
              <BiScan size="40px" className="pps-icon" />
            </div>
          </div>

          <div className="flex align pps-icons-col1-item">
            <div className="pps-icons-col1-text-div">
              <h3>Secure access via OAuth 2.0</h3>
            </div>
            <div className="pps-icons-col1-icon-div">
              <BsPersonFillLock size="40px" className="pps-icon" />
            </div>
          </div>

          <div className="flex align pps-icons-col1-item">
            <div className="pps-icons-col1-text-div">
              <h3> Easily delete or edit incorrect data</h3>
            </div>
            <div className="pps-icons-col1-icon-div">
              <AiOutlineEdit size="40px" className="pps-icon" />
            </div>
          </div>

          <div className="flex align pps-icons-col1-item">
            <div className="pps-icons-col1-text-div">
              <h3>Manage which users can access the scanner</h3>
            </div>
            <div className="pps-icons-col1-icon-div">
              <FaUserCheck size="40px" className="pps-icon" />
            </div>
          </div>
        </Col>

        <Col
          lg="4"
          md="4"
          sm="12"
          className="margin-0 padding-0 flex justify-center align pps-icons-img-col"
        >
          <div>
            <img src={phone} className="pps-icons-img" />
          </div>
        </Col>

        <Col
          lg="4"
          md="4"
          sm="12"
          className="margin-0 padding-0 pps-icons-col2"
        >
          <div className="flex align pps-icons-col2-item pps-icons-col2-item-exception">
            <div className="pps-icons-col2-icon-div">
              <TbSettingsAutomation size="40px" className="pps-icon" />
            </div>
            <div>
              <h3>Automatically assign to standard Pardot fields</h3>
            </div>
          </div>

          <div className="flex align pps-icons-col2-item">
            <div className="pps-icons-col2-icon-div">
              <MdOutlineDashboardCustomize size="40px" className="pps-icon" />
            </div>
            <div>
              <h3>Include custom data fields in the scanning process</h3>
            </div>
          </div>

          <div className="flex align pps-icons-col2-item">
            <div className="pps-icons-col2-icon-div">
              <AiOutlineCloudUpload size="40px" className="pps-icon" />
            </div>
            <div>
              <h3>Assign scan to a list</h3>
            </div>
          </div>

          <div className="flex align pps-icons-col2-item">
            <div className="pps-icons-col2-icon-div">
              <MdLibraryAdd size="40px" className="pps-icon" />
            </div>
            <div>
              <h3>Assign scan to a campaign</h3>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="margin-0 padding-0 flex justify-center align pps-icons-contact">
        <Col lg="10" sm="10">
          <p className="margin-0">
            <span> Interested?</span> Why not download the app and trial the
            scanning functionality? Scan the QR code below to download.
          </p>
          <Row className="margin-0 padding-0 flex justify-center align pps-qr-row">
            {/* <Col
              lg="4"
              md="6"
              sm="6"
              xs="6"
              className="flex justify-center align"
            >
              <div>
                <img src={apple} />
                <div>
                  <a
                    href="https://apps.apple.com/gb/app/pardot-prospect-scanner/id1663176407"
                    target="_blank"
                  >
                    Apple Store
                  </a>
                </div>
              </div>
            </Col> */}
            <Col
              lg="4"
              md="6"
              sm="6"
              xs="6"
              className="flex justify-center align"
            >
              <div>
                <img src={google} />
                <div>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.marcloudtechnologies.pardotprospectscanner&pli=1"
                    target="_blank"
                  >
                    Google Play
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <p className="margin-0 text-align-center">
            You can also get in touch for more information or to arrange a demo.
          </p>
          <Link to="/ContactUs">
            <button className="button4">Arrange a demo</button>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default Icons;
