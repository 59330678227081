import * as React from "react";
import "./emailTemplates.css";
import EmailTemplateCard from "../../components/emailTemplateCard/emailTemplateCard";
import Hero from "../../components/solution/hero";
import hero from "../../assets/img/img22.png";
import Banner from "../../container/emailTemplates/banner/banner";
import FlippedCurveColor from "../../components/curve/flippedCurveColor";
import SolutionContact from "../../components/contact/solutionContact";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation";
import Curve from "../../components/curve/curveColor";
import { useCart } from "../../components/cartContext/cartContext";
import DummyEmailTemplateCard from "../../components/emailTemplateCard/dummyEmailTemplateCard";
import ShortNewsLetter from "../../assets/dummyProducts/Short-Newsletter.jpg";
import Webinar from "../../assets/dummyProducts/Webinar.jpg";
import WebinarAlternate from "../../assets/dummyProducts/Webinar-Alternate.jpg";
import NewProduct from "../../assets/dummyProducts/New-Product.jpg";
import Sales from "../../assets/dummyProducts/Sales.jpg";
import Accessibility from "../../assets/dummyProducts/Accessibility.jpg";
import ShortEvent from "../../assets/dummyProducts/Short-Event.jpg";
import SaveTheDate from "../../assets/dummyProducts/Save-the-Date.jpg";
import CtaAlternate from "../../assets/dummyProducts/CTA-Alternate.jpg";
import Discount from "../../components/discount/discount";
import { CiSearch } from "react-icons/ci";
import Row from "react-bootstrap/Row";

const EmailTemplates = ({ changeBg }) => {
  const { inCartMap, addToCart, removeFromCart } = useCart();
  const [products, setProducts] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const dummyProducts = [
    {
      name: "Short Newsletter",
      description:
        "A reduced length newsletter template specially designed for higher frequency sends, such as weekly updates, where you may not have as much content to share as your normal newsletter",
      img: ShortNewsLetter,
    },
    {
      name: "Webinar",
      description:
        "A robust email template with modules that support your webinar campaign. Clear CTAs, info boxes and design that captures all the key information for your audience",
      img: Webinar,
    },
    {
      name: "Webinar Alternate",
      description:
        "A robust email template with modules that support your webinar campaign. Clear CTAs, info boxes and design that captures all the key information for your audience. This template has a darker color scheme",
      img: WebinarAlternate,
    },
    {
      name: "New Products",
      description:
        "This image-heavy template will showcase your new product. Includes modules, such as feature lists that will present key info to your target audience.",
      img: NewProduct,
    },
    {
      name: "Sales",
      description:
        "Want to send an email as if it's from an account executive? This is the template for you. Use this template to give that personal touch for down-funnel email communication.",
      img: Sales,
    },
    {
      name: "Accessibility",
      description:
        "Fully compliant to accessibility standards, this template prioritises ease of use for those who find it hard to engage with typical email communications such as the partially sighted or color blind.",
      img: Accessibility,
    },
    {
      name: "Short Event",
      description:
        "Short and sweet. This template provides a minimalist approach to event awareness. Simple design with CTA and key info presented up top.",
      img: ShortEvent,
    },
    {
      name: "Save the Date",
      description:
        "You have the venue confirmed, you can get going building the hype. This robust save the date email highlights the key info to raise awareness of your event and get people talking.",
      img: SaveTheDate,
    },
    {
      name: "CTA Alternate",
      description:
        "An alternate design for our classic CTA email. Perfect for when you want to get your audience clicking away! Design used more dark and mid colors than the original.",
      img: CtaAlternate,
    },
  ];
  React.useEffect(() => {
    const color = "#e4ebf5";
    changeBg(color);
    fetch("/products", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ filter: "Email Template" }),
    })
      .then((r) => r.json())
      .then((jsonResponse) => setProducts(jsonResponse.productInfo));
  }, []);

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredDummyProducts = dummyProducts.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <>
      <div className="overflow-x">
        {/* <Discount /> */}
        <Hero
          title="Email Templates"
          subtitle="MarCloud's responsive"
          content="Want to send beautiful emails without worrying how they will appear once they reach the customer? Our tried and tested email templates are easy to brand and will look good in any inbox."
          heroImg={hero}
          imgClass="et-hero-img"
        />
        <div className="lightblue-bg">
          <div className="div-width">
            <Banner />

            <div className="flex align justify-center et-search-div">
              <div className="flex align et-search">
                <CiSearch size="20px" color="grey" />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>

            <div className="flex justify-center">
              <Row className="text-align-center et-card-container">
                {!searchTerm &&
                  products.map((product) => (
                    <EmailTemplateCard
                      key={product.priceId}
                      product={product}
                      addToCart={() => addToCart(product)}
                      removeFromCart={() => removeFromCart(product)}
                      inCart={inCartMap[product.priceId] || false}
                    />
                  ))}
                {!searchTerm &&
                  dummyProducts.map((product) => (
                    <DummyEmailTemplateCard
                      name={product.name}
                      description={product.description}
                      img={product.img}
                    />
                  ))}
                {searchTerm &&
                  filteredProducts.map((product) => (
                    <EmailTemplateCard
                      key={product.priceId}
                      product={product}
                      addToCart={() => addToCart(product)}
                      removeFromCart={() => removeFromCart(product)}
                      inCart={inCartMap[product.priceId] || false}
                    />
                  ))}
                {searchTerm &&
                  filteredDummyProducts.map((product) => (
                    <DummyEmailTemplateCard
                      name={product.name}
                      description={product.description}
                      img={product.img}
                    />
                  ))}
              </Row>
            </div>
          </div>
        </div>
        <FlippedCurveColor color="#e4ebf5" />
        <SolutionContact solution="Pardot Prospect Scanner" />
        <Curve color="#e4ebf5" />
        <div className="lightblue-bg">
          <div className="div-width">
            <BottomNavigation />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailTemplates;
