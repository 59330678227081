import * as React from "react";
import { GET_USER } from "../../utils/queries";
import { useQuery } from "@apollo/client";
import "./oauth.css";
import { AES, enc } from "crypto-js";
import { FaCheckCircle } from "react-icons/fa";
import { MdError } from "react-icons/md";
import loadingGif from "../../assets/loading1.gif"
const Oauth = ({ changeBg }) => {
  const color = "transparent";
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const userId = params.get("state");

  const [success, setSuccess] = React.useState();
  const [fail, setFail] = React.useState();

  const { error: userError, data: userData } = useQuery(GET_USER, {
    variables: { userId },
  });

  React.useEffect(() => {
    try {
      changeBg(color);
      if (userData) {
        handleRequest();
      }
    } catch (error) {
      console.log(error);
    }
  }, [changeBg, code, userId, userData]);

  React.useEffect(() => {
    if (success !== undefined || fail !== undefined) {
      const timeoutId = setTimeout(() => {
        window.close();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [success, fail]);

  const handleError = (message) => {
    window.opener.postMessage(
      { success: false, message: message },
      window.origin
    );
    setSuccess(false);
    setFail(true);
  };

  const handleRequest = async () => {
    try {
      let decryptedClientId = AES.decrypt(
        userData.getUser.clientId,
        process.env.REACT_APP_SECRET_KEY
      ).toString(enc.Utf8);
      let decryptedClientSecret = AES.decrypt(
        userData.getUser.clientSecret,
        process.env.REACT_APP_SECRET_KEY
      ).toString(enc.Utf8);

      const requestData = {
        grant_type: "authorization_code",
        client_id: decryptedClientId,
        client_secret: decryptedClientSecret,
        redirect_uri: process.env.REACT_APP_REDIRECT_URI,
        code: code,
      };

      const response = await fetch("/sf-auth", {
        method: "POST",
        body: JSON.stringify({ requestData }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Response Status:", response.status);
      if (response.ok) {
        const resultData = await response.json();
        const result = resultData.result;

        const accessToken = result.access_token;
        const instanceUrl = result.instance_url;
        const buidResponse = await fetch(
          `/sf-buids?accessToken=${accessToken}&instanceUrl=${instanceUrl}`,
          {
            method: "GET",
          }
        );
        if (buidResponse.ok) {
          const callResult = await buidResponse.json();
          const buList = callResult.successfulBUs;
          setSuccess(true);
          setFail(false);
          window.opener.postMessage(
            { accessToken, buList, instanceUrl, success: true },
            window.origin
          );
        } else {
          console.log("Authentication Error: Can't get business units");
          handleError(
            "Please ensure that you have entered the correct consumer details above and attempt the authentication again."
          );
        }
      } else {
        console.log("Authentication Error");
        handleError(
          "Please ensure that you have entered the correct consumer details above and attempt the authentication again."
        );
      }
    } catch (error) {
      console.log(error);
      handleError("Please try again.");
    }
  };

  return (
    <>
      {success && (
        <div className="flex justify-center align text-align-center oauth-div">
          <div>
            <FaCheckCircle color="green" size="100px" />
            <p className="title">Authentication Successful</p>
            <p>Please close the window</p>
          </div>
        </div>
      )}
      {fail && (
        <div className="flex justify-center align text-align-center oauth-div">
          <div>
            <MdError color="orange" size="100px" />
            <p className="title">Authentication Unsuccessful</p>
            <p>Please close the window, and try again</p>
          </div>
        </div>
      )}
      {!fail && !success && (
        <div className="flex justify-center align text-align-center oauth-div">
        <div>
          <img src={loadingGif} width="100px"/>
          <p className="title1">Authenticating</p>
          <p>Please wait and don't close the window</p>
        </div>
      </div>
      )}
    </>
  );
};

export default Oauth;
