import * as React from "react";
import "./bottomNavigation.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import img1 from "../../assets/img/img1.png";
import img2 from "../../assets/img/img11.png";
import img3 from "../../assets/img/img3.png";
import img4 from "../../assets/img/img7.png";
import { Link } from "react-router-dom";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
const BottomNavigation = () => {
  return (
    <Row className="text-align-center bottom-nav">
      <div>
        <h2>Need more solutions?</h2>
        <div className="orange-line"></div>
      </div>

      <Col
        lg="3"
        md="6"
        xs="12"
        className="flex justify-center align padding-0 bottom-nav-card "
      >
        <Link to="/EmailTemplates">
          <div className="flex justify-space-between align bottom-nav-card-div">
            <div>
              <img src={img1} width="110px" alt="solution" />
            </div>
            <div>
              <p>Responsive email templates</p>
              <div>
                <BsFillArrowRightCircleFill size="30px" color="orange" />
              </div>
            </div>
          </div>
        </Link>
      </Col>
      <Col
        lg="3"
        md="6"
        xs="12"
        className="flex justify-center align padding-0 bottom-nav-card"
      >
        <Link to="/PardotProspectScanner">
          <div className="flex justify-space-between align bottom-nav-card-div ">
            <div>
              <img src={img2} width="110px" alt="solution" />
            </div>
            <div>
              <p>Pardot Prospect Scanner</p>
              <div>
                <BsFillArrowRightCircleFill size="30px" color="orange" />
              </div>
            </div>
          </div>
        </Link>
      </Col>
      <Col
        lg="3"
        md="6"
        xs="12"
        className="flex justify-center align padding-0 bottom-nav-card"
      >
        <a
          href="https://training.marcloudconsulting.com/course/pardot-email-for-reluctant-coders"
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex justify-space-between align bottom-nav-card-div ">
            <div>
              <img src={img3} width="110px" alt="solution" />
            </div>
            <div>
              <p>Email template course</p>
              <div>
                <BsFillArrowRightCircleFill size="30px" color="orange" />
              </div>
            </div>
          </div>
        </a>
      </Col>
      <Col
        lg="3"
        md="6"
        xs="12"
        className="flex justify-center align padding-0 bottom-nav-card"
      >
        <Link to="/CustomSolutions">
          <div className="flex justify-space-between align bottom-nav-card-div ">
            <div>
              <img src={img4} width="130px" alt="solution" />
            </div>
            <div>
              <p>Custom solutions</p>
              <div>
                <BsFillArrowRightCircleFill size="30px" color="orange" />
              </div>
            </div>
          </div>
        </Link>
      </Col>
    </Row>
  );
};
export default BottomNavigation;
