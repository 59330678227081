import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { CHANGE_PASSWORD } from "../../../utils/mutations";
import ErrorComponent from "../../../components/error/error";
import { useMutation } from "@apollo/client";
import loadingGif from "../../../assets/loading1.gif";
import { TiTick } from "react-icons/ti";
import { RiLockPasswordLine } from "react-icons/ri";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

const PasswordForm = (props) => {
  const [changePassword] = useMutation(CHANGE_PASSWORD);
  const [loading, setLoading] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState();
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();
  const [expanded, setExpanded] = React.useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [formData, setFormData] = React.useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = async (e) => {
    setSuccessMessage(<></>);
    setError(false);
    setErrorMessage(<></>);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await changePassword({
        variables: {
          email: props.email,
          currentPassword: formData.currentPassword,
          newPassword: formData.newPassword,
          confirmPassword: formData.confirmPassword,
        },
      });
      setLoading(false);
      setFormData({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      setSuccessMessage(
        <div className="flex align password-error">
          <TiTick size="40px" color="green" className="password-error-icon" />
          Password changed successfully
        </div>
      );
      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.message || "Error changing password!");
      setError(true);
      console.error(error);
    }
  };
  const handleCancel = async (event) => {
    event.preventDefault();
    setSuccessMessage(<></>);
    setError(false);
    setErrorMessage(<></>);
    setFormData({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <div className="account-div">
        <div
          className="flex align justify-space-between"
          onClick={toggleExpanded}
        >
          <div className="flex align">
            <span>
              <RiLockPasswordLine color="#052e57" size="40px" />
            </span>
            <div>
              <h2>Password</h2>
              <h3>Change your password here </h3>
            </div>
          </div>
          {expanded ? "▲" : "▼"}
        </div>
        {expanded && (
          <div>
            <Form onSubmit={handleFormSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGroupPassword">
                  <Form.Label className="flex justify-space-between">
                    <div>Current Password</div>
                    <div>
                      {showCurrentPassword ? (
                        <IoMdEyeOff
                          color="black"
                          size="25px"
                          onClick={() =>
                            setShowCurrentPassword(!showCurrentPassword)
                          }
                        />
                      ) : (
                        <IoMdEye
                          color="black"
                          size="25px"
                          onClick={() =>
                            setShowCurrentPassword(!showCurrentPassword)
                          }
                        />
                      )}
                    </div>
                  </Form.Label>
                  <Form.Control
                    type={showCurrentPassword ? "text" : "password"}
                    placeholder="Enter current password"
                    name="currentPassword"
                    value={formData.currentPassword}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="account-new-password">
                  <Form.Label className="flex justify-space-between">
                    <div>New Password</div>
                    <div>
                      {showNewPassword ? (
                        <IoMdEyeOff
                          color="black"
                          size="25px"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        />
                      ) : (
                        <IoMdEye
                          color="black"
                          size="25px"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        />
                      )}
                    </div>
                  </Form.Label>
                  <Form.Control
                    type={showNewPassword ? "text" : "password"}
                    placeholder="Enter new password"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleChange}
                    required
                    aria-describedby="passwordHelpBlock"
                  />
                </Form.Group>
                <Form.Text id="passwordHelpBlock" muted className="account-password-help">
                  Your password must be minimum 8 characters, with at least 1
                  uppercase letter, 1 lowercase letter, 1 number & 1 special
                  character
                </Form.Text>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className="flex justify-space-between">
                    <div>Confirm Password</div>
                    <div>
                      {showConfirmPassword ? (
                        <IoMdEyeOff
                          color="black"
                          size="25px"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        />
                      ) : (
                        <IoMdEye
                          color="black"
                          size="25px"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        />
                      )}
                    </div>
                  </Form.Label>
                  <Form.Control
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm new password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Row>
              {successMessage}
              {error && <ErrorComponent errorMessage={errorMessage} />}
              {loading ? (
                <img src={loadingGif} width="50px" />
              ) : (
                <>
                  <Button
                    variant="primary"
                    type="submit"
                    className="account-form-save"
                  >
                    Save new password
                  </Button>
                  <Button
                    variant="warning"
                    type="button"
                    className="account-form-cancel"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Form>{" "}
          </div>
        )}
      </div>
    </>
  );
};

export default PasswordForm;
