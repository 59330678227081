import * as React from "react";
import "./aboutUsSection.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const AboutUsSection = (props) => {
  return (
    <div>
      <Row className="margin-0 padding-0 flex justify-center align aboutus-section offwhite-bg">
        <Col lg="8" className="flex justify-center align">
          <div className="div-width">
            <h2>{props.title}</h2>
            <h3>{props.subtitle}</h3>
            {props.content}
            {props.img && (
              <img
                className="aboutus-white-img"
                src={props.img}
                width={props.width}
                alt="about us"
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default AboutUsSection;
