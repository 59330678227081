import * as React from "react";
import "./purchases.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PurchasedProductCard from "../../components/purchasedProductCard/purchasedProductCard";
import jwtDecode from "jwt-decode";
import Auth from "../../utils/auth";
import { GET_USER } from "../../utils/queries";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
const Purchases = ({ changeBg }) => {
  const color = "transparent";
  const [userId, setUserId] = React.useState(null);
  const [products, setProducts] = React.useState();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    changeBg(color);
    const isLoggedIn = Auth.loggedIn();
    if (!isLoggedIn) {
      const prevPage = encodeURIComponent(window.location.href);
      window.location.assign(`/login?prev=${prevPage}`);
    }
    const decodeToken = () => {
      const token = localStorage.getItem("id_token");
      if (token) {
        try {
          const decoded = jwtDecode(token);
          setUserId(decoded.data._id);
        } catch (error) {
          console.error("Error decoding token:", error.message);
        }
      }
    };
    decodeToken();
  }, [changeBg]);

  const {
    loading: queryLoading,
    data,
  } = useQuery(GET_USER, {
    variables: { userId },
  });

  React.useEffect(() => {
    const fetchImages = async () => {
      if (data) {
        const allProducts = await Promise.all(
          data.getUser.purchases.flatMap(async (purchase) => {
            const purchaseId = purchase._id;
            return Promise.all(
              purchase.products.map(async (product) => {
                try {
                  const response = await fetch("/purchase-image", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      productId: product.stripeId,
                    }),
                  });
                  if (!response.ok) {
                    throw new Error("Failed to fetch image");
                  }
                  const json = await response.json();
                  return { ...product, image: json.image, purchaseId };
                } catch (error) {
                  console.error("Error fetching image:", error.message);
                  return null;
                }
              })
            );
          })
        );
        setProducts(allProducts.flat().filter((product) => product !== null));
        setLoading(false);
      }
    };
    fetchImages();
  }, [data]);
  return (
    <div>
      <Row className="div-width text-align-center ">
        <div className="text-align-left purchased-products">
          <h2>Purchased products</h2>
          {queryLoading || loading ? (
            <></>
          ) : (
            <Row>
              {products &&
                products.length > 0 &&
                products.map((product, index) => (
                  <Col
                    key={index}
                    xs="12"
                    md="4"
                    className="text-align-center purchased-products-col"
                  >
                    <PurchasedProductCard product={product} />
                  </Col>
                ))}{" "}
              {products && products.length === 0 && (
                <div>
                  <p>You haven't purchased any products yet</p>
                  <Link to="/EmailTemplates">
                    <button className="button4 purchased-products-browse-btn">
                      Browse Products
                    </button>
                  </Link>
                </div>
              )}
            </Row>
          )}
        </div>
      </Row>
    </div>
  );
};

export default Purchases;
