import * as React from "react";
import "./emailTemplateCard.css";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const EmailTemplateCard = (props) => {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    window.dataLayer.push({
      event: "product_detail_view",
      items: [
        {
          item_id: props.product.sku,
          item_name: props.product.name,
          item_category: props.product.category,
          item_brand: props.product.brand,
          price: props.product.cost,
        },
      ],
    });
  };
  function handleAddToCart() {
    props.addToCart(props.product);
    window.dataLayer.push({
      event: "add_to_cart",
      items: [
        {
          item_id: props.product.sku,
          item_name: props.product.name,
          item_category: props.product.category,
          item_brand: props.product.brand,
          price: props.product.cost,
        },
      ],
    });
  }
  function handleRemoveFromCart() {
    props.removeFromCart(props.product);
    window.dataLayer.push({
      event: "remove_from_cart",
      items: [
        {
          item_id: props.product.sku,
          item_name: props.product.name,
          item_category: props.product.category,
          item_brand: props.product.brand,
          price: props.product.cost,
        },
      ],
    });
  }
  return (
    <Col xs="12" md="6" lg="4" className="et-col">
      <div className="et-card-div">
        <div className="et-card-img-container">
          <img
            src={props.product.image}
            className="et-card-img"
            alt="email template"
          />
        </div>
        <div className="et-card-text-container">
          <div className="et-card-text-content">
            <p className="et-card-title">{props.product.name}</p>
            <p className="et-card-subtitle">{props.product.description}</p>
          </div>
          <p className="et-card-price">
            Price: {props.product.cost} {props.product.currency.toUpperCase()}
          </p>
          <div className="et-card-btn-container">
            <button
              className="et-view-btn"
              variant="primary"
              onClick={handleShow}
            >
              View template
            </button>
            {props.inCart ? (
              <button
                className="et-remove-from-cart"
                onClick={handleRemoveFromCart}
              >
                Remove From Cart
              </button>
            ) : (
              <button className="button4" onClick={handleAddToCart}>
                Add To Cart
              </button>
            )}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="et-modal-title">
            {props.product.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="et-modal-subtitle">{props.product.description}</p>
            <img
              src={props.product.image}
              className="et-modal-img"
              alt="email template"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="et-view-btn"
          >
            Close
          </Button>
          {props.inCart ? (
            <button
              className="et-remove-from-cart"
              onClick={() => {
                handleRemoveFromCart();
                handleClose();
              }}
            >
              Remove From Cart
            </button>
          ) : (
            <button
              className="button4"
              onClick={() => {
                handleAddToCart();
                handleClose();
              }}
            >
              Add To Cart
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </Col>
  );
};
export default EmailTemplateCard;
