import * as React from "react";
import "./account.css";
import { useQuery } from "@apollo/client";
import { GET_USER } from "../../utils/queries";
import jwtDecode from "jwt-decode";
import PasswordForm from "../../container/account/password/passwordForm";
import DetailsForm from "../../container/account/details/detailsForm";
import BuidSection from "../../container/account/buid/buidSection";
import ConsumerDetails from "../../container/account/consumerDetails/consumerDetails";
import Auth from "../../utils/auth";
import Alert from "react-bootstrap/Alert";

const Account = ({ changeBg }) => {
  const color = "transparent";
  const [userId, setUserId] = React.useState(null);
  const [email, setUserEmail] = React.useState(null);

  React.useEffect(() => {
    const isLoggedIn = Auth.loggedIn();
    if (!isLoggedIn) {
      const prevPage = encodeURIComponent(window.location.href);
      window.location.assign(`/login?prev=${prevPage}`);
    }

    changeBg(color);

    const decodeToken = () => {
      const token = localStorage.getItem("id_token");
      if (token) {
        try {
          const decoded = jwtDecode(token);
          setUserId(decoded.data._id);
          setUserEmail(decoded.data.email);
        } catch (error) {
          console.error("Error decoding token:", error.message);
        }
      }
    };
    decodeToken();
  }, []);

  const { error, data } = useQuery(GET_USER, {
    variables: { userId },
  });
  return (
    <>
      <div className="overflow-x stars-bg">
        <div className="div-width account-container">
          {data && (
            <>
              <DetailsForm user={data.getUser} />
              <PasswordForm email={email} />
              <ConsumerDetails user={data.getUser}/>
              <BuidSection user={data.getUser} />
            </>
          )}
          {error && (
            <Alert variant="warning" style={{ margin: "20px" }}>
              <Alert.Heading>Failed to load your account details</Alert.Heading>
              <p>Please make sure you are logged in and refresh the page!</p>
              <hr />
              <p className="mb-0">
                If the problem persists please contact our support team at{" "}
                <span className="bold">support@marcloudtechnologies.com</span>
              </p>
            </Alert>
          )}
        </div>
      </div>
    </>
  );
};

export default Account;
