import * as React from "react";
import "./login.css";
import qs from "qs";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Auth from "../../utils/auth.js";
import axios from "axios";
import { useMutation } from "@apollo/client";
import { LOGIN_USER } from "../../utils/mutations.js";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { AES, enc } from "crypto-js";
import { BiErrorCircle } from "react-icons/bi";
import loadingGif from "../../assets/loading1.gif";

const Login = ({ changeBg }) => {
  const color = "transparent";
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const prevPage = params.get("prev");

  React.useEffect(() => {
    changeBg(color);
  }, []);

  const actionUrl = process.env.REACT_APP_LOGIN_FORM_HANDLER;
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
  const [login, { error, data }] = useMutation(LOGIN_USER);
  const [formData, setFormData] = React.useState({ email: "", password: "" });
  const [rememberMe, setRememberMe] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decrypted_login_data = AES.decrypt(token, SECRET_KEY).toString(
        enc.Utf8
      );
      const params = new URLSearchParams(decrypted_login_data);
      const email = params.get("email");
      const password = params.get("password");
      setFormData({ email: email, password: password });
      setRememberMe(true);
    }
  }, []);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (rememberMe) {
      const login_data = qs.stringify({
        email: formData.email,
        password: formData.password,
      });
      const encrypted_login_data = AES.encrypt(
        login_data,
        SECRET_KEY
      ).toString();

      localStorage.setItem("token", encrypted_login_data);
    } else {
      localStorage.removeItem("token");
    }
    try {
      const { data } = await login({
        variables: { ...formData },
      });
      const prospect_data = {
        email: formData.email,
      };
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: actionUrl,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: prospect_data,
      };
      try {
        await axios.request(config);
        Auth.login(data.userLogin.token, prevPage);
        setLoading(false);
      } catch (e) {
        Auth.login(data.userLogin.token, prevPage);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };
  return (
    <div className="stars-bg">
      <Row className="div-width text-align-center flex justify-center login">
        <Col lg="5" md="8" sm="10" xs="11">
          <h2>Login</h2>
          <div className="blue-line"></div>
          <p className="login-subtitle">MarCloud Technologies Account Login</p>

          <Form onSubmit={handleFormSubmit}>
            {error && (
              <div className="flex align justify-center login-error">
                <BiErrorCircle
                  size="40px"
                  color="orange"
                  className="login-error-icon"
                />{" "}
                {error.message}
              </div>
            )}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="email"
                placeholder="Email"
                name="email"
                className="login-form-label"
                required
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                className="login-form-label"
                required
                value={formData.password}
                onChange={handleChange}
              />
            </Form.Group>

            <div className="flex align login-bottom">
              <Form.Group
                controlId="rememberMe-checkbox"
                className="mb-3 login-checkbox"
              >
                <Form.Check
                  name="checkbox-option"
                  type="checkbox"
                  label="Remember Me"
                  value="Remember Me"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
              </Form.Group>
              <div>
                <Link to="/ForgotPassword" className="login-forgot-pass">
                  Forgot Password?
                </Link>{" "}
              </div>
            </div>
            {loading ? (
              <img src={loadingGif} width="100px" />
            ) : (
              <Button
                variant="primary"
                type="submit"
                className="button4 login-button"
              >
                Log in
              </Button>
            )}
          </Form>

          <p className="login-bottom-subtitle">
            Don't have an account?{" "}
            <Link to="/signup" className="login-link">
              Sign Up
            </Link>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
