import React from "react";
import "./error.css"
import { BiErrorCircle } from "react-icons/bi";

const ErrorComponent = (props) => {
  return (
    <>
      <div className="flex align error">
        <BiErrorCircle
          size="40px"
          color="orange"
          className="error-icon"
        />
        {props.errorMessage}
      </div>
    </>
  );
};
export default ErrorComponent;
