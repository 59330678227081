import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./searchList.css";
import { CiSearch } from "react-icons/ci";

const SelectionModal = ({ items, isOpen, onClose, onSelect, renderItem, category }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15); // Number of items per page

  // Filtered items based on search term
  const filteredItems = items.filter((item) =>
    renderItem(item).toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Get current items for pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle selection of item
  const handleItemSelect = (item) => {
    onSelect(item);
    onClose();
  };

  useEffect(() => {
    setCurrentPage(1); // Reset page when items change
  }, [items, isOpen]);

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      size="l"
      aria-labelledby="contained-modal-title-vcenter"
      className="search-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="title">Choose a {category}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex align search-modal-div">
          <CiSearch size="20px" color="grey" />
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <ul className="padding-0 margin-0">
          {currentItems.map((item, index) => (
            <li key={index} onClick={() => handleItemSelect(item)}>
              {renderItem(item)}
            </li>
          ))}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <div>
          Page
          {Array.from({
            length: Math.ceil(filteredItems.length / itemsPerPage),
          }).map((_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={currentPage === index + 1 ? "bold" : ""}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectionModal;
