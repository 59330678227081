import * as React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./pages/home/home";
import TermsAndConditions from "./pages/termsAndConditions/termsAndConditions";
import PrivacyPolicy from "./pages/privacyPolicy/privacyPolicy";
import ContactUs from "./pages/contactUs/contactUs";
import AboutUs from "./pages/aboutUs/aboutUs";
import Solutions from "./pages/solutions/solutions";
import PardotProspectScanner from "./pages/pardotProspectScanner/pps/pardotProspectScanner";
import CustomPreferenceCenter from "./pages/customPreferenceCenter/customPreferenceCenter";
import PpsPackages from "./pages/pardotProspectScanner/pps-packages/pps-packages";
import CustomSolutions from "./pages/customSolutions/customSolutions";
import NavBar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import Curve from "./components/curve/curve";
import SignUp from "./pages/signup/signup";
import Login from "./pages/login/login";
import Reset from "./pages/password/reset";
import Email from "./pages/password/email";
import Dashboard from "./pages/dashboard/dashboard";
import Confirmation from "./pages/confirmation/confirmation";
// import NotFound from "./pages/404/404";
import EmailTemplates from "./pages/emailTemplates/emailTemplates";
import EmailTemplateReceipt from "./pages/emailTemplateReceipt/emailTemplateReceipt";
import AppReceipt from "./pages/appReceipt/appReceipt";
import Oauth from "./pages/oauth/oauth";
import Account from "./pages/account/account";
import Purchases from "./pages/purchases/purchases";
import Preview from "./pages/preview/preview";
import Receipts from "./pages/receipts/receipts";
import { CartProvider } from "./components/cartContext/cartContext";
import { PageViews } from "@piwikpro/react-piwik-pro";
import "./App.css";

// ******* Change httpLink for the database *******
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_DATABASE_LINK,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("id_token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function ScrollToTop() {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}
function App() {
  const [bgColor, setBgColor] = React.useState("");
  function changeBg(color) {
    setBgColor(color);
  }
  PageViews.trackPageView({ customPageTitle: "ContactUs" });
  return (
    <ApolloProvider client={client}>
      <CartProvider>
        <Router>
          {!window.location.pathname.startsWith("/sfauth") && <NavBar />}{" "}
          <div className="main">
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Home changeBg={changeBg} />} />
              <Route
                path="/TermsAndConditions"
                element={<TermsAndConditions changeBg={changeBg} />}
              />
              <Route
                path="/PrivacyPolicy"
                element={<PrivacyPolicy changeBg={changeBg} />}
              />
              <Route
                path="/Solutions"
                element={<Solutions changeBg={changeBg} />}
              />
              <Route
                path="/PardotProspectScanner"
                element={<PardotProspectScanner changeBg={changeBg} />}
              />
              <Route
                path="/CustomSolutions"
                element={<CustomSolutions changeBg={changeBg} />}
              />
              <Route
                path="/CustomPreferenceCenter"
                element={<CustomPreferenceCenter changeBg={changeBg} />}
              />
              <Route
                path="/AboutUs"
                element={<AboutUs changeBg={changeBg} />}
              />

              <Route
                path="/ContactUs"
                element={<ContactUs changeBg={changeBg} />}
              />
              <Route
                path="/EmailTemplates"
                element={<EmailTemplates changeBg={changeBg} />}
              />
              <Route
                path="/checkout/success"
                element={<EmailTemplateReceipt changeBg={changeBg} />}
              />
              <Route
                path="/subscription/success"
                element={<AppReceipt changeBg={changeBg} />}
              />
              <Route path="/SignUp" element={<SignUp changeBg={changeBg} />} />
              <Route
                path="/confirmation/:user"
                element={<Confirmation changeBg={changeBg} />}
              />
              <Route path="/Login" element={<Login changeBg={changeBg} />} />
              <Route
                path="/ResetPassword/:user"
                element={<Reset changeBg={changeBg} />}
              />
              <Route
                path="/ForgotPassword"
                element={<Email changeBg={changeBg} />}
              />
              <Route
                path="/Account"
                element={<Account changeBg={changeBg} />}
              />
              <Route
                path="/Purchases"
                element={<Purchases changeBg={changeBg} />}
              />
              <Route
                path="/Receipts"
                element={<Receipts changeBg={changeBg} />}
              />
              <Route
                path="/UseTemplate/:encryptedProduct"
                element={<Preview changeBg={changeBg} />}
              />
              <Route path="/sfauth" element={<Oauth changeBg={changeBg} />} />
              {/* <Route
              path="/PardotProspectScanner/Packages"
              element={<PpsPackages changeBg={changeBg} />}
            /> */}
              <Route
              path="/Subscriptions"
              element={<Dashboard changeBg={changeBg} />}
            />
              {/* <Route path="*" element={<Navigate to="/404" />} /> */}
              {/* <Route path="/404" element={<NotFound changeBg={changeBg} />} /> */}
            </Routes>
            {!window.location.pathname.startsWith("/sfauth") && (
              <div className="main-bottom">
                <Curve bgColor={bgColor} />
                <Footer />
              </div>
            )}
          </div>
        </Router>
      </CartProvider>
    </ApolloProvider>
  );
}

export default App;
