import React from "react";
import "./intro.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { BsCloudCheckFill } from "react-icons/bs";
const Intro = () => {
  const content =
    "Lorem ipsum dolor sit amet. Id dolor facilis At eligendi exerci tati onem est dolorem odio ";
  return (
    <>
      <Row className="margin-0 padding-0 pps-intro-row">
        <Col
          lg="12"
          className="margin-0 padding-0 flex justify-center text-align-center pps-intro-col1"
        >
          <div className="pps-intro-div">
            <h2>About the app</h2>
            <div className="orange-line"></div>
            <h3>
              The first app solution for integrating in-person events directly
              with Pardot
            </h3>
          </div>
        </Col>
        <Col
          lg="12"
          className="margin-0 padding-0 flex justify-center align pps-intro-col2"
        >
          <Row className="padding-0 margin-0 flex justify-center">
            <Col
              lg="10"
              md="11"
              sm="11"
              xs="11"
              className="pps-intro-col2-col1"
            >
              <h3 className="text-align-center">
                Why use the Pardot Prospect Scanner?
              </h3>
              <ul className="margin-0 padding-0">

                <li>
                  <BsCloudCheckFill
                    size="25px"
                    color="orange"
                    className="pps-intro-icon"
                  />{" "}
                  End bad-data and streamline your prospect capturing at
                  in-person events with MarCloud Technologies’ Pardot Prospect
                  Scanner.
                </li>
                <li>
                  <BsCloudCheckFill
                    size="25px"
                    color="orange"
                    className="pps-intro-icon"
                  />{" "}
                  Using powerful AI and leading OCR technology, our app will
                  scan business cards and with the minimum of user input,
                  accurately assign data to fields within Pardot. What’s more,
                  it can be used to assign scanned data to campaigns and lists
                  so that the scan can be the entry point to powerful automated
                  journeys.
                </li>

                <li>
                  <BsCloudCheckFill
                    size="25px"
                    color="orange"
                    className="pps-intro-icon"
                  />{" "}
                  Can be used to scan people into your in-person events so that
                  you can reliably record event attendance, and trigger the
                  sending of content in a timely manner that really resonates
                  with the customer’s journey.
                </li>
                <li>
                  <BsCloudCheckFill
                    size="25px"
                    color="orange"
                    className="pps-intro-icon"
                  />{" "}
                  Surpasses similar solutions as it uploads directly via
                  Marketing Cloud Account Engagement, meaning that data on
                  customers has to be shared between marketing and sales. It
                  also enables traditional B2B marketing by allowing marketing
                  to nurture and qualify leads before they are sent to
                  Salesforce CRM. No more opportunities missed due to
                  salespeople failing to record their data properly.
                </li>
              </ul>
              <div className="text-align-center">
                <Link to="/ContactUs">
                  <button className="button4">Enquire now</button>
                </Link>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Intro;
