import React from "react";
import "./section1.css";
import hero from "../../../assets/img/img2.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MdOutlineMailOutline } from "react-icons/md";
import { AiOutlineForm } from "react-icons/ai";
import { BsCode } from "react-icons/bs";
import { ImHtmlFive2 } from "react-icons/im";
import { TbWorldWww} from "react-icons/tb";
import { MdOutlineCookie } from "react-icons/md";

const Section1 = () => {
  return (
    <div className="cs-icons">
      <Row className="margin-0 padding-0">
        <Col
          lg="12"
          className="margin-0 padding-0 flex justify-center text-align-center"
        >
          <div className="cs-icons-div">
            <h2>Marketing Cloud Account Engagement (Pardot)</h2>
            <div className="orange-line"></div>
          </div>
        </Col>
      </Row>
      <Row className="margin-0 padding-0 flex justify-center align cs-icons-row">
        <Col lg="4" md="4" sm="12" className="margin-0 padding-0 cs-icons-col1">
          <div className="flex align cs-icons-col1-item">
            <div className="cs-icons-col1-text-div">
              <h3>Custom Email Preference Centre</h3>
            </div>
            <div className="cs-icons-col1-icon-div">
              <MdOutlineMailOutline size="40px" className="cs-icon" />
            </div>
          </div>

          <div className="flex align cs-icons-col1-item">
            <div className="cs-icons-col1-text-div">
              <h3>Custom form design and functionality
</h3>
            </div>
            <div className="cs-icons-col1-icon-div">
              <AiOutlineForm size="40px" className="cs-icon" />
            </div>
          </div>

          <div className="flex align cs-icons-col1-item">
            <div className="cs-icons-col1-text-div">
              <h3> Tracking/UTM integration</h3>
            </div>
            <div className="cs-icons-col1-icon-div">
              <BsCode size="40px" className="cs-icon" />
            </div>
          </div>
        </Col>

        <Col
          lg="4"
          md="4"
          sm="12"
          className="margin-0 padding-0 flex justify-center align cs-icons-img-col"
        >
          <div>
            <img src={hero} className="cs-icons-img" />
          </div>
        </Col>

        <Col lg="4" md="4" sm="12" className="margin-0 padding-0 cs-icons-col2">
          <div className="flex align cs-icons-col2-item cs-icons-col2-item-exception">
            <div className="cs-icons-col2-icon-div">
              <ImHtmlFive2 size="40px" className="cs-icon" />
            </div>
            <div>
              <h3>HTML/CSS email templates and landing pages</h3>
            </div>
          </div>

          <div className="flex align cs-icons-col2-item">
            <div className="cs-icons-col2-icon-div">
              <MdOutlineCookie size="40px" className="cs-icon" />
            </div>
            <div>
              <h3>Cookie Policy solutions</h3>
            </div>
          </div>

          <div className="flex align cs-icons-col2-item">
            <div className="cs-icons-col2-icon-div">
              <TbWorldWww size="40px" className="cs-icon" />
            </div>
            <div>
              <h3>General web troubleshooting</h3>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Section1;
