import * as React from "react";
import Hero from "../../components/solution/hero";
import FlippedCurveColor from "../../components/curve/flippedCurveColor";
import Section1 from "../../container/customSolutions/section1/section1";
import Section2 from "../../container/customSolutions/section2/section2";
import SolutionContact from "../../components/contact/solutionContact";
import "./customSolutions.css";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation";
import Curve from "../../components/curve/curveColor";
import hero from "../../assets/img/img13.png";
import { Link } from "react-router-dom";
import SEO from "../../components/seo/seo";

const CustomSolutions = ({ changeBg }) => {
  const color = "#e4ebf5";
  React.useEffect(() => {
    changeBg(color);
  }, []);
  function scrollToSection() {
    const section2 = document.getElementById("section1");
    section2.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <>
      {/* <SEO
        title="Custom Solutions"
        description="Do you have a specific requirement that can’t be met using the out of box capabilities of Sales Cloud, Marketing Cloud, or Marketing Cloud Account Engagement? We have developers who create custom-coded solutions to give you that capability."
      /> */}
      <div className="overflow-x">
        <Hero
          title="Custom Salesforce solutions"
          subtitle="Taking clients to greater heights"
          content="Do you have a specific requirement that can’t be met using the out of box capabilities of Sales Cloud, Marketing Cloud, or Marketing Cloud Account Engagement? We have developers who create custom-coded solutions to give you that capability."
          heroImg={hero}
          imgClass="cs-heroImg"
          actionButtons={
            <>
              <button
                className="button4 solution-hc-left-button1"
                onClick={scrollToSection}
              >
                Read more
              </button>
              <Link to="/ContactUs">
                <button className="button4">Enquire now</button>
              </Link>
            </>
          }
        />
      </div>
      <div className="offwhite-bg  ">
        <div className="div-width" id="section1">
          <Section1 />
        </div>
      </div>
      <FlippedCurveColor color="#f7f7f7" bgColor="#e4ebf5" />
      <div className="lightblue-bg  ">
        <div className="div-width">
          <Section2 />
        </div>
      </div>
      <SolutionContact solution="Custom Solutions" />
      <Curve color="#e4ebf5" />
      <div className="lightblue-bg">
        <div className="div-width">
          <BottomNavigation />
        </div>
      </div>
    </>
  );
};

export default CustomSolutions;
