import React from "react";
import "./contactUs.css";
import Contact from "../../components/contact/contact";
import img5 from "../../assets/img/img5.png"
const ContactUs = ({ changeBg }) => {
  const color = "transparent";
  React.useEffect(() => {
    changeBg(color);
  }, []);
  return (
    <div>
      <Contact img={img5}/>
    </div>
  );
};
export default ContactUs;
