import * as React from "react";
import "./aboutUsSection.css";
import "./team.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import nadine from "../../assets/team/nadine.png"
import anton from "../../assets/team/anton.png"
import tom from "../../assets/team/tom.png"
import brad from "../../assets/team/brad.png"

const Team = () => {
  return (
    <div>
      <Row className="margin-0 offwhite-bg team">
        <div className="div-width">
          <h2>Meet your crew</h2>
          <h5> A team of marketing consultants, developers, and project managers</h5>
          <Row className="flex justify-center  team-row">
            <Col lg="2" md="4" sm="6" xs="6">
              <div className="flex justify-center team-img-div">
                <img alt="team" src={tom} />
              </div>
              <h3>Tom Ryan</h3>
              <div className="orange-line"></div>
              <h4>Founder and CEO</h4>
            </Col>
            <Col lg="2" md="4" sm="6" xs="6">
              <div className="flex justify-center team-img-div">
                <img alt="team" src={anton} />
              </div>
              <h3>Anton Minnion</h3>
              <div className="orange-line"></div>
              <h4>Head of Technical Services</h4>
            </Col>
            <Col lg="2" md="4" sm="6" xs="6">
              <div className="flex justify-center team-img-div">
                <img alt="team" src={nadine} />
              </div>
              <h3>Nadine Mohsen</h3>
              <div className="orange-line"></div>
              <h4>Web Developer</h4>
            </Col>
            <Col lg="2" md="4" sm="6" xs="6">
              <div className="flex justify-center team-img-div">
                <img alt="team" src={brad}/>
              </div>
              <h3>Bradley Bayley</h3>
              <div className="orange-line"></div>
              <h4>Web Developer</h4>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  );
};
export default Team;
