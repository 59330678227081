import * as React from "react";
import "./preview.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GET_USER } from "../../utils/queries";
import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { AES, enc } from "crypto-js";
import { useParams } from "react-router-dom";
import { NEW_PREVIEW } from "../../utils/mutations";
import PublishTemplate from "../../container/preview/publishTemplate";
import ChooseBuid from "../../container/preview/chooseBuid";
import ChooseBranding from "../../container/preview/chooseBranding";
import jwtDecode from "jwt-decode";
import Auth from "../../utils/auth";
import loadingGif from "../../assets/loading1.gif";
import ErrorComponent from "../../components/error/error";

const Preview = ({ changeBg }) => {
  const color = "transparent";

  const { encryptedProduct } = useParams();
  const decryptedProduct = AES.decrypt(
    decodeURIComponent(encryptedProduct),
    process.env.REACT_APP_SECRET_KEY
  ).toString(enc.Utf8);
  const product = JSON.parse(decryptedProduct);

  const [newPreview] = useMutation(NEW_PREVIEW);
  const [userId, setUserId] = React.useState(null);
  const [chosenBuid, setChosenBuid] = React.useState();
  const [chosenBranding, setChosenBranding] = React.useState();
  const [folders, setFolders] = React.useState([]);
  const [campaigns, setCampaigns] = React.useState([]);
  const [domains, setDomains] = React.useState([]);
  const [iframeContent, setIframeContent] = React.useState("");
  const [previewLoading, setPreviewLoading] = React.useState(false);
  const [continueLoading, setContinueLoading] = React.useState(false);
  const [previewError, setPreviewError] = React.useState(false);
  const [show, setshow] = React.useState(false);
  const [authenticated, setAuthenticated] = React.useState(false);
  const [authenticationError, setAuthenticationError] = React.useState(false);
  const [continueError, setContinueError] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState();

  React.useEffect(() => {
    changeBg(color);
    const isLoggedIn = Auth.loggedIn();
    if (!isLoggedIn) {
      const prevPage = encodeURIComponent(window.location.href);
      window.location.assign(`/login?prev=${prevPage}`);
    }
    const decodeToken = () => {
      const token = localStorage.getItem("id_token");
      if (token) {
        try {
          const decoded = jwtDecode(token);
          setUserId(decoded.data._id);
        } catch (error) {
          console.error("Error decoding token:", error.message);
        }
      }
    };
    decodeToken();
  }, [changeBg]);

  const { data } = useQuery(GET_USER, {
    variables: { userId },
  });

  React.useEffect(() => {
    if (data && data.getUser.buids.length > 0 && !chosenBuid) {
      setChosenBuid(data.getUser.buids[0]);
    }
    if (
      data &&
      data.getUser.buids.length > 0 &&
      data.getUser.buids[0].brandings.length > 0 &&
      !chosenBranding
    ) {
      setChosenBranding(data.getUser.buids[0].brandings[0]);
    }
  }, [data, chosenBuid, chosenBranding]);

  const buidClick = async (buid) => {
    setPreviewError(false);
    setChosenBuid(buid);
  };
  const brandingClick = async (branding) => {
    setPreviewError(false);
    setChosenBranding(branding);
  };
  const onUpdateBranding = (updateBuid, updatedBranding) => {
    setChosenBuid(updateBuid);
    brandingClick(updatedBranding);
  };

  React.useEffect(() => {
    const storedAccessToken = sessionStorage.getItem("accessToken");

    if (storedAccessToken) {
      setAuthenticated(true);
      setAccessToken(storedAccessToken);
    }
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [chosenBuid]);

  const handleMessage = (event) => {
    const { accessToken, success, buList } = event.data;
    if (success === false) {
      console.log("success false");
      setAuthenticationError(true);
    } else if (success === true) {
      console.log("success true");
      console.log(accessToken, success, buList);
      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("businessUnits", JSON.stringify(buList));
      setAccessToken(accessToken);
      setAuthenticated(true);
      proceed(accessToken);
    }
  };

  const preview = async () => {
    setPreviewError(false);
    setPreviewLoading(true);
    const requestData = {
      templateRef: product.internalReference,
      address1: chosenBuid.address1,
      address2: chosenBuid.address2,
      copyrightText: chosenBuid.copyRightText,
      longName: chosenBuid.longName,
      profile: {
        name: chosenBranding.name,
        buttonStyle: chosenBranding.buttonStyle,
        highlightColor: chosenBranding.highlightColor,
        lightColor: chosenBranding.lightColor,
        midColor: chosenBranding.midColor,
        darkColor: chosenBranding.darkColor,
        copyFont: chosenBranding.copyFont,
        displayFont: chosenBranding.displayFont,
        preferenceLink: chosenBranding.preferenceLink,
        logo: chosenBranding.logo,
        facebook: chosenBranding.facebook,
        instagram: chosenBranding.instagram,
        twitter: chosenBranding.twitter,
        linkedIn: chosenBranding.linkedIn,
      },
    };
    try {
      const response = await fetch("/preview", {
        method: "POST",
        body: JSON.stringify({ requestData }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const resultData = await response.json();
        const result = resultData.sfResult;
        setIframeContent(result.html);
        setPreviewLoading(false);
        await newPreview({
          variables: { purchaseId: product.purchaseId, productId: product._id },
        });
      } else {
        setPreviewLoading(false);
        setPreviewError(true);
      }
    } catch (error) {
      setPreviewLoading(false);
      setPreviewError(true);
      console.error("Error:", error);
    }
  };

  const authenticate = async () => {
    setContinueLoading(true);
    setAuthenticationError(false);
    setContinueError(false);
    try {
      if (!authenticated) {
        let decryptedClientId = "";

        decryptedClientId = AES.decrypt(
          data.getUser.clientId,
          process.env.REACT_APP_SECRET_KEY
        ).toString(enc.Utf8);

        const encodedUserId = encodeURIComponent(userId);
        const clientId = decryptedClientId;
        const redirectUri = process.env.REACT_APP_REDIRECT_URI;
        const authUrl = `${data.getUser.instanceUrl}/services/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&state=${encodedUserId}`;

        window.open(authUrl, "_blank", "width=500,height=600");
      } else {
        proceed(accessToken);
      }
    } catch (error) {
      console.log(error);
      setContinueLoading(false);
      setAuthenticationError(true);
    }
  };

  const proceed = async (accessToken) => {
    const decryptedBuid = AES.decrypt(
      chosenBuid.buid,
      process.env.REACT_APP_SECRET_KEY
    ).toString(enc.Utf8);
    try {
      const foldersResponse = await fetch(
        `/folders?accessToken=${accessToken}&businessUnitId=${decryptedBuid}`,
        {
          method: "GET",
        }
      );
      if (foldersResponse.ok) {
        const foldersResult = await foldersResponse.json();
        setFolders(foldersResult.folders);
      } else {
        console.log("Folders Error");
        setContinueError(true);
      }

      const campaignsResponse = await fetch(
        `/campaigns?accessToken=${accessToken}&businessUnitId=${decryptedBuid}`,
        {
          method: "GET",
        }
      );
      if (campaignsResponse.ok) {
        const campaignsResult = await campaignsResponse.json();
        setCampaigns(campaignsResult.campaigns);
      } else {
        console.log("Campaigns Error");
        setContinueError(true);
      }

      const domainsResponse = await fetch(
        `/domains?accessToken=${accessToken}&businessUnitId=${decryptedBuid}`,
        {
          method: "GET",
        }
      );
      if (domainsResponse.ok) {
        const domainsResult = await domainsResponse.json();
        setDomains(domainsResult.domains);
        setContinueLoading(false);
        setshow(true);
      } else {
        console.log("Folders Error");
        setContinueLoading(false);
        setContinueError(true);
      }
    } catch (error) {
      console.error("Error fetching folders:", error);
      setContinueLoading(false);
      setContinueError(true);
    }
  };

  return (
    <div className="stars-bg">
      <div className="div-width preview">
        <Row className="padding-0 margin-0">
          <Col lg="6" md="12">
            <div className="preview-container">
              {show ? (
                <>
                  <PublishTemplate
                    campaigns={campaigns}
                    folders={folders}
                    domains={domains}
                    businessUnit={chosenBuid}
                    branding={chosenBranding}
                    product={product}
                    onBack={() => setshow(false)}
                  />
                </>
              ) : (
                <>
                  <h3>Preview and publish template</h3>
                  <h2>{product.name}</h2>
                  {data && (
                    <ChooseBuid
                      buids={data.getUser.buids}
                      buidClick={buidClick}
                      chosenBuid={chosenBuid}
                    />
                  )}
                  {chosenBuid && (
                    <>
                      <ChooseBranding
                        chosenBuid={chosenBuid}
                        chosenBranding={chosenBranding}
                        brandingClick={brandingClick}
                        onUpdate={onUpdateBranding}
                      />
                    </>
                  )}
                  {authenticationError && (
                    <ErrorComponent
                      errorMessage={
                        <p class="margin-0">
                          <span class="bold">Authentication Failed: </span>
                          If you encounter persistent issues, kindly reach out
                          to our support team at
                          support@marcloudtechnologies.com.
                        </p>
                      }
                    />
                  )}
                  {continueError && (
                    <ErrorComponent
                      errorMessage={
                        <p class="margin-0">
                          <span class="bold">Error, </span>
                          please try again!
                        </p>
                      }
                    />
                  )}
                  {previewError && (
                    <ErrorComponent errorMessage="Preview error, please try again!" />
                  )}
                  {previewLoading || continueLoading ? (
                    <img src={loadingGif} width="50px" alt="loading"/>
                  ) : (
                    <>
                      <div className="flex">
                        <button className="preview-btn" onClick={preview}>
                          Preview template
                        </button>
                        <button
                          className="button4 preview-continue-btn"
                          onClick={authenticate}
                        >
                          Continue
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
          {iframeContent && (
            <Col lg="6" md="12">
              <div className="preview-container preview-iframe">
                <iframe
                  title="Preview"
                  srcDoc={iframeContent}
                  style={{ width: "100%", height: "100vh", border: "none" }}
                ></iframe>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default Preview;
