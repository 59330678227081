import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./footer.css";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/colored-logo.png";
import { ImLinkedin2 } from "react-icons/im";
import { HiOutlineMail } from "react-icons/hi";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import partnerBadge from "../../assets/logo/partner-badge.png";
import consultingPartnerBadge from "../../assets/logo/consulting-partner-badge.png";

const Footer = () => {
  return (
    <div className="footer-div">
      <footer className="div-width">
        <Container>
          <Row>
            <Col lg="3" md="6" xs="6">
              <ul className="margin-0 padding-0">
                <li className="footer-title">Products</li>
                <div className="orange-line footer-title-line"></div>
                <li>
                  <Link to="/EmailTemplates">Email templates</Link>
                </li>
                <li>
                  <Link to="/PardotProspectScanner">
                    Pardot Prospect Scanner
                  </Link>
                </li>
                <li>
                  <a
                    href="https://training.marcloudconsulting.com/course/pardot-email-for-reluctant-coders"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Email Template Course
                  </a>
                </li>
                <li>
                  <Link to="/CustomSolutions">Custom solutions</Link>
                </li>
              </ul>
            </Col>
            <Col lg="3" md="6" xs="6">
              <ul className="margin-0 padding-0">
                <li className="footer-title">About Us</li>
                <div className="orange-line footer-title-line"></div>
                <li>
                  <Link to="/AboutUs">About MarCloud</Link>
                </li>
                <li>
                  <Link to="/AboutUs">What We Do</Link>
                </li>
              </ul>
            </Col>
            <Col lg="3" md="6" xs="6">
              <ul className="margin-0 padding-0">
                <li className="footer-title">Support</li>
                <div className="orange-line footer-title-line"></div>
                <li>
                  <Link to="/ContactUs">Contact Us</Link>
                </li>
              </ul>
            </Col>
            <Col lg="3" md="6" xs="6">
              <ul className="margin-0 padding-0">
                <li className="footer-title">Legal</li>
                <div className="orange-line footer-title-line"></div>
                <li>
                  <Link to="/TermsAndConditions">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/PrivacyPolicy">Privacy Policy</Link>
                </li>{" "}
              </ul>
            </Col>
          </Row>
          <Row className="flex justify-center footer-bottom">
            <Col lg="3" md="3" xs="4" className="flex align justify-center">
              <ul className="margin-0 padding-0 flex justify-center align footer-images-container">
                <li>
                  <img src={logo} alt="logo" className="footer-logo" />
                </li>
              </ul>
            </Col>
            <Col lg="3" md="3" xs="4" className="flex align justify-center">
              <ul className="margin-0 padding-0 flex justify-center align footer-images-container">
                <li>
                  <img
                    src={partnerBadge}
                    alt="certifications"
                    className="footer-certifications"
                  />
                </li>
                <li>
                  <img
                    src={consultingPartnerBadge}
                    alt="certifications"
                    className="footer-certifications"
                  />
                </li>
              </ul>
            </Col>
            <Col
              lg="3"
              md="1"
              xs="12"
              className="flex align justify-center"
            ></Col>
            <Col lg="3" md="3" xs="12" className="flex align justify-center">
              <ul className="margin-0 padding-0 flex align footer-icons-container">
                <li>
                  <a
                    href="https://www.linkedin.com/company/marcloud-consulting/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ImLinkedin2 size="35px" className="footer-icon" />
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:getintouch@marcloudconsulting.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <HiOutlineMail size="35px" className="footer-icon" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/marcloudc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsTwitter size="35px" className="footer-icon" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCAOSvJw88tffCX0o9dzeQBA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsYoutube size="35px" className="footer-icon" />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className="footer-banner">
        <p className="margin-0">
          © Copyright MarCloud {new Date().getFullYear()} | All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
