import * as React from "react";
import "./branding.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useMutation } from "@apollo/client";
import { ADD_BRANDING } from "../../utils/mutations";
import { FaFacebook } from "react-icons/fa";
import { RxInstagramLogo } from "react-icons/rx";
import { FaTwitter } from "react-icons/fa";
import { SiLinkedin } from "react-icons/si";
import ErrorComponent from "../error/error";
import { AES, enc } from "crypto-js";

const AddBranding = (props) => {
  const buid = props.buid || [];
  const decryptedBuid = AES.decrypt(
    buid.buid,
    process.env.REACT_APP_SECRET_KEY
  ).toString(enc.Utf8);
  const [addBranding] = useMutation(ADD_BRANDING);

  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();
  const [addBrandingModal, setAddBrandingModal] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState();

  const handleAddBranding = () => setAddBrandingModal(true);
  const handleCloseAddBranding = () => {
    setAddBrandingModal(false);
    setError(false);
    setErrorMessage(<></>);
    setBrandingFormData({
      brandingName: "",
      displayFont: "",
      copyFont: "",
      buttonStyle: "",
      preferenceLink: "",
      highlightColor: "#ed8f38",
      lightColor: "#8fe4ff",
      midColor: "#0f75bc",
      darkColor: "#052e57",
      logo: "",
      facebook: "",
      instagram: "",
      twitter: "",
      linkedIn: "",
    });
    setPreviewImage();
  };

  const [brandingFormData, setBrandingFormData] = React.useState({
    brandingName: "",
    displayFont: "",
    copyFont: "",
    buttonStyle: "",
    preferenceLink: "",
    highlightColor: "#ed8f38",
    lightColor: "#8fe4ff",
    midColor: "#0f75bc",
    darkColor: "#052e57",
    logo: "",
    facebook: "",
    instagram: "",
    twitter: "",
    linkedIn: "",
  });

  const handleBrandingChange = async (e) => {
    setError(false);
    setErrorMessage(<></>);
    if (e.target.type === "file") {
      const file = e.target.files[0];
      const reader = new FileReader();
      if (
        file &&
        ["image/png", "image/jpeg", "image/jpg"].includes(file.type)
      ) {
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        setPreviewImage();
      }
      setBrandingFormData({
        ...brandingFormData,
        [e.target.name]: file,
      });
    } else {
      setBrandingFormData({
        ...brandingFormData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleAddBrandingSubmit = async (e) => {
    e.preventDefault();
    if (
      !brandingFormData.logo ||
      !["image/png", "image/jpeg", "image/jpg"].includes(
        brandingFormData.logo.type
      )
    ) {
      setErrorMessage("Please select a PNG, JPG, or JPEG file.");
      setError(true);
      return;
    }

    const maxSizeInBytes = 100 * 1024;
    if (brandingFormData.logo.size > maxSizeInBytes) {
      setErrorMessage("Please select an image file smaller than 100KB.");
      setError(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", brandingFormData.logo);
    formData.append(
      "input",
      `{"name": "MarCloud - ${brandingFormData.brandingName} logo - Do Not Delete" }`
    );
    formData.append("accessToken", sessionStorage.getItem("accessToken"));
    formData.append("businessUnitId", decryptedBuid);
    try {
      const response = await fetch("/sf-save-image", {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const result = await response.json();
        const imageUrl = result.url;
        await addBranding({
          variables: {
            buidId: buid._id,
            brandingInput: {
              name: brandingFormData.brandingName,
              displayFont: brandingFormData.displayFont,
              copyFont: brandingFormData.copyFont,
              buttonStyle: brandingFormData.buttonStyle,
              preferenceLink: brandingFormData.preferenceLink || "",
              highlightColor: brandingFormData.highlightColor,
              lightColor: brandingFormData.lightColor,
              midColor: brandingFormData.midColor,
              darkColor: brandingFormData.darkColor,
              logo: imageUrl,
              facebook: brandingFormData.facebook || "",
              instagram: brandingFormData.instagram || "",
              twitter: brandingFormData.twitter || "",
              linkedIn: brandingFormData.linkedIn || "",
            },
          },
        });
        handleCloseAddBranding();
      }
    } catch (error) {
      console.error(error);
      console.error(error.graphQLErrors);
      setErrorMessage(error.message || "Error adding new branding!");
      setError(true);
    }
  };

  return (
    <>
      <div
        className="buid-branding-add flex justify-center align"
        onClick={handleAddBranding}
      >
        <p className="margin-0"> + Add new branding</p>
      </div>

      <Modal
        show={addBrandingModal}
        onHide={handleCloseAddBranding}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className="buid-branding-add-modal"
      >
        <Form className="account-buid-div" onSubmit={handleAddBrandingSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="title">
              Add new branding to <span className="bold">{buid.longName}</span>{" "}
              business unit
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row className="margin-0 padding-0">
              <Form.Group as={Col} xs={12} className="mb-3">
                <Form.Label>Branding name</Form.Label>
                <Form.Control
                  type="text"
                  name="brandingName"
                  required
                  onChange={handleBrandingChange}
                  value={brandingFormData.brandingName}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} className="mb-3">
                <Form.Label>Headings Font</Form.Label>
                <Form.Select
                  required
                  name="displayFont"
                  onChange={handleBrandingChange}
                  value={brandingFormData.displayFont}
                  aria-label="Default select example"
                  style={{ fontFamily: brandingFormData.displayFont }}
                >
                  <option></option>
                  <option value="Poppins">Poppins</option>
                  <option value="Open sans">Open Sans</option>
                  <option value="Roboto">Roboto</option>
                  <option value="Arial">Arial</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} className="mb-3">
                <Form.Label>Body Font</Form.Label>
                <Form.Select
                  required
                  name="copyFont"
                  onChange={handleBrandingChange}
                  value={brandingFormData.copyFont}
                  aria-label="Default select example"
                  style={{ fontFamily: brandingFormData.copyFont }}
                >
                  <option></option>
                  <option value="Poppins">Poppins</option>
                  <option value="Open Sans">Open Sans</option>
                  <option value="Roboto">Roboto</option>
                  <option value="Arial">Arial</option>
                  <option value="Titillium">Titillium</option>
                  <option value="Lato">Lato</option>
                  <option value="Paytone One">Paytone One</option>
                  <option value="Inter">Inter</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} className="mb-3">
                <Form.Label>Button style</Form.Label>
                <Form.Select
                  required
                  name="buttonStyle"
                  onChange={handleBrandingChange}
                  value={brandingFormData.buttonStyle}
                  aria-label="Default select example"
                >
                  <option></option>
                  <option value="Square">Square</option>
                  <option value="Rounded">Rounded</option>
                  <option value="Slightly Rounded">Slightly rounded</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} className="mb-3">
                <Form.Label>
                  Preference link
                  <span className="account-branding-optional">(optional)</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="preferenceLink"
                  onChange={handleBrandingChange}
                  value={brandingFormData.preferenceLink}
                />
              </Form.Group>
              <Form.Group as={Col} xs={6} md={3} className="mb-3">
                <Form.Label htmlFor="exampleColorInput">
                  Highlight Color
                </Form.Label>
                <Form.Control
                  type="color"
                  id="exampleColorInput"
                  title="Choose your color"
                  name="highlightColor"
                  onChange={handleBrandingChange}
                  value={brandingFormData.highlightColor}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xs={6} md={3} className="mb-3">
                <Form.Label htmlFor="exampleColorInput">Light Color</Form.Label>
                <Form.Control
                  type="color"
                  id="exampleColorInput"
                  title="Choose your color"
                  name="lightColor"
                  onChange={handleBrandingChange}
                  value={brandingFormData.lightColor}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xs={6} md={3} className="mb-3">
                <Form.Label htmlFor="exampleColorInput">Mid Color</Form.Label>
                <Form.Control
                  type="color"
                  id="exampleColorInput"
                  title="Choose your color"
                  name="midColor"
                  onChange={handleBrandingChange}
                  value={brandingFormData.midColor}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xs={6} md={3} className="mb-3">
                <Form.Label htmlFor="exampleColorInput">Dark Color</Form.Label>
                <Form.Control
                  type="color"
                  id="exampleColorInput"
                  title="Choose your color"
                  name="darkColor"
                  onChange={handleBrandingChange}
                  value={brandingFormData.darkColor}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Logo</Form.Label>
                <Form.Control
                  type="file"
                  name="logo"
                  onChange={handleBrandingChange}
                  required
                />
              </Form.Group>
              {previewImage && (
                <Col xs="12">
                  <Row>
                    <Col xs="12" md="8" lg="3">
                      <div className="buid-branding-logo-div flex align justify-center">
                        <img
                          src={previewImage}
                          className="buid-branding-logo"
                          alt="logo"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}

              <Form.Group as={Col} xs={12} md={6} className="mb-3">
                <Form.Label className="flex align">
                  <FaFacebook
                    color="#316FF6"
                    size="25px"
                    className="account-branding-social-icons"
                  />
                  Facebook
                  <span className="account-branding-optional">(optional)</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="facebook"
                  onChange={handleBrandingChange}
                  value={brandingFormData.facebook}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} className="mb-3">
                <Form.Label className="flex align">
                  <RxInstagramLogo
                    color="#E1306C"
                    size="25px"
                    className="account-branding-social-icons"
                  />
                  Instagram
                  <span className="account-branding-optional">(optional)</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="instagram"
                  onChange={handleBrandingChange}
                  value={brandingFormData.instagram}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} className="mb-3">
                <Form.Label className="flex align">
                  <SiLinkedin
                    color="#0077b5"
                    size="25px"
                    className="account-branding-social-icons"
                  />
                  LinkedIn
                  <span className="account-branding-optional">(optional)</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="linkedIn"
                  onChange={handleBrandingChange}
                  value={brandingFormData.linkedIn}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} className="mb-3">
                <Form.Label className="flex align">
                  <FaTwitter
                    color="#1DA1F2"
                    size="25px"
                    className="account-branding-social-icons"
                  />
                  X/Twitter
                  <span className="account-branding-optional">(optional)</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="twitter"
                  onChange={handleBrandingChange}
                  value={brandingFormData.twitter}
                />
              </Form.Group>
            </Row>
            {error && <ErrorComponent errorMessage={errorMessage} />}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="account-form-cancel"
              onClick={handleCloseAddBranding}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              className="account-form-save"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export default AddBranding;
