import * as React from "react";
import "./curve.css";
const FlippedCurve = (props) => {
  return (
    <div className="flex justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="hero-svg hero-flipped-svg"
        style={{ backgroundColor: props.bgColor }}
        viewBox="0 90 1440 100"
      >
        <path
          fill="#F7F7F7"
          fillOpacity="1"
          d="M0,96L60,112C120,128,240,160,360,170.7C480,181,600,171,720,154.7C840,139,960,117,1080,117.3C1200,117,1320,139,1380,149.3L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
};
export default FlippedCurve;
