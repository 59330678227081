import * as React from "react";
import "./banner.css";
import ship from "../../assets/ship.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const Banner = () => {
  return (
    <>
      <Row className="div-width flex padding-0 justify-center">
        <Col lg="7" md="9" xs="11" className="banner">
          <Row className="flex justify-center align banner-div">
            <Col xs="8" className="banner-div-col1">
              <h2>Need more than technical solutions? </h2>
              <p>
                Visit the MarCloud Consulting website at{" "}
                <a href="https://marcloudconsulting.com/" target="_blank" rel="noreferrer">
                  <span className="underline">www.marcloudconsulting.com</span>{" "}
                </a>
                to see the full range of support services available, including
                free Marketing Cloud audits, campaign management, and
                consulting.
              </p>
            </Col>
            <Col xs="3" className="banner-div-col2 flex justify-center align">
              <img src={ship} className="banner-img" alt="banner"/>
            </Col>
            <div className="flex justify-center stars-bg">
              <a href="https://marcloudconsulting.com/" target="_blank" rel="noreferrer">
                <button className="button3">Visit MarCloud Consulting</button>
              </a>
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default Banner;
