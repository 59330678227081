import * as React from "react";
import "./solutions.css";
import Row from "react-bootstrap/Row";
import HeroComponent from "../../components/heroComponent/heroComponent";
import hero3 from "../../assets/hero/hero3.png";
import OurProductsSection from "../../components/ourProductsSection/ourProductsSection";
import Contact from "../../components/contact/contact";
import pps from "../../assets/ss/pps.png";
import etc from "../../assets/img/img5.png";
import lpc from "../../assets/img/img7.png";
import ret from "../../assets/img/img8.png";
import lfp from "../../assets/img/img15.png";
import atp from "../../assets/img/img16.png";
import cpc from "../../assets/img/img18.png";
// import SEO from "../../components/seo/seo";

const OurProducts = ({ changeBg }) => {
  const color = "transparent";
  React.useEffect(() => {
    changeBg(color);
  });
  return (
    <>
      {/* <SEO
        title="MarCloud Technologies Solutions"
        description="Solving technical Salesforce challenges"
      /> */}
      <div className="overflow-x">
        <div className="stars-bg">
          <Row>
            <HeroComponent
              title="Solving technical Salesforce challenges"
              subtitle="See the solutions we’ve created to support marketing and sales teams"
              img={hero3}
              imgStyle="ourproducts-hero-img"
            />
          </Row>
        </div>
        <div className="text-align-center ourproducts-products-div lightblue-bg">
          <div className="div-width">
            <Row className="margin-0 flex justify-center ourProductsSection-row">
              <OurProductsSection
              img={ret}
              title="Responsive Email Templates"
              content={
                <p>
                  Want to send beautiful emails without worrying how they will
                  appear once they reach the customer? Our tried and tested
                  email templates are easy to brand and will look good in any
                  inbox.
                </p>
              }
              link="/EmailTemplates"
              imgStyle="ourproducts-products-div-ret"
            />
              <OurProductsSection
                img={lfp}
                title="Pardot Form & EPC - Look and Feel Package"
                content={
                  <p>
                    Let us implement a well built, responsive form and email
                    preference centre template for you. We will also provide
                    auto-resizing functionality to perfectly place your forms on
                    your responsive website!
                  </p>
                }
                link="https://pardot.marcloud.com/look-and-feel"
                target="_blank"
                imgStyle="ourproducts-products-div-lfp"
              />
              <OurProductsSection
                img={atp}
                title="Pardot Form Analytics and Tracking Package"
                content={
                  <p>
                    Track and collect robust UTM tracking in Pardot upon every
                    form submission that aligns with GA4.
                  </p>
                }
                link="https://pardot.marcloud.com/Pardot-Tracking-Package"
                target="_blank"
                imgStyle="ourproducts-products-div-atp"
              />
              <OurProductsSection
                img={cpc}
                title="Salesforce Marketing Cloud Engagement - Custom Preference Center"
                content={
                  <p>
                    Quick-build SFMCE Preference Center that can be fully
                    branded and is easy to maintain and edit
                  </p>
                }
                link="/CustomPreferenceCenter"
                imgStyle="ourproducts-products-div-cpc"
              />
              <OurProductsSection
                img={pps}
                title="Pardot Prospect Scanner"
                content={
                  <p>
                    End bad-data and streamline your lead capture at in-person
                    events with MarCloud Technologies’ Pardot Prospect Scanner
                    app for iOS and Android. Ensure accurate prospect data
                    enters Pardot first and immediately nurture leads for the
                    sales team.
                  </p>
                }
                link="/PardotProspectScanner"
                imgStyle="ourproducts-products-div-pps"
              />
              <OurProductsSection
                img={etc}
                title="Email template course"
                content={
                  <p>
                    ‘Pardot Email for the Reluctant Coder’ is a self-serve video
                    course focused on using Pardot eail templates to enable
                    simple, effective messaging that will connect with your
                    audiences. The course includes an industry-standard email
                    template resource.
                  </p>
                }
                link="https://training.marcloudconsulting.com/course/pardot-email-for-reluctant-coders"
                target="_blank"
                imgStyle="ourproducts-products-div-etc"
              />
              <OurProductsSection
                img={lpc}
                title="Custom solutions"
                content={
                  <p>
                    Marcloud Technologies works closely with our in-house
                    consulting team to develop readymade and custom solutions
                    for clients using Salesforce products, including Sales
                    Cloud, Marketing Cloud, and Marketing Cloud Account
                    Engagement (Pardot).
                  </p>
                }
                link="/CustomSolutions"
                imgStyle="ourproducts-products-div-cs"
              />
            </Row>
          </div>
        </div>
        <div className="section-top-padding">
          <Contact />
        </div>
      </div>
    </>
  );
};

export default OurProducts;
