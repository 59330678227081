import React from "react";
import "./subscription.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BiErrorCircle } from "react-icons/bi";

const Subscription = (props) => {
  const plan = props.plan;
  const subscriptionId = props.subscriptionId;
  const [cancelModal, setCancelModal] = React.useState(false);
  const [cancelError, setCancelError] = React.useState(false);
  const [resumeModal, setResumeModal] = React.useState(false);
  const [resumeError, setResumeError] = React.useState(false);

  const cancelSubscription = async () => {
    try {
      setCancelError(false);
      const response = await fetch("/app-cancel-subscription", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          subscriptionId: subscriptionId,
        }),
      });
      if (!response.ok) {
        setCancelError(true);
        throw new Error("Failed to fetch data");
      }
      await response.json();
      props.refreshPlan(subscriptionId);
      setCancelModal(false);
    } catch (error) {
      setCancelError(true);
      console.error(error);
    }
  };
  const resumeSubscription = async () => {
    try {
      setResumeError(false);
      const response = await fetch("/app-resume-subscription", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          subscriptionId: subscriptionId,
        }),
      });
      if (!response.ok) {
        setResumeError(true);
        throw new Error("Failed to fetch data");
      }
      await response.json();
      props.refreshPlan(subscriptionId);
      setResumeModal(false);
    } catch (error) {
      setResumeError(true);
      console.error(error);
    }
  };
  return (
    <>
      <div className="pps-subscription-div">
        <p className="margin-0 pps-subscription-title">Your subscription</p>
        <p className="pps-subscription-subtitle">
          Pardot prospect scanner subscription details
        </p>

        <Row className="flex justify-center">
          <Col xs="12">
            <Row className="pps-subscription-details-item">
              <Col className="bold">Status </Col>
              <Col>
                {plan.status.charAt(0).toUpperCase() + plan.status.slice(1)}
              </Col>
            </Row>
            <Row className="pps-subscription-details-item">
              <Col className="bold">Last payment date </Col>
              <Col>{plan.startDate}</Col>
            </Row>
            <Row className="pps-subscription-details-item">
              <Col className="bold">Next payment date </Col>
              <Col>{plan.nextPayment}</Col>
            </Row>
            <Row className="pps-subscription-details-item">
              <Col className="bold">Card used </Col>
              <Col>
                {plan.status === "active"
                  ? plan.cardBrand.toUpperCase() + " ending " + plan.cardLast4
                  : "-"}
              </Col>
            </Row>
            <Row className="pps-subscription-details-item">
              <Col className="bold">Interval </Col>
              <Col>
                {plan.status === "active"
                  ? plan.interval.charAt(0).toUpperCase() +
                    plan.interval.slice(1) +
                    "ly"
                  : "-"}
              </Col>
            </Row>
            <Row className="pps-subscription-details-item">
              <Col className="bold">Amount </Col>
              <Col>
                {plan.status === "active"
                  ? plan.price + " " + plan.currency.toUpperCase()
                  : "-"}
              </Col>
            </Row>
            {plan.status === "active" &&
            plan.cancelAt === null &&
            plan.endedAt === null ? (
              <Button variant="danger" onClick={() => setCancelModal(true)}>
                Cancel subscription
              </Button>
            ) : plan.status === "active" &&
              plan.cancelAt !== null &&
              plan.endedAt === null ? (
              <Button className="button4" onClick={() => setResumeModal(true)}>
                Resume subscription
              </Button>
            ) : (
              <Button className="button4" onClick={props.resubscribe}>
                Resubscribe
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <Modal
        show={cancelModal}
        onHide={() => setCancelModal(false)}
        className="pps-subscription-cancel-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="title">
            Cancel Pardot Prospect Scanner Subscription
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span className="bold">
              Are you sure you would like to cancel your subscription?
            </span>
            You won't be able to use the Pardot Prospect Scanner App after your
            license expiration date. If you change your mind, you can resume
            your subscription before the end of the current license. Otherwise a
            new subscription should be made.
          </div>
          {cancelError && (
            <div className="flex align error">
              <BiErrorCircle
                size="30px"
                color="orange"
                className="error-icon"
              />
              Error canceling subscription. Please try again!
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setCancelModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={cancelSubscription}>
            Cancel subscription
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={resumeModal}
        onHide={() => setResumeModal(false)}
        className="pps-subscription-resume-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="title">
            Resume Pardot Prospect Scanner Subscription
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span className="bold">
              Are you sure you would like to resume your subscription?
            </span>
            Your{" "}
            {plan.cardBrand.toUpperCase() + " card ending " + plan.cardLast4}{" "}
            will be charged again on the {plan.endDate}.
          </div>
          {resumeError && (
            <div className="flex align error">
              <BiErrorCircle
                size="30px"
                color="orange"
                className="error-icon"
              />
              Error resuming subscription. Please try again!
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setResumeModal(false)}>
            Close
          </Button>
          <Button variant="success" onClick={resumeSubscription}>
            Resume subscription
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Subscription;
