import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import cpc from "../../../assets/ss/cpc.png";
import { AiOutlineCheck } from "react-icons/ai";
import "./section1.css";

const Section1 = () => {
  return (
    <Row>
      <Col lg="5" md="12" className="flex justify-center align">
        <div className="cpc1-container">
          <div className="cpc1-h2-div">
            <h2>MarCloud’s Marketing Cloud Preference Center</h2>
          </div>
          <ul>
            <li className="flex align">
              <span>
                <AiOutlineCheck color="white" className="cpc1-check" />
              </span>
              <p className="margin-0">Fully responsive</p>
            </li>
            <li className="flex align">
              <span>
                <AiOutlineCheck color="white" className="cpc1-check" />
              </span>
              <p className="margin-0">Includes a complete branding solution</p>
            </li>
            <li className="flex align">
              <span>
                <AiOutlineCheck color="white" className="cpc1-check" />
              </span>
              <p className="margin-0">Easily add and remove preferences</p>
            </li>
            <li className="flex align">
              <span>
                <AiOutlineCheck color="white" className="cpc1-check" />
              </span>
              <p className="margin-0">Automate preference data collection</p>
            </li>
            <li className="flex align">
              <span>
                <AiOutlineCheck color="white" className="cpc1-check" />
              </span>
              <p className="margin-0">
                Can be enhanced with custom development, for example:
              </p>
            </li>
            <div className="cpc1-example">
              <ul>
                <li>Include hierarchical preferences</li>
                <li>Integrate with 3rd party systems</li>
                <li>Deploy on CMS system</li>
              </ul>
            </div>
          </ul>
        </div>
      </Col>
      <Col lg="7" md="12" className="flex justify-center align">
          <div className="flex justify-center">
            <img src={cpc} className="cpc1-img" />
        </div>
      </Col>
    </Row>
  );
};

export default Section1;
