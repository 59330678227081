import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./section2.css";
import email from "../../../assets/img/img8.png";
import { Link } from "react-router-dom";
const Section2 = () => {
  return (
    <Row className="flex justify-center align margin-0 padding-0">
      <Col lg="12" className="margin-0 padding-0">
        <div className="cpc2-div">
          <div className="flex justify-center align cpc2-title-div">
            <img src={email} />
            <h2 className="margin-0">
              Enhance Your Email Marketing Strategy with MarCloud's Advanced
              Preference Center
            </h2>
          </div>
          <div>
            <p>
              Once a contact clicks the global unsubscribe link they are gone
              forever. Most marketers know that this is the reality of email
              marketing, and that the only opportunity you have to stop this
              happening is to present your contacts with an attractive
              alternative. This is why more and more companies look to deploy a
              preference center - this gives the contact the opportunity to
              selectively unsubscribe from communications.
            </p>
            <p>
              Unfortunately the legacy subscription center in SFMCE is very
              basic with only limited branding options. This underwhelming
              experience is unlikely to stop anyone from going direct to the
              global unsubscribe option
            </p>
            <p>
              MarCloud’s preference center is different. It offers a better
              branded solution with superior user-flow that will clearly present
              your contacts with an alternative to the global unsubscribe and
              help reduce your unsubscribe rate. Moreover, by capturing your
              contact’s preferences, it will help you better target them with
              content.
            </p>
          </div>

          <div className="flex justify-center align">
            <Link to="/ContactUs">
              <button className="button4">Enquire Now</button>
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Section2;
