import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BuidForm from "./buidForm";
import BuidCard from "../../../components/buidCard/buidCard";
import { AES, enc } from "crypto-js";
import { IoIosSettings } from "react-icons/io";
import { BiErrorCircle } from "react-icons/bi";

const BuidSection = (props) => {
  const buids = props.user.buids || [];
  const userId = props.user._id;
  const subscriptions = props.user.appSubscriptions;
  let isSubscribed;
  let appBuid;
  if (!subscriptions || subscriptions.length === 0) {
    isSubscribed = false;
  } else {
    isSubscribed = true;
    appBuid = subscriptions[subscriptions.length - 1].buidId;
  }
  const [buList, setBuList] = React.useState([]);
  const [filteredBuList, setFilteredBuList] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState("");
  const [authenticated, setAuthenticated] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [buidFormVisibility, setBuidFormVisibility] = React.useState();
  const [selectedBuid, setSelectedBuid] = React.useState(appBuid);

  const updateFilteredBuList = () => {
    let storedBuList = sessionStorage.getItem("businessUnits");
    if (storedBuList) {
      storedBuList = JSON.parse(storedBuList);
      const filteredList = storedBuList.filter(
        (bu) =>
          !buids.some(
            (item) =>
              AES.decrypt(item.buid, process.env.REACT_APP_SECRET_KEY).toString(
                enc.Utf8
              ) === bu.Id
          )
      );

      setFilteredBuList(filteredList);

      if (filteredList.length === 0) {
        setBuidFormVisibility(false);
      } else {
        setBuidFormVisibility(true);
      }
    }
  };
  React.useEffect(() => {
    const storedAccessToken = sessionStorage.getItem("accessToken");

    if (storedAccessToken) {
      setAuthenticated(true);
    }

    updateFilteredBuList();

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [buids, buList]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const handleMessage = (event) => {
    const { accessToken, success, buList, message } = event.data;
    if (success === false) {
      setErrorMessage(
        <p class="margin-0">
          <span class="bold">Authentication Failed:</span> {message}
          If you encounter persistent issues, kindly reach out to our support
          team at support@marcloudtechnologies.com.
        </p>
      );
    } else if (success === true) {
      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("businessUnits", JSON.stringify(buList));
      setAccessToken(accessToken);
      setBuList(buList);
      setAuthenticated(true);
    }
  };
  const authenticationProcess = async (event) => {
    try {
      if (
        !props.user.clientId ||
        props.user.clientId === "" ||
        !props.user.clientSecret ||
        props.user.clientSecret === "" ||
        !props.user.instanceUrl ||
        props.user.instanceUrl === ""
      ) {
        setErrorMessage(
          <p className="margin-0">
            Please ensure you have provided your{" "}
            <span className="bold">Client Id</span>,{" "}
            <span className="bold">Client Secret</span> and{" "}
            <span className="bold">Instance Url</span> in the section above
            titled <span className="bold">Consumer Details</span>
          </p>
        );
      } else {
        setErrorMessage("");
        let decryptedClientId = "";

        decryptedClientId = AES.decrypt(
          props.user.clientId,
          process.env.REACT_APP_SECRET_KEY
        ).toString(enc.Utf8);

        const encodedUserId = encodeURIComponent(userId);
        const clientId = decryptedClientId;
        const redirectUri = process.env.REACT_APP_REDIRECT_URI;
        const authUrl = `${props.user.instanceUrl}/services/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&state=${encodedUserId}`;
        console.log(authUrl)
        window.open(authUrl, "_blank", "width=500,height=600");
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(
        <p className="margin-0">Authentication error, please try again!</p>
      );
    }
  };
  const handleCheckboxChange = (buidId) => {
    setSelectedBuid(buidId);
  };
  return (
    <>
      <div className="account-div">
        <div
          className="flex align justify-space-between"
          onClick={toggleExpanded}
        >
          <div className="flex align">
            <span>
              <IoIosSettings color="#052e57" size="40px" />
            </span>
            <div>
              <h2>Business Units</h2>
              <h3>Manage your Business Units here</h3>
            </div>
          </div>
          {expanded ? "▲" : "▼"}
        </div>
        {expanded && (
          <>
            {authenticated ? (
              <>
                <Row className="account-buids">
                  {buids.length === 0 && <p>No existing business units.</p>}
                  {buids.map((buid, index) => (
                    <Col
                      key={index}
                      xs={12}
                      className="flex align justify-center text-align-center account-buid-card-col"
                    >
                      <BuidCard
                        buid={buid}
                        isSubscribed={isSubscribed}
                        checked={selectedBuid === buid._id}
                        onCheckboxChange={handleCheckboxChange}
                      />
                    </Col>
                  ))}
                </Row>
                {buidFormVisibility && <BuidForm buList={filteredBuList} />}
              </>
            ) : (
              <>
                <p className="margin-0 account-buids-auth">
                  Authorise access with Salesforce to oversee and administer
                  your business units.
                </p>
                <p>
                  If clicking the button below does not redirect you to the
                  Salesforce login page, kindly verify that the consumer details
                  entered in the above section are accurate.
                </p>
                {errorMessage && (
                  <div className="flex align error">
                    <BiErrorCircle
                      size="40px"
                      color="orange"
                      className="error-icon"
                    />
                    {errorMessage}
                  </div>
                )}
                <button className="button4" onClick={authenticationProcess}>
                  Authenticate with Salesforce
                </button>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default BuidSection;
