import * as React from "react";
import "./home.css";
import Hero from "../../container/home/homeHero/homeHero";
import Products from "../../container/home/homeProducts/homeProducts";
import Contact from "../../components/contact/contact";
import Curve from "../../components/curve/curve";
import FlippedCurve from "../../components/curve/flippedCurve.jsx";
import FlippedCurveColor from "../../components/curve/flippedCurveColor.jsx";
import AboutUsSectionWhite from "../../components/aboutUsSection/aboutUsSectionWhite";
import Banner from "../../components/banner/banner";
import { Link } from "react-router-dom";
import SEO from "../../components/seo/seo";
// import { useQuery} from "@apollo/client";
// import { GET_ALL_USERS } from "../../utils/queries.js";

const Home = ({ changeBg }) => {
  const color = "transparent";
  React.useEffect(() => {
    changeBg(color);
  }, []);
  // const { loading, error, data } = useQuery(GET_ALL_USERS);
  // console.log(data)
  return (
    <>
      {/* <SEO
        title="MarCloud Technologies"
        description="Discover out-of-this-world technical solutions for Salesforce CRM & Salesforce Marketing Cloud"
      /> */}
      <Hero />
      <FlippedCurve bgColor="#e4ebf5" />
      <div className="margin-0 padding-0 lightblue-bg">
        <Products />
        <Curve />
        <AboutUsSectionWhite
          title="What We Do"
          subtitle="The MarCloud Technologies mission"
          content={
            <>
              <div>
                MarCloud Technologies was created to provide solutions for many
                of the common pain points endured by marketing and sales teams
                who use Salesforce products. We all know that Salesforce
                provides the most complete ecosystem when it comes to sales and
                marketing, but we also know that sometimes we find ourselves
                stuck looking for a solution that doesn’t exist or where the
                workaround is inefficient. That’s where MarCloud Technologies
                fits in. Our team of expert software engineers work in
                conjunction with our experienced and talented consultants to
                provide genuinely useful tools to help you with your day-to-day
                marketing and sales workflows.
              </div>
              <Link to="/AboutUs">
                <button className="button4" style={{ marginTop: 40 }}>
                  More about us
                </button>
              </Link>
            </>
          }
        />
        <FlippedCurveColor color="#F7F7F7" />
        <Banner />
      </div>
      <FlippedCurveColor color="#e4ebf5" />
      <Contact />
    </>
  );
};

export default Home;
