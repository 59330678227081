import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ADD_BUID } from "../../../utils/mutations";
import { useMutation } from "@apollo/client";
import check from "../../../assets/check.gif";
import { FaFacebook } from "react-icons/fa";
import { RxInstagramLogo } from "react-icons/rx";
import { FaTwitter } from "react-icons/fa";
import { SiLinkedin } from "react-icons/si";
import loadingGif from "../../../assets/loading1.gif";
import "./buid.css";
import ErrorComponent from "../../../components/error/error";
import Dropdown from "react-bootstrap/Dropdown";
const BuidForm = (props) => {
  const buList = props.buList;

  const [addBuid] = useMutation(ADD_BUID);
  const [showModal, setShowModal] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const [showbutton, setShowButton] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();
  const [previewImages, setPreviewImages] = React.useState([]);

  const [buidFormData, setBuidFormData] = React.useState();
  const [brandings, setBrandings] = React.useState([
    {
      name: "",
      displayFont: "",
      copyFont: "",
      buttonStyle: "",
      preferenceLink: "",
      highlightColor: "#ed8f38",
      lightColor: "#8fe4ff",
      midColor: "#0f75bc",
      darkColor: "#052e57",
      logo: "",
      facebook: "",
      instagram: "",
      twitter: "",
      linkedIn: "",
    },
  ]);

  const handleCloseModal = () => setShowModal(false);

  const toggleshowForm = () => {
    setShowForm(true);
    setShowButton(false);
  };
  const handleDropdownItemClick = (selectedBuId, selectedPardotTenantName) => {
    setBuidFormData({
      buid: selectedBuId,
      shortName: selectedPardotTenantName,
      longName: "",
      address1: "",
      address2: "",
      copyRightText: "",
      privacyPolicy: "",
    });
    toggleshowForm();
  };
  const handleCancel = async (event) => {
    event.preventDefault();
    setPreviewImages([]);
    setShowForm(false);
    setShowButton(true);
    setError(false);
    setErrorMessage(<></>);
    setBrandings([
      {
        name: "",
        displayFont: "",
        copyFont: "",
        buttonStyle: "",
        preferenceLink: "",
        highlightColor: "#ed8f38",
        lightColor: "#8fe4ff",
        midColor: "#0f75bc",
        darkColor: "#052e57",
        logo: "",
        facebook: "",
        instagram: "",
        twitter: "",
        linkedIn: "",
      },
    ]);
  };
  const handleAddBranding = () => {
    setBrandings([
      ...brandings,
      {
        name: "",
        displayFont: "",
        copyFont: "",
        buttonStyle: "",
        preferenceLink: "",
        highlightColor: "#ed8f38",
        lightColor: "#8fe4ff",
        midColor: "#0f75bc",
        darkColor: "#052e57",
        logo: "",
        facebook: "",
        instagram: "",
        twitter: "",
        linkedIn: "",
      },
    ]);
  };
  const handleRemoveBranding = (index) => {
    setError(false);
    setErrorMessage(<></>);
    const updatedBrandings = [...brandings];
    const updatedPreviewImages = [...previewImages];

    updatedBrandings.splice(index, 1);
    updatedPreviewImages.splice(index, 1);

    setBrandings(updatedBrandings);
    setPreviewImages(updatedPreviewImages);
  };

  const handleBrandingChange = (index, key, value) => {
    setError(false);
    setErrorMessage(<></>);
    const updatedBrandings = [...brandings];
    const updatedPreviewImages = [...previewImages];
    if (key === "logo") {
      const reader = new FileReader();
      if (
        value &&
        ["image/png", "image/jpeg", "image/jpg"].includes(value.type)
      ) {
        reader.onloadend = () => {
          updatedPreviewImages[index] = reader.result;
          setPreviewImages(updatedPreviewImages);
        };
        reader.readAsDataURL(value);
      } else {
        updatedPreviewImages[index] = null;
        setPreviewImages(updatedPreviewImages);
      }
      updatedBrandings[index] = {
        ...updatedBrandings[index],
        [key]: value,
      };
    } else {
      updatedBrandings[index] = {
        ...updatedBrandings[index],
        [key]: value,
      };
    }
    setBrandings(updatedBrandings);
  };
  const handleBuidChange = async (e) => {
    setError(false);
    setErrorMessage(<></>);
    setBuidFormData({
      ...buidFormData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const newBrandings = [];

    // for (const [branding] of brandings.entries()) {
    for (let i = 0; i < brandings.length; i++) {
      const branding = brandings[i];
      console.log(branding);
      if (
        !branding.logo ||
        !["image/png", "image/jpeg", "image/jpg"].includes(branding.logo.type)
      ) {
        setErrorMessage("Please select a PNG, JPG, or JPEG file.");
        setError(true);
        setLoading(false);
        return;
      }

      const maxSizeInBytes = 100 * 1024;
      if (branding.logo.size > maxSizeInBytes) {
        setErrorMessage("Please select an image file smaller than 100KB.");
        setError(true);
        setLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append("file", branding.logo);
      formData.append(
        "input",
        `{"name": "MarCloud - ${branding.name} logo - Do Not Delete" }`
      );
      formData.append("accessToken", sessionStorage.getItem("accessToken"));
      formData.append("businessUnitId", buidFormData.buid);

      try {
        const response = await fetch("/sf-save-image", {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const result = await response.json();
          const imageUrl = result.url;

          newBrandings.push({
            name: branding.name,
            buttonStyle: branding.buttonStyle,
            highlightColor: branding.highlightColor,
            lightColor: branding.lightColor,
            midColor: branding.midColor,
            darkColor: branding.darkColor,
            copyFont: branding.copyFont,
            displayFont: branding.displayFont,
            preferenceLink: branding.preferenceLink || "",
            logo: imageUrl,
            facebook: branding.facebook || "",
            instagram: branding.instagram || "",
            twitter: branding.twitter || "",
            linkedIn: branding.linkedIn || "",
          });
        }
      } catch (error) {
        setLoading(false);
        setErrorMessage(error.message || "Error adding business unit!");
        setError(true);
        console.error(error);
        return;
      }
    }

    const buid = {
      ...buidFormData,
      brandings: newBrandings,
    };

    try {
      await addBuid({
        variables: {
          buid,
        },
      });

      setPreviewImages([]);
      setShowForm(false);
      setShowButton(true);
      setLoading(false);
      setBuidFormData({
        buid: "",
        shortName: "",
        longName: "",
        address1: "",
        address2: "",
        copyRightText: "",
        privacyPolicy: "",
      });
      setBrandings([
        {
          name: "",
          displayFont: "",
          copyFont: "",
          buttonStyle: "",
          preferenceLink: "",
          highlightColor: "#ed8f38",
          lightColor: "#8fe4ff",
          midColor: "#0f75bc",
          darkColor: "#052e57",
          logo: "",
          facebook: "",
          instagram: "",
          twitter: "",
          linkedIn: "",
        },
      ]);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 2000);
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.message || "Error adding business unit!");
      setError(true);
      console.error(error);
    }
  };

  return (
    <>
      <div>
        {showbutton && (
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="button4"
            >
              Add a business unit
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {buList.map((bu, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() =>
                    handleDropdownItemClick(bu.Id, bu.PardotTenantName)
                  }
                >
                  {bu.Id}, {bu.PardotTenantName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      {showForm && (
        <Form className="account-buid-div" onSubmit={handleSubmit}>
          <Row className="account-buid">
            <p className="title margin-0">New Business unit</p>
            <p className="subtitle">
              Please fill in the below details to add a new business unit to
              your account. You can still edit all the details after saving.
            </p>

            <Form.Group as={Col} xs={12} className=" mb-3 account-buid-input">
              <Form.Label>Business Unit Id</Form.Label>
              <Form.Control
                type="text"
                name="buid"
                onChange={handleBuidChange}
                value={buidFormData.buid}
                required
                placeholder="Disabled input"
                aria-label="Disabled input example"
                disabled
                readOnly
              />
            </Form.Group>

            <Form.Group as={Col} md={6} xs={12} className="mb-3">
              <Form.Label>Short Name</Form.Label>
              <Form.Control
                type="text"
                name="shortName"
                required
                onChange={handleBuidChange}
                value={buidFormData.shortName}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} xs={12} className="mb-3">
              <Form.Label>Long Name</Form.Label>
              <Form.Control
                type="text"
                name="longName"
                required
                onChange={handleBuidChange}
                value={buidFormData.longName}
              />
            </Form.Group>

            <Form.Group as={Col} md={6} xs={12} className="mb-3">
              <Form.Label>Address 1</Form.Label>
              <Form.Control
                type="text"
                name="address1"
                required
                onChange={handleBuidChange}
                value={buidFormData.address1}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} xs={12} className="mb-3">
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                type="text"
                name="address2"
                required
                onChange={handleBuidChange}
                value={buidFormData.address2}
              />
            </Form.Group>

            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label>Copyright text</Form.Label>
              <Form.Control
                type="text"
                name="copyRightText"
                required
                onChange={handleBuidChange}
                value={buidFormData.copyRightText}
              />
            </Form.Group>

            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label>Privacy Policy link</Form.Label>
              <Form.Control
                type="text"
                name="privacyPolicy"
                required
                onChange={handleBuidChange}
                value={buidFormData.privacyPolicy}
              />
            </Form.Group>
          </Row>

          <Row className="account-branding">
            <div className="flex align justify-space-between">
              <div>
                <p className="title margin-0">Business Unit Branding</p>
                <p className="subtitle">
                  You must have at least 1 branding per business unit.
                </p>
              </div>

              <Button
                variant="primary"
                type="button"
                onClick={handleAddBranding}
                className="button4"
              >
                + Add Branding
              </Button>
            </div>

            {brandings.map((branding, index) => (
              <div className="flex justify-center account-branding-section">
                <p className="title">{index + 1}.</p>

                <Row key={index} className="margin-0 padding-0">
                  <Form.Group as={Col} xs={12} md={12} className="mb-3">
                    <Form.Label>Branding Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      required
                      onChange={(e) =>
                        handleBrandingChange(index, "name", e.target.value)
                      }
                      value={branding.name}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6} className="mb-3">
                    <Form.Label>Headings Font</Form.Label>
                    <Form.Select
                      required
                      onChange={(e) =>
                        handleBrandingChange(
                          index,
                          "displayFont",
                          e.target.value
                        )
                      }
                      value={branding.displayFont}
                      aria-label="Default select example"
                      style={{ fontFamily: branding.displayFont }}
                    >
                      <option></option>
                      <option value="Poppins">Poppins</option>
                      <option value="Open Sans">Open Sans</option>
                      <option value="Roboto">Roboto</option>
                      <option value="Arial">Arial</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6} className="mb-3">
                    <Form.Label>Body Font</Form.Label>
                    <Form.Select
                      required
                      onChange={(e) =>
                        handleBrandingChange(index, "copyFont", e.target.value)
                      }
                      value={branding.copyFont}
                      aria-label="Default select example"
                      style={{ fontFamily: branding.copyFont }}
                    >
                      <option></option>
                      <option value="Poppins">Poppins</option>
                      <option value="Open Sans">Open Sans</option>
                      <option value="Roboto">Roboto</option>
                      <option value="Arial">Arial</option>
                      <option value="Titillium">Titillium</option>
                      <option value="Lato">Lato</option>
                      <option value="Paytone One">Paytone One</option>
                      <option value="Inter">Inter</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6} className="mb-3">
                    <Form.Label>Button style</Form.Label>
                    <Form.Select
                      required
                      onChange={(e) =>
                        handleBrandingChange(
                          index,
                          "buttonStyle",
                          e.target.value
                        )
                      }
                      value={branding.buttonStyle}
                      aria-label="Default select example"
                    >
                      <option></option>
                      <option value="Square">Square</option>
                      <option value="Rounded">Rounded</option>
                      <option value="Slightly Rounded">Slightly rounded</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6} className="mb-3">
                    <Form.Label>
                      Preference link
                      <span className="account-branding-optional">
                        (optional)
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="preferenceLink"
                      onChange={(e) =>
                        handleBrandingChange(
                          index,
                          "preferenceLink",
                          e.target.value
                        )
                      }
                      value={branding.preferenceLink}
                    />
                  </Form.Group>

                  <Form.Group as={Col} xs={6} md={3} className="mb-3">
                    <Form.Label htmlFor="exampleColorInput">
                      Highlight Color
                    </Form.Label>
                    <Form.Control
                      required
                      type="color"
                      id="exampleColorInput"
                      title="Choose your color"
                      onChange={(e) =>
                        handleBrandingChange(
                          index,
                          "highlightColor",
                          e.target.value
                        )
                      }
                      value={branding.highlightColor}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={6} md={3} className="mb-3">
                    <Form.Label htmlFor="exampleColorInput">
                      Light Color
                    </Form.Label>
                    <Form.Control
                      required
                      type="color"
                      id="exampleColorInput"
                      title="Choose your color"
                      onChange={(e) =>
                        handleBrandingChange(
                          index,
                          "lightColor",
                          e.target.value
                        )
                      }
                      value={branding.lightColor}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={6} md={3} className="mb-3">
                    <Form.Label htmlFor="exampleColorInput">
                      Mid Color
                    </Form.Label>
                    <Form.Control
                      required
                      type="color"
                      id="exampleColorInput"
                      title="Choose your color"
                      onChange={(e) =>
                        handleBrandingChange(index, "midColor", e.target.value)
                      }
                      value={branding.midColor}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={6} md={3} className="mb-3">
                    <Form.Label htmlFor="exampleColorInput">
                      Dark Color
                    </Form.Label>
                    <Form.Control
                      required
                      type="color"
                      id="exampleColorInput"
                      title="Choose your color"
                      onChange={(e) =>
                        handleBrandingChange(index, "darkColor", e.target.value)
                      }
                      value={branding.darkColor}
                    />
                  </Form.Group>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Logo</Form.Label>
                    <Form.Control
                      required
                      type="file"
                      name="logo"
                      onChange={(e) =>
                        handleBrandingChange(index, "logo", e.target.files[0])
                      }
                    />
                  </Form.Group>
                  {previewImages[index] && (
                    <Col xs="12">
                      <img
                        src={previewImages[index]}
                        className="account-branding-logo"
                        alt="logo"
                      />
                    </Col>
                  )}
                  <Form.Group as={Col} xs={12} md={6} className="mb-3">
                    <Form.Label className="flex align">
                      <FaFacebook
                        color="#316FF6"
                        size="25px"
                        className="account-branding-social-icons"
                      />
                      Facebook
                      <span className="account-branding-optional">
                        (optional)
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="facebook"
                      onChange={(e) =>
                        handleBrandingChange(index, "facebook", e.target.value)
                      }
                      value={branding.facebook}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6} className="mb-3">
                    <Form.Label className="flex align">
                      <RxInstagramLogo
                        color="#E1306C"
                        size="25px"
                        className="account-branding-social-icons"
                      />
                      Instagram
                      <span className="account-branding-optional">
                        (optional)
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="instagram"
                      onChange={(e) =>
                        handleBrandingChange(index, "instagram", e.target.value)
                      }
                      value={branding.instagram}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6} className="mb-3">
                    <Form.Label className="flex align">
                      <SiLinkedin
                        color="#0077b5"
                        size="25px"
                        className="account-branding-social-icons"
                      />
                      LinkedIn
                      <span className="account-branding-optional">
                        (optional)
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="linkedIn"
                      onChange={(e) =>
                        handleBrandingChange(index, "linkedIn", e.target.value)
                      }
                      value={branding.linkedIn}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6} className="mb-3">
                    <Form.Label className="flex align">
                      <FaTwitter
                        color="#1DA1F2"
                        size="25px"
                        className="account-branding-social-icons"
                      />
                      X/Twitter
                      <span className="account-branding-optional">
                        (optional)
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="twitter"
                      onChange={(e) =>
                        handleBrandingChange(index, "twitter", e.target.value)
                      }
                      value={branding.twitter}
                    />
                  </Form.Group>
                  <Col>
                    {brandings.length > 1 && (
                      <Button
                        variant="danger"
                        type="button"
                        onClick={() => handleRemoveBranding(index)}
                      >
                        Remove
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            ))}
          </Row>
          <div className="account-buid-error">
            {error && <ErrorComponent errorMessage={errorMessage} />}
          </div>
          {loading ? (
            <img src={loadingGif} width="50px" alt="loading" />
          ) : (
            <>
              <Button
                variant="primary"
                type="submit"
                className="account-form-save"
              >
                Save Business Unit
              </Button>
              <Button
                variant="warning"
                type="button"
                className="account-form-cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </>
          )}
        </Form>
      )}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
        className="account-buid-success-modal"
      >
        <Modal.Header closeButton>
          <img src={check} width="100px" alt="check" />
          <Modal.Title>
            Success! A new Business unit was added to your account
          </Modal.Title>
        </Modal.Header>
      </Modal>
    </>
  );
};

export default BuidForm;
