import React from "react";
import "./banner.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import apple from "../../../assets/logo/apple.png";
import google from "../../../assets/logo/google.png";
import { AiOutlineCheckCircle } from "react-icons/ai";
const Banner = () => {
  return (
    <>
      <Row className="div-width flex justify-center align">
        <Col
          lg="10"
          md="11"
          sm="11"
          xs="11"
          className="offwhite-bg flex pps-banner-col"
        >
          <Row className="margin-0 padding-0 pps-banner-row">
            <Col lg="6" md="6" sm="6" xs="12">
              <div>
                <h2>Download the Pardot Prospect Scanner app</h2>
                {/* <h3>Available on Apple Store & Google Play</h3> */}
                <h3>Available on Google Play</h3>
                <a
                  href="https://apps.apple.com/gb/app/pardot-prospect-scanner/id1663176407"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <img src={apple} /> */}
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.marcloudtechnologies.pardotprospectscanner&pli=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={google} />
                </a>
              </div>
            </Col>
            <Col lg="1" md="1" className="pps-banner-margin-col"></Col>
            <Col lg="5" md="5" sm="5" xs="12" >
              <ul className="padding-0 margin-0">
                <li>
                  <AiOutlineCheckCircle size="30px" color="orange" /> Speed up
                  your data capture{" "}
                </li>
                <li>
                  <AiOutlineCheckCircle size="30px" color="orange" /> Align marketing
                  and sales teams
                </li>
                <li>
                  <AiOutlineCheckCircle size="30px" color="orange" /> Have excellent data hygiene
                </li>
                <li>
                  <AiOutlineCheckCircle size="30px" color="orange" />{" "}
                  Drive data-driven nurture journeys
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Banner;
