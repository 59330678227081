import * as React from "react";
import "./emailTemplateReceipt.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../assets/logo/colored-logo.png";
import { useLocation } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { AES, enc } from "crypto-js";
import { useMutation } from "@apollo/client";
import { NEW_PURCHASE } from "../../utils/mutations";
import { Link } from "react-router-dom";
import Auth from "../../utils/auth";
import { BiErrorCircle } from "react-icons/bi";

const EmailTemplateReceipt = ({ changeBg }) => {
  const actionUrl = process.env.REACT_APP_PURCHASE_CONFIRMATION;
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
  const location = useLocation();
  const color = "transparent";
  const [transaction, setTransation] = React.useState();
  const [transactionProducts, setTransationProducts] = React.useState();
  const [src, setSrc] = React.useState();
  const [error, setError] = React.useState(false);

  const [newPurchase, { error: newPurchaseError, data: newPurchaseData }] =
    useMutation(NEW_PURCHASE);

  React.useEffect(() => {
    setError(false);
    changeBg(color);
    localStorage.removeItem("cartArray");
    const isLoggedIn = Auth.loggedIn();
    if (!isLoggedIn) {
      const prevPage = encodeURIComponent(window.location.href);
      window.location.assign(`/login?prev=${prevPage}`);
    }
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get("session_id");
    const src = searchParams.get("src");
    setSrc(src);

    const fetchData = async () => {
      try {
        const response = await fetch("/transaction", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            checkoutSessionId: sessionId,
          }),
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const jsonR = await response.json();
        setTransation(jsonR.transaction);
        setTransationProducts(jsonR.transactionProducts);

        const products = [];
        jsonR.transactionProducts.map((product) => {
          products.push({
            name: product.name,
            category: product.category,
            internalReference: product.sku,
            price: product.total,
            stripeId: product.productId,
          });
        });
        await newPurchase({
          variables: {
            purchaseInput: {
              stripeReference: jsonR.transaction.payment_intent,
              total: jsonR.transaction.total,
              products: products,
              currency: jsonR.transaction.currency,
            },
          },
        });

        if (src === "web") {
          const receipt_data = {
            email: jsonR.transaction.customerDetails.email,
            encryptedConfirmationString: sessionId,
          };
          const isFirstPageLoadItem = localStorage.getItem("isFirstPageLoad");
          if (
            !isFirstPageLoadItem ||
            JSON.parse(
              AES.decrypt(isFirstPageLoadItem, SECRET_KEY).toString(enc.Utf8)
            ).transaction_id !== jsonR.transaction.id
          ) {
            const config = {
              method: "post",
              maxBodyLength: Infinity,
              url: actionUrl,
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              data: qs.stringify(receipt_data),
            };
            const piwikProducts = jsonR.transactionProducts.map((product) => {
              return {
                item_id: product.sku,
                item_name: product.name,
                item_category: product.category,
                item_brand: product.brand,
                price: product.total,
              };
            });
            window.dataLayer.push({
              revenue: jsonR.transaction.total,
            });
            window.dataLayer.push({
              event: "order",
              items: piwikProducts,
              order_data: {
                transaction_id: jsonR.transaction.payment_intent,
                value: jsonR.transaction.total,
                subtotal: jsonR.transaction.subTotal,
                tax: jsonR.transaction.tax,
                discount: jsonR.transaction.discount,
              },
            });

            localStorage.setItem(
              "isFirstPageLoad",
              AES.encrypt(
                JSON.stringify({
                  transaction_id: jsonR.transaction.id,
                  isPushed: true,
                }),
                SECRET_KEY
              ).toString()
            );

            const response = await axios.request(config);
          }
        }
      } catch (error) {
        console.log(error);
        setError(true);
      }
    };
    if (sessionId) {
      fetchData();
    }
  }, [location.search]);

  const findMatchedProduct = (product) => {
    const matchedPurchase = newPurchaseData.newPurchase.purchases.find(
      (purchase) => purchase.stripeReference === transaction.payment_intent
    );
    if (matchedPurchase) {
      const matchedProduct = matchedPurchase.products.find(
        (p) => p.name === product.name
      );
      return matchedProduct;
    }
    return null;
  };

  return (
    <div className="stars-bg">
      {!transaction && error && (
        <Row className="div-width flex justify-center text-align-center align et et-receipt-header">
          <Col lg="7" md="10" xs="11" className="flex justify-center align">
            <div>
              <h2 className="flex align justify-center">
                {" "}
                <BiErrorCircle
                  size="40px"
                  color="orange"
                  className="error-icon"
                />
                Error loading receipt
              </h2>
              <div className="blue-line"></div>

              <p className="et-receipt-subtitle">
                Please refresh the page, if the problem persists contact our
                support team.
              </p>
              <Link to="/ContactUs">
                {" "}
                <button className="button4">Contact Us</button>
              </Link>
            </div>
          </Col>
        </Row>
      )}
      {transaction && (
        <>
          <Row className="div-width flex justify-center text-align-center align et et-receipt-header">
            <Col lg="7" md="10" xs="11" className="flex justify-center align">
              <div>
                <h2>
                  Thank you for your purchase,{" "}
                  {transaction.customerDetails.name}!
                </h2>
                <div className="blue-line"></div>
                {src === "web" ? (
                  <>
                    <p className="et-receipt-subtitle">
                      We have sent your receipt to,{" "}
                      <span className="bold">
                        {transaction.customerDetails.email}
                      </span>
                      .
                    </p>
                  </>
                ) : (
                  <></>
                )}
                <p className="et-receipt-subtitle">
                  You can find all the details about your transaction and use
                  your purchased products below.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="div-width flex justify-center text-align-center align">
            <Col lg="7" md="10" xs="11" className="et-receipt">
              <Row>
                <div className="flex align justify-space-between et-receipt-header-div">
                  <div className="text-align-left">
                    <h3>Your Transaction </h3>
                    <h4>{transaction.date}</h4>
                  </div>
                  <div className="flex align justify-flex-end">
                    <img src={logo} alt="Logo" />
                  </div>
                </div>
              </Row>
              {transactionProducts.length > 0 ? (
                <>
                  <Row className="et-receipt-items-title">
                    <Col xs="5" className="flex align">
                      Product
                    </Col>
                    <Col xs="2" className="flex align justify-center">
                      Price
                    </Col>
                  </Row>
                  {transactionProducts.map((product) => {
                    return (
                      <Row className="et-receipt-item">
                        <Col xs="5" className="flex align">
                          {product.name}
                        </Col>
                        <Col
                          xs="2"
                          className="flex align justify-center text-align-center"
                        >
                          {product.subTotal}{" "}
                          {transaction.currency.toUpperCase()}
                        </Col>
                        <Col xs="5" className="flex align justify-end">
                          {newPurchaseData && (
                            <Link
                              to={`/UseTemplate/${encodeURIComponent(
                                AES.encrypt(
                                  JSON.stringify(findMatchedProduct(product)),
                                  process.env.REACT_APP_SECRET_KEY
                                ).toString()
                              )}`}
                            >
                              <button
                                className="button4"
                                disabled={!findMatchedProduct(product)}
                              >
                                Use Template
                              </button>
                            </Link>
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                  <div className="et-receipt-items-total">
                    {transaction.discount > 0 && (
                      <>
                        <Row className="flex text-align-left">
                          <Col xs="5">
                            <div className="flex align justify-space-between et-receipt-summary-title">
                              <p>Subtotal</p>
                            </div>
                          </Col>
                          <Col xs="2" className="flex align justify-center">
                            <p className="et-receipt-summary-value  text-align-center">
                              {transaction.subTotal}{" "}
                              {transaction.currency.toUpperCase()}
                            </p>
                          </Col>
                          <Col xs="5"></Col>
                        </Row>
                        <Row className="flex text-align-left">
                          <Col xs="5">
                            <div className="flex align justify-space-between et-receipt-summary-title">
                              <p>Discount</p>
                            </div>
                          </Col>
                          <Col xs="2" className="flex align justify-center">
                            <p className="et-receipt-summary-value  text-align-center">
                              - {transaction.discount}{" "}
                              {transaction.currency.toUpperCase()}
                            </p>
                          </Col>
                          <Col xs="5"></Col>
                        </Row>
                      </>
                    )}

                    <Row className="flex text-align-left">
                      <Col xs="5">
                        <div className="flex align justify-space-between et-receipt-summary-title">
                          <p>Tax</p>
                        </div>
                      </Col>
                      <Col xs="2" className="flex align justify-center">
                        <p className="et-receipt-summary-value  text-align-center">
                          {transaction.tax} {transaction.currency.toUpperCase()}
                        </p>
                      </Col>
                      <Col xs="5"></Col>
                    </Row>
                    <Row className="flex text-align-left">
                      <Col xs="5">
                        <div className="flex align justify-space-between et-receipt-summary-title">
                          <p>Total</p>
                        </div>
                      </Col>
                      <Col xs="2" className="flex align justify-center">
                        <p className="et-receipt-summary-value  text-align-center">
                          {transaction.total}{" "}
                          {transaction.currency.toUpperCase()}
                        </p>
                      </Col>
                      <Col xs="5"></Col>
                    </Row>
                  </div>
                </>
              ) : (
                <Row>
                  <p>No products</p>
                </Row>
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
export default EmailTemplateReceipt;
