import * as React from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { CgClose } from "react-icons/cg";
import { FaUser } from "react-icons/fa";
import planet1 from "../../assets/planets/planet1.png";
import planet2 from "../../assets/planets/planet2.png";
import logo from "../../assets/logo/white-logo.png";
import Auth from "../../utils/auth";
import NavDropdown from "react-bootstrap/NavDropdown";
import { AiOutlineShoppingCart } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MdDeleteOutline } from "react-icons/md";
import { useCart } from "../../components/cartContext/cartContext";

const NavBar = () => {
  const { cartArray, total, handleDelete } = useCart();
  const currency = cartArray?.[0]?.currency.toUpperCase() || "";
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getPrevPage = () => {
    const prevPage = window.location.pathname;
    if (
      prevPage.includes("/SignUp") ||
      prevPage.includes("/Login") ||
      prevPage.includes("/ResetPassword") ||
      prevPage.includes("/ForgotPassword")
    ) {
      return "";
    } else {
      return `?prev=${encodeURIComponent(window.location.href)}`;
    }
  };

  React.useEffect(() => {
    getPrevPage();
    const body = document.querySelector("body");
    if (toggleMenu) {
      body.classList.add("no-scroll");
    } else {
      body.classList.remove("no-scroll");
    }
    return () => {
      body.classList.remove("no-scroll");
    };
  }, [toggleMenu]);
  const logout = (event) => {
    event.preventDefault();
    Auth.logout();
  };
  const isLoggedIn = Auth.loggedIn();
  // const [show, setShow] = React.useState(false);
  // const showDropdown = (e) => {
  //   setShow(!show);
  // };
  // const hideDropdown = (e) => {
  //   setShow(false);
  // };

  const onCheckout = async () => {
    const cart = cartArray.map((item) => {
      return { price: item.priceId, quantity: 1 };
    });
    try {
      if (!isLoggedIn) {
        window.location.assign(`/login${getPrevPage()}`);
      }
      const session = await fetch(`/create-checkout-session`, {
        method: "POST",
        body: JSON.stringify({ cart: cart }),
        headers: { "Content-Type": "application/json" },
      });
      const sessionJSON = await session.json();
      window.location.href = sessionJSON.url;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <nav className="flex justify-space-between div-width custom-navbar">
        <div className="flex align custom-navbar-logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>

        <ul className="flex align margin-0 padding-0 custom-navbar-links">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/AboutUs">About</Link>
          </li>
          <li>
            <Link to="/Solutions">Solutions</Link>
          </li>
          <li>
            <a
              href="https://marcloudconsulting.com/articles"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blog
            </a>
          </li>
          <li>
            <Link to="/ContactUs">Contact</Link>
          </li>
          <li>
            <Link to="/EmailTemplates">Buy Templates</Link>
          </li>

          {/* <li>
          <Link to="/ContactUs">
            <button className="button2">Contact</button>
          </Link>
        </li> */}
          <li>
            <button onClick={handleShow} className="custom-navbar-cart">
              <AiOutlineShoppingCart className="custom-navbar-cart-icon" />
              <sup className="et-cart-nb">{cartArray.length}</sup>
            </button>
          </li>
          {isLoggedIn ? (
            <div className="custom-navbar-dropdown">
              <NavDropdown
                title={
                  <>
                    <FaUser size="20px" />
                  </>
                }
                id="basic-nav-dropdown"
                // show={show}
                // onMouseEnter={showDropdown}
                // onMouseLeave={hideDropdown}
              >
                <NavDropdown.Item
                  as={Link}
                  to="/Account"
                  className="custom-navbar-dropdown-link"
                >
                  Account
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Purchases"
                  className="custom-navbar-dropdown-link"
                >
                  Purchases
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Subscriptions"
                  className="custom-navbar-dropdown-link"
                >
                  Subscriptions
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Receipts"
                  className="custom-navbar-dropdown-link"
                >
                  Receipts
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
              </NavDropdown>
            </div>
          ) : (
            <li>
              <Link to={`/login${getPrevPage()}`}>
                <button className="button2">Login</button>
              </Link>
            </li>
          )}
        </ul>
        <div>
          <div className="custom-navbar-smallscreen">
            <button onClick={handleShow} className="custom-navbar-cart">
              <AiOutlineShoppingCart className="custom-navbar-cart-icon" />
              <sup className="et-cart-nb">{cartArray.length}</sup>
            </button>
            <AiOutlineMenu
              className="custom-menu"
              color="#fff"
              fontSize={20}
              onClick={() => setToggleMenu(true)}
            />
            {toggleMenu && (
              <div className="custom-navbar-smallscreen-overlay">
                <div className="custom-navbar-smallscreen-ul">
                  <CgClose
                    className="custom-navbar-smallscreen-overlay-close"
                    onClick={() => setToggleMenu(false)}
                    font-size={20}
                  />
                  <ul className="flex margin-0 padding-0 text-align-center">
                    <li onClick={() => setToggleMenu(false)}>
                      <Link to="/">Home</Link>
                    </li>
                    <li onClick={() => setToggleMenu(false)}>
                      <Link to="/AboutUs">About</Link>
                    </li>
                    <li onClick={() => setToggleMenu(false)}>
                      <Link to="/Solutions">Solutions</Link>
                    </li>
                    <li onClick={() => setToggleMenu(false)}>
                      <a
                        href="https://marcloudconsulting.com/articles"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Blog
                      </a>
                    </li>
                    <li onClick={() => setToggleMenu(false)}>
                      <Link to="/ContactUs">Contact</Link>
                    </li>
                    <li onClick={() => setToggleMenu(false)}>
                      <Link to="/EmailTemplates">Buy Templates</Link>
                    </li>
                    {/* <li onClick={() => setToggleMenu(false)}>
                    <Link to="/ContactUs">
                      <button className="button2">Contact</button>
                    </Link>
                  </li> */}
                    {isLoggedIn ? (
                      <>
                        <li onClick={() => setToggleMenu(false)}>
                          <Link to="/Account">Account</Link>
                        </li>
                        <li onClick={() => setToggleMenu(false)}>
                          <Link to="/Purchases">Purchases</Link>
                        </li>
                        <li onClick={() => setToggleMenu(false)}>
                          <Link to="/Subscriptions">Subscriptions</Link>
                        </li>
                        <li onClick={() => setToggleMenu(false)}>
                          <Link to="/Receipts">Receipts</Link>
                        </li>
                        <li onClick={() => setToggleMenu(false)}>
                          <button className="button2" onClick={logout}>
                            Logout
                          </button>
                        </li>
                      </>
                    ) : (
                      <li onClick={() => setToggleMenu(false)}>
                        <Link to={`/login${getPrevPage()}`}>
                          <button className="button2">Login</button>
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>

                <div className="custom-navbar-planets">
                  <img
                    src={planet1}
                    className="custom-navbar-planet1"
                    alt="planet"
                  />
                  <img
                    src={planet2}
                    className="custom-navbar-planet2"
                    alt="planet"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>

      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton className="flex align">
          <Modal.Title className="custom-navbar-cart-modal-title">
            <AiOutlineShoppingCart size="40px" /> Your Cart
          </Modal.Title>
        </Modal.Header>
        {cartArray.length > 0 ? (
          <>
            <Modal.Body>
              {cartArray.map((product, index) => {
                return (
                  <Row className="custom-navbar-cart-modal-body" key={index}>
                    <Col xs="6" className="flex align">
                      <div>{product.name}</div>
                    </Col>
                    <Col xs="4" className="flex align">
                      <div>
                        {product.cost} {currency}
                      </div>
                    </Col>
                    <Col xs="2" className="flex align justify-center">
                      <button onClick={() => handleDelete(index, product)}>
                        <MdDeleteOutline size="25px" />
                      </button>
                    </Col>
                  </Row>
                );
              })}
              <Row className="custom-navbar-disclaimer">
                <p>
                  * You can use your purchased email templates upon payment
                  completion.
                </p>
              </Row>
              <Row className="custom-navbar-cart-modal-footer">
                <Col xs="6">
                  <div>Total</div>
                </Col>
                <Col xs="4">
                  <div>
                    {total} {currency}
                  </div>
                </Col>
                <Col xs="2"></Col>
              </Row>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  className="custom-navbar-view-btn"
                >
                  Close
                </Button>
                <Button
                  variant="primary"
                  onClick={(handleClose, onCheckout)}
                  className="button4"
                >
                  Checkout
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </>
        ) : (
          <>
            <Modal.Body>
              <Row>
                <p className="custom-navbar-cart-empty">Your cart is empty</p>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleClose}
                className="custom-navbar-view-btn"
              >
                Close
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default NavBar;
