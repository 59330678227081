import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EditBranding from "../../components/branding/editBranding";
import { FaCircle } from "react-icons/fa";

const ChooseBranding = ({
  chosenBuid,
  chosenBranding,
  brandingClick,
  onUpdate,
}) => {
  return (
    <div className="preview-div text-align-left">
      <h3 className="flex align">Choose a Branding</h3>
      <Row className="padding-0 margin-0">
        {chosenBuid.brandings.map((branding, index) => (
          <Col
            key={index}
            lg={4}
            md={4}
            sm={6}
            xs={12}
            className="flex justify-center align text-align-center preview-left-col"
          >
            <div
              onClick={() => brandingClick(branding)}
              className={`preview-card ${
                chosenBranding === branding && "preview-card-active"
              }`}
            >
              <div>
                <p className="bold title">{branding.name}</p>
                <div className="margin-0 padding-0 flex justify-center">
                  <FaCircle color={branding.highlightColor} />
                  <FaCircle color={branding.lightColor} />
                  <FaCircle color={branding.midColor} />
                  <FaCircle color={branding.darkColor} />
                </div>
              </div>
              <div className="flex justify-end">
                <EditBranding
                  branding={branding}
                  buid={chosenBuid.buid}
                  buidId={chosenBuid._id}
                  onUpdate={onUpdate}
                />
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ChooseBranding;
