import * as React from "react";
import "./heroComponent.css";
const HeroComponent = (props) => {
  return (
    <div className="hc-div section-top-padding div-width">
      <h1>{props.title}</h1>
      <h3>{props.subtitle}</h3>
      <div className="flex justify-center">
        <img src={props.img} className={props.imgStyle} alt="hero"/>
      </div>
    </div>
  );
};
export default HeroComponent;
