import * as React from "react";
import "./confirmation.css";
import qs from "qs";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Auth from "../../utils/auth.js";
// import { Link } from "react-router-dom";
import { AES, enc } from "crypto-js";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CONFIRMATION_LOGIN_USER } from "../../utils/mutations.js";
import { CONFIRM_USER } from "../../utils/mutations.js";
import { BiErrorCircle } from "react-icons/bi";
import loadingGif from "../../assets/loading1.gif";
import check from "../../assets/check.gif";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
import { InputGroup } from "react-bootstrap";

const Confirmation = ({ changeBg }) => {
  const color = "transparent";
  React.useEffect(() => {
    changeBg(color);
  }, []);
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
  const [login, { error, data }] = useMutation(CONFIRMATION_LOGIN_USER);
  const [confirmUser] = useMutation(CONFIRM_USER);
  const [formData, setFormData] = React.useState({ email: "", password: "" });
  const [rememberMe, setRememberMe] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  // const [valid, setValid] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const { user } = useParams();
  const decrypted_signup_data = AES.decrypt(
    decodeURIComponent(user),
    SECRET_KEY
  ).toString(enc.Utf8);
  const params = new URLSearchParams(decrypted_signup_data);
  const date = params.get("date");
  const email = params.get("email");
  // React.useEffect(() => {
  //   if (new Date().getTime() - date > 48 * 60 * 60 * 1000) {
  //     setValid(false);
  //   }
  // }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleFormSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (rememberMe) {
      const login_data = qs.stringify({
        email: email,
        password: formData.password,
      });
      const encrypted_login_data = AES.encrypt(
        login_data,
        SECRET_KEY
      ).toString();
      localStorage.setItem("token", encrypted_login_data);
    } else {
      localStorage.removeItem("token");
    }
    try {
      const { data } = await login({
        variables: { email: email, password: formData.password },
      });
      await confirmUser({
        variables: { email: email },
      });
      setTimeout(() => {
        Auth.login(data.confirmationLogin.token, "/Account");
      }, 4000);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  // valid ?
  return (
    <div className="stars-bg">
      <Row className="div-width text-align-center flex justify-center confirmation">
        <Col lg="5" md="8" sm="10" xs="11">
          <h2>Confirmation</h2>
          <div className="blue-line"></div>
          {data ? (
            <>
              <p className="confirmation-subtitle">User Confirmed</p>
              <div className="flex align justify-center">
                <img
                  src={check}
                  width="150px"
                  className="confirmation-check-img"
                />
              </div>
            </>
          ) : (
            <>
              <p className="confirmation-subtitle">
                Please enter your password below to confirm your account
              </p>
              <Form onSubmit={handleFormSubmit}>
                {error && (
                  <div className="flex align justify-center confirmation-error">
                    <BiErrorCircle
                      size="40px"
                      color="orange"
                      className="confirmation-error-icon"
                    />
                    {error.message}
                  </div>
                )}
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="email"
                    className="confirmation-form-label confirmation-form-label-email "
                    required
                    value={email}
                    readOnly
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      className="confirmation-form-label"
                      required
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      className="flex align signup-show-password"
                    >
                      {showPassword ? (
                        <IoMdEyeOff color="black" size="25px" />
                      ) : (
                        <IoMdEye color="black" size="25px" />
                      )}
                    </div>
                  </InputGroup>
                </Form.Group>

                <div className="flex align confirmation-bottom">
                  <Form.Group
                    controlId="rememberMe-checkbox"
                    className="mb-3 confirmation-checkbox"
                  >
                    <Form.Check
                      name="checkbox-option"
                      type="checkbox"
                      label="Remember Me"
                      value="Remember Me"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                  </Form.Group>
                </div>
                {loading ? (
                  <>
                    <img src={loadingGif} width="100px" />
                  </>
                ) : (
                  <>
                    <Button
                      variant="primary"
                      type="submit"
                      className="button4 confirmation-button"
                    >
                      Confirm Account
                    </Button>
                  </>
                )}
              </Form>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
  // : (
  //   <div className="stars-bg">
  //     <Row className="div-width text-align-center flex justify-center confirmation">
  //       <Col lg="5" md="8" sm="10" xs="11">
  //         <h2>Confirmation</h2>
  //         <div className="blue-line"></div>
  //         <p className="confirmation-subtitle">
  //           <BiErrorCircle size="30px" color="orange" /> This confirmation link
  //           is no longer valid. Please sign up again.{" "}
  //         </p>
  //         <Link to="/SignUp">
  //           <button className="button4">Sign up</button>
  //         </Link>
  //       </Col>
  //     </Row>
  //   </div>
  // );
};

export default Confirmation;
