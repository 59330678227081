import * as React from "react";
import Hero from "../../components/solution/hero";
import SolutionContact from "../../components/contact/solutionContact";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation";
import Curve from "../../components/curve/curveColor";
import FlippedCurveColor from "../../components/curve/flippedCurveColor";
import hero from "../../assets/img/img19.png";
import Section1 from "../../container/customPreferenceCenter/section1/section1";
import Section2 from "../../container/customPreferenceCenter/section2/section2";
import { Link } from "react-router-dom";
import "./customPreferenceCenter.css";

const CustomPreferenceCenter = ({ changeBg }) => {
  const color = "#e4ebf5";
  React.useEffect(() => {
    changeBg(color);
  }, []);
  function scrollToSection() {
    const section2 = document.getElementById("section2");
    section2.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <>
      <div className="overflow-x">
        <Hero
          title="Custom Preference Center"
          subtitle="Salesforce Marketing Cloud Engagement"
          content="Quick-build SFMCE Preference Center that can be fully branded and is easy to maintain and edit          "
          heroImg={hero}
          imgClass="cpc-heroImg"
          actionButtons={
            <>
              <button
                className="button4 solution-hc-left-button1"
                onClick={scrollToSection}
              >
                Read more
              </button>
              <Link to="/ContactUs">
                <button className="button4">Enquire now</button>
              </Link>
            </>
          }
        />
      </div>
      <div className="offwhite-bg">
        <div className="div-width">
          <Section2 />
        </div>
      </div>
      <FlippedCurveColor color="#f7f7f7" bgColor="#e4ebf5" />
      <div className="lightblue-bg" id="section2">
        <div className="div-width">
          <Section1 />
        </div>
      </div>
      <FlippedCurveColor color="#e4ebf5" />
      <SolutionContact solution="Custom Preference Center" />
      <Curve color="#e4ebf5" />
      <div className="lightblue-bg">
        <div className="div-width">
          <BottomNavigation />
        </div>
      </div>
    </>
  );
};

export default CustomPreferenceCenter;
