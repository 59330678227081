// CartContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartArray, setCartArray] = useState([]);
  const [total, setTotal] = useState(0);
  const [inCartMap, setInCartMap] = useState({});

  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cartArray") || "[]");
    setCartArray(savedCart);
  }, []);

  useEffect(() => {
    localStorage.setItem("cartArray", JSON.stringify(cartArray));
    const newTotal = cartArray.reduce(
      (acc, product) => acc + product.cost,
      0
    );
    setTotal(newTotal);
  }, [cartArray]);

  useEffect(() => {
    const newInCartMap = {};
    cartArray.forEach((item) => {
      newInCartMap[item.priceId] = true;
    });
    setInCartMap(newInCartMap);
  }, [cartArray]);

  const addToCart = (product) => {
    let newTotal = total;
    const updatedCartArray = [...cartArray];
    if (
      updatedCartArray.filter((item) => item.priceId === product.priceId)
        .length > 0
    ) {
      return;
    }
    updatedCartArray.push(product);
    setCartArray(updatedCartArray);
    newTotal += product.cost;
    setTotal(newTotal);
  };

  const removeFromCart = (product) => {
    const itemIndex = cartArray.findIndex((item) => item.name === product.name);
    if (itemIndex !== -1) {
      const updatedCartArray = [...cartArray];
      const deletedItem = updatedCartArray.splice(itemIndex, 1)[0];
      setTotal((prevTotal) => prevTotal - deletedItem.cost);
      setCartArray(updatedCartArray);
    }
  };

  const handleDelete = (index, product) => {
    const updatedCartArray = [...cartArray];
    const deletedItem = updatedCartArray.splice(index, 1)[0];
    setTotal((prevTotal) => prevTotal - deletedItem.cost);
    setCartArray(updatedCartArray);
    console.log(product)
    window.dataLayer.push({
      event: "remove_from_cart",
      items: [
        {
          item_id: product.sku,
          item_name: product.name,
          item_category: product.category,
          item_brand: product.brand,
          price: product.cost,
        },
      ],
    });
  };

  return (
    <CartContext.Provider
      value={{ cartArray, total, inCartMap, addToCart, removeFromCart, handleDelete }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
