import * as React from "react";
import "./contact.css";
import Form from "react-bootstrap/Form";
import qs from "qs";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ImLinkedin2 } from "react-icons/im";
import { HiOutlineMail } from "react-icons/hi";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsFillPersonFill } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import colored_logo from "../../assets/logo/colored-logo.png";
import axios from "axios";
import check from "../../assets/check.gif";
const Contact = (props) => {
  const actionUrl = "https://pardot.marcloud.com/l/914051/2023-10-11/rzlsq";
  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    message: "",
  });
  const [formCheckbox, setFormCheckbox] = React.useState(false);
  const [formSubmitted, setIsFormSubmitted] = React.useState(false);
  const [savedFirstName, setSavedFirstName] = React.useState("");
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleFirstNameChange = (e) => {
    setSavedFirstName(e.target.value);
  };
  const handleCheckBoxChange = (e) => {
    if (formCheckbox) {
      setFormCheckbox(false);
    } else {
      setFormCheckbox(true);
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    try {
      const {
        firstName,
        lastName,
        email,
        company,
        message,
        formBasicCheckbox,
      } = event.target;
      const data = qs.stringify({
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        company: company.value,
        message: message.value,
        formBasicCheckbox: formBasicCheckbox.checked,
      });
      setFormData(data);
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: actionUrl,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      setIsFormSubmitted(true);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        message: "",
      });
      setFormCheckbox(false);
      await axios.request(config);
    } catch (error) {
      console.log(error.response);
      return;
    }
  };
  if (formSubmitted) {
    setTimeout(() => {
      setIsFormSubmitted(false);
    }, 10000);
  }

  return (
    <div className="stars-bg section-padding">
      <div className="div-width">
        <h3 className="text-align-center margin-0 padding-0">
          Ready to liftoff?
        </h3>
        <h2 className="text-align-center margin-0 padding-0">
          Send us a message
        </h2>
        <div className="blue-line"></div>
        {props.img && (
          <div className="flex justify-center">
            <img src={props.img} className="contact-img" alt="contact" />
          </div>
        )}

        <div className="container padding-0 contact-form-container">
          <Row className="margin-0 padding-0 ">
            <Col
              xs="12"
              className={
                formSubmitted
                  ? "margin-0 padding-0 contact-form-div-1 display-block"
                  : "margin-0 padding-0 contact-form-div-1 display-none"
              }
            >
              <div className="contact-thanks-div ">
                <div className="flex align justify-center contact-thanks-title-div">
                  <div className="flex align">
                    <img src={check} width="100px" alt="contact" />
                  </div>
                  <p className="margin-0 contact-thanks-title">
                    Thanks for getting in touch {savedFirstName}!
                  </p>
                </div>
                <p className="contact-thanks-text">
                  Your message is important to us so you'll hear from one of our
                  team very shortly.
                </p>
              </div>
            </Col>
            <Col
              className={
                formSubmitted
                  ? "margin-0 padding-0 contact-form-div-1 display-none"
                  : "margin-0 padding-0 contact-form-div-1 display-block"
              }
              lg="8"
              md="12"
              sm="12"
            >
              <div className="p-3 contact-form">
                <Form onSubmit={submitForm}>
                  <img
                    src={colored_logo}
                    width="200px"
                    className="contact-form-logo"
                    alt="contact"
                  />
                  <div
                    className={formSubmitted ? "display-none" : "display-block"}
                  >
                    <Form.Group className="mb-3" controlId="formBasicFirstName">
                      <Form.Control
                        placeholder="First Name"
                        name="firstName"
                        className="contact-form-label"
                        required
                        value={formData.firstName}
                        onChange={(e) => {
                          handleChange(e);
                          handleFirstNameChange(e);
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicLastName">
                      <Form.Control
                        placeholder="Last Name"
                        name="lastName"
                        className="contact-form-label"
                        required
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        className="contact-form-label"
                        required
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCompany">
                      <Form.Control
                        placeholder="Company Name"
                        name="company"
                        className="contact-form-label"
                        required
                        value={formData.company}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicMessage">
                      <Form.Control
                        placeholder="Your Message"
                        name="message"
                        className="contact-form-label contact-message"
                        required
                        value={formData.message}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        className="contact-form-checkbox"
                        name="formBasicCheckbox"
                        type="checkbox"
                        label="By ticking this box I give permission to receive MCAE (Pardot) related content and information from MarCloud. I know I can unsubscribe at any time."
                        required
                        checked={formCheckbox}
                        onChange={handleCheckBoxChange}
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="button1">
                      Send Now
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col
              className={
                formSubmitted
                  ? "margin-0 padding-0 contact-form-div-2 display-none"
                  : "margin-0 padding-0 contact-form-div-2 display-block"
              }
              lg="4"
              md="12"
              sm="12"
            >
              <Row className="margin-0 p-3 contact-social">
                <Col lg="12" className="contact-icons-div">
                  <ul className="margin-0 padding-0 contact-icons">
                    <li>
                      <a
                        href="https://marcloudconsulting.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsFillPersonFill
                          size="50px"
                          className="contact-icons-icon"
                        />
                      </a>
                      <div>
                        <p className="contact-icons-title">Consulting </p>
                        <p className="contact-icons-subtitle">
                          www.marcloudconsulting.com{" "}
                        </p>
                      </div>
                    </li>
                    <li>
                      <ImLocation size="50px" className="contact-icons-icon" />
                      <div>
                        <p className="contact-icons-title">Location </p>
                        <p className="contact-icons-subtitle">Liverpool, UK </p>
                      </div>
                    </li>
                    <li>
                      <a
                        href="mailto:getintouch@marcloudconsulting.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <HiOutlineMail
                          size="50px"
                          className="contact-icons-icon"
                        />
                      </a>
                      <div>
                        <p className="contact-icons-title">Get in touch </p>
                        <p className="contact-icons-subtitle">
                          getintouch@marcloudconsulting.com
                        </p>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col lg="12" className="padding-0 contact-bottom">
                  <Row className="margin-0 padding-0 contact-bottom-row">
                    <p className="follow-us">Follow us</p>
                    <ul className="contact-social-icons">
                      <li>
                        <a
                          href="https://www.linkedin.com/company/marcloud-consulting/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ImLinkedin2
                            size="40px"
                            className="contact-social-icons-icon"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="mailto:getintouch@marcloudconsulting.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <HiOutlineMail
                            size="40px"
                            className="contact-social-icons-icon"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/marcloudc"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <BsTwitter
                            size="40px"
                            className="contact-social-icons-icon"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCAOSvJw88tffCX0o9dzeQBA"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <BsYoutube
                            size="40px"
                            className="contact-social-icons-icon"
                          />
                        </a>
                      </li>
                    </ul>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Contact;
