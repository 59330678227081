import * as React from "react";
import "./ourProductsSection.css";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
const OurProductsSection = (props) => {
  return (
    <>
      <Col lg={8} md={10} sm={10} xs={11} className="ourProductsSection-col">
        <div>
          <div className="ourProductsSection-card">
            <div className="flex justify-center ">
              <img src={props.img} className={props.imgStyle} alt="product" />
            </div>
          </div>
          <div>
            <h3>{props.title}</h3>
            {props.content}
            <Link to={props.link} target={props.target}>
              <button className="button4">More details</button>
            </Link>
          </div>
        </div>
      </Col>
    </>
  );
};
export default OurProductsSection;
