import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import PiwikPro from "@piwikpro/react-piwik-pro";

PiwikPro.initialize(
  "4bcb274d-872d-4de7-8d6b-1119dc024ee8",
  "https://marcloudconsulting.containers.piwik.pro"
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);
