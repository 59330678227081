import React from "react";
import "./banner.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BiScan } from "react-icons/bi";
import { AiOutlineCheck } from "react-icons/ai";
import { MdAutorenew } from "react-icons/md";
import { MdDateRange } from "react-icons/md";
import { IoWarningOutline } from "react-icons/io5";

const Banner = (props) => {
  const plan = props.plan;
  return (
    <Row className="dashboard-banner-subscription-row">
      <Col lg="3" md="12" className="flex ">
        <Row>
          <Col xs="5" className="flex align justify-end">
            {plan.status === "active" ? (
              <AiOutlineCheck color="green" size="60px" />
            ) : (
              <IoWarningOutline color="red" size="60px" />
            )}
          </Col>
          <Col xs="7" className="flex align justify-start">
            <div>
              <Row className="dashboad-banner-subscription-title">Status</Row>
              <Row className="dashboad-banner-subscription-status">
                {plan.status.charAt(0).toUpperCase() + plan.status.slice(1)}
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
      <Col lg="2" md="6" className="flex ">
        <Row>
          <Col xs="5" className="flex align justify-end">
            <BiScan size="70px" color="orange" />{" "}
          </Col>
          <Col xs="7" className="flex align justify-start">
            <div>
              <Row className="dashboad-banner-subscription-title">
                Total scans used
              </Row>
              <Row className="dashboad-banner-subscription-status">{props.totalScans}</Row>
            </div>
          </Col>
        </Row>
      </Col>
      <Col lg="3" md="6" className="flex ">
        <Row>
          <Col xs="5" className="flex align justify-end">
            <MdAutorenew color="orange" size="60px" />
          </Col>
          <Col xs="7" className="flex align justify-start">
            <div>
              <Row className="dashboad-banner-subscription-title">
                Next payment
              </Row>
              <Row className="dashboad-banner-subscription-status">
                {plan.nextPayment}
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
      <Col lg="4" md="12" className="flex ">
        <Row>
          <Col xs="2" className="flex align justify-end">
            <MdDateRange color="black" size="70px" />
          </Col>
          <Col xs="10" className="flex align justify-start">
            <div>
              <Row className="dashboad-banner-subscription-title">License</Row>
              <Row className="dashboad-banner-subscription-status">
                {plan.license}
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Banner;
