import React from "react";
import "./homeProducts.css";
import Row from "react-bootstrap/Row";
import etc from "../../../assets/img/img17.png";
import pps from "../../../assets/img/img11.png";
import cs from "../../../assets/img/img7.png";
import ret from "../../../assets/img/img8.png";
import lfp from "../../../assets/img/img15.png";
import atp from "../../../assets/img/img16.png";
import cpc from "../../../assets/img/img18.png";
import ProductCard from "../../../components/home/products/productCard";
import { Link } from "react-router-dom";
const Products = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="flex justify-center align text-align-center">
      <div className="div-width home-products">
        <h2 className="margin-0 padding-0">Solutions</h2>
        <div className="orange-line"></div>
        <h3 className="margin-0 padding-0">
          Boosting Salesforce developers and marketers to the next level
        </h3>
        <Row className="flex justify-center margin-0">
          <ProductCard
            title="Responsive Email Templates"
            description="Want to send beautiful emails without worrying how they will appear once they reach the customer? Our tried and tested email templates are easy to brand and will look good in any inbox."
            img={ret}
            imgClass="home-products-ret"
            link="/EmailTemplates"
          />
          <ProductCard
            title="Pardot Form & EPC - Look and Feel Package"
            description="Let us implement a well built, responsive form and email preference centre template for you. We will also provide auto-resizing functionality to perfectly place your forms on your responsive website!"
            img={lfp}
            imgClass="home-products-lfp"
            link="https://pardot.marcloud.com/look-and-feel"
            target="_blank"
          />
          <ProductCard
            title="Pardot Form Analytics and Tracking Package"
            description="Track and collect robust UTM tracking in Pardot upon every form submission that aligns with GA4."
            img={atp}
            imgClass="home-products-atp"
            link="https://pardot.marcloud.com/Pardot-Tracking-Package"
            target="_blank"
          />
          <ProductCard
            title="Salesforce Marketing Cloud Engagement - Custom Preference Center"
            description="Quick-build SFMCE Preference Center that can be fully branded and is easy to maintain and edit"
            img={cpc}
            imgClass="home-products-cpc"
            link="/CustomPreferenceCenter"
          />
          <ProductCard
            title="Pardot Prospect Scanner app"
            description="End bad-data and streamline your lead capture at in-person
            events with our innovative app for iOS and Android."
            img={pps}
            imgClass="home-products-pps"
            link="/PardotProspectScanner"
          />
          <ProductCard
            title="Email template course"
            description="Take the 'Pardot Email for the Reluctant Coder' training
                    course and learn how to get the most out of your Pardot
                    email template builder."
            img={etc}
            imgClass="home-products-etc"
            link="https://training.marcloudconsulting.com/course/pardot-email-for-reluctant-coders"
            target="_blank"
          />
          <ProductCard
            title="Custom solutions"
            description=" Our team of developers can help overcome limitations in your
            Sales Cloud, Marketing Cloud, and Marketing Cloud Account
            Engagement accounts."
            img={cs}
            imgClass="home-products-cs"
            link="/CustomSolutions"
          />
        </Row>
        <Link to="/Solutions">
          <button className="button4" onClick={scrollToTop}>
            See all solutions
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Products;
