import React from "react";
import "./branding.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FiEdit2 } from "react-icons/fi";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useMutation } from "@apollo/client";
import { UPDATE_BRANDING } from "../../utils/mutations";
import { FaFacebook } from "react-icons/fa";
import { RxInstagramLogo } from "react-icons/rx";
import { FaTwitter } from "react-icons/fa";
import { SiLinkedin } from "react-icons/si";
import ErrorComponent from "../../components/error/error";
import FormData from "form-data";
import { AES, enc } from "crypto-js";

const EditBranding = (props) => {
  const branding = props.branding;
  const decryptedBuid = AES.decrypt(
    props.buid,
    process.env.REACT_APP_SECRET_KEY
  ).toString(enc.Utf8);
  const [updateBranding] = useMutation(UPDATE_BRANDING);

  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState(branding.logo);

  const handleImageError = () => {
    setPreviewImage();
  };

  const [brandingFormData, setBrandingFormData] = React.useState({
    brandingName: branding.name,
    displayFont: branding.displayFont,
    copyFont: branding.copyFont,
    buttonStyle: branding.buttonStyle,
    preferenceLink: branding.preferenceLink || "",
    highlightColor: branding.highlightColor,
    lightColor: branding.lightColor,
    midColor: branding.midColor,
    darkColor: branding.darkColor,
    logo: branding.logo,
    facebook: branding.facebook || "",
    instagram: branding.instagram || "",
    twitter: branding.twitter || "",
    linkedIn: branding.linkedIn || "",
  });

  const handleBrandingChange = async (e) => {
    setError(false);
    setErrorMessage(<></>);

    if (e.target.type === "file") {
      const file = e.target.files[0];
      const reader = new FileReader();
      if (
        file &&
        ["image/png", "image/jpeg", "image/jpg"].includes(file.type)
      ) {
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        setPreviewImage();
      }
      setBrandingFormData({
        ...brandingFormData,
        [e.target.name]: file,
      });
    } else {
      setBrandingFormData({
        ...brandingFormData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleEditModal = () => setEditModal(true);
  const handleCloseEditModal = () => {
    setEditModal(false);
    setError(false);
    setErrorMessage(<></>);
    setBrandingFormData({
      brandingName: branding.name,
      displayFont: branding.displayFont,
      copyFont: branding.copyFont,
      buttonStyle: branding.buttonStyle,
      preferenceLink: branding.preferenceLink || "",
      highlightColor: branding.highlightColor,
      lightColor: branding.lightColor,
      midColor: branding.midColor,
      darkColor: branding.darkColor,
      logo: branding.logo,
      facebook: branding.facebook || "",
      instagram: branding.instagram || "",
      twitter: branding.twitter || "",
      linkedIn: branding.linkedIn || "",
    });
    setPreviewImage(branding.logo);
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    if (branding.logo !== brandingFormData.logo) {
      if (
        !brandingFormData.logo ||
        !["image/png", "image/jpeg", "image/jpg"].includes(
          brandingFormData.logo.type
        )
      ) {
        setErrorMessage("Please select a PNG, JPG, or JPEG file.");
        setError(true);
        return;
      }

      const maxSizeInBytes = 100 * 1024;
      if (brandingFormData.logo.size > maxSizeInBytes) {
        setErrorMessage("Please select an image file smaller than 100KB.");
        setError(true);
        return;
      }

      const formData = new FormData();
      formData.append("file", brandingFormData.logo);
      formData.append(
        "input",
        `{"name": "MarCloud - ${brandingFormData.brandingName} logo - Do Not Delete" }`
      );
      formData.append("accessToken", sessionStorage.getItem("accessToken"));
      formData.append("businessUnitId", decryptedBuid);
      try {
        const response = await fetch("/sf-save-image", {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const result = await response.json();
          const imageUrl = result.url;
          const user = await updateBranding({
            variables: {
              buidId: props.buidId,
              brandingId: props.branding._id,
              brandingInput: {
                name: brandingFormData.brandingName,
                displayFont: brandingFormData.displayFont,
                copyFont: brandingFormData.copyFont,
                buttonStyle: brandingFormData.buttonStyle,
                preferenceLink: brandingFormData.preferenceLink || "",
                highlightColor: brandingFormData.highlightColor,
                lightColor: brandingFormData.lightColor,
                midColor: brandingFormData.midColor,
                darkColor: brandingFormData.darkColor,
                logo: imageUrl,
                facebook: brandingFormData.facebook || "",
                instagram: brandingFormData.instagram || "",
                twitter: brandingFormData.twitter || "",
                linkedIn: brandingFormData.linkedIn || "",
              },
            },
          });
          if (typeof props.onUpdate === "function") {
            const updatedBuid = user.data.updateBranding.buids.find(
              (buid) => buid._id === props.buidId
            );
            const updatedBranding = updatedBuid.brandings.find(
              (branding) => branding._id === props.branding._id
            );
            props.onUpdate(updatedBuid, updatedBranding);
          }
          setEditModal(false);
        }
      } catch (error) {
        console.error(error);
        setErrorMessage(error.message || "Error saving changes!");
        setError(true);
      }
    } else {
      const user = await updateBranding({
        variables: {
          buidId: props.buidId,
          brandingId: props.branding._id,
          brandingInput: {
            name: brandingFormData.brandingName,
            displayFont: brandingFormData.displayFont,
            copyFont: brandingFormData.copyFont,
            buttonStyle: brandingFormData.buttonStyle,
            preferenceLink: brandingFormData.preferenceLink || "",
            highlightColor: brandingFormData.highlightColor,
            lightColor: brandingFormData.lightColor,
            midColor: brandingFormData.midColor,
            darkColor: brandingFormData.darkColor,
            logo: branding.logo,
            facebook: brandingFormData.facebook || "",
            instagram: brandingFormData.instagram || "",
            twitter: brandingFormData.twitter || "",
            linkedIn: brandingFormData.linkedIn || "",
          },
        },
      });
      if (typeof props.onUpdate === "function") {
        const updatedBuid = user.data.updateBranding.buids.find(
          (buid) => buid._id === props.buidId
        );
        const updatedBranding = updatedBuid.brandings.find(
          (branding) => branding._id === props.branding._id
        );
        props.onUpdate(updatedBuid, updatedBranding);
      }

      setEditModal(false);
    }
  };
  return (
    <>
      <div className="buid-branding-edit" onClick={handleEditModal}>
        <FiEdit2 color="green" size="20px" />
      </div>

      <Modal
        show={editModal}
        onHide={handleCloseEditModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className="buid-card-edit-modal"
      >
        <Form className="account-buid-div" onSubmit={handleSaveChanges}>
          <Modal.Header closeButton>
            <Modal.Title className="title">
              Edit <span className="bold">{branding.name}</span> Branding
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row className="margin-0 padding-0">
              <Form.Group as={Col} xs={12} className="mb-3">
                <Form.Label>Branding name</Form.Label>
                <Form.Control
                  type="text"
                  name="brandingName"
                  required
                  onChange={handleBrandingChange}
                  value={brandingFormData.brandingName}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} className="mb-3">
                <Form.Label>Headings Font</Form.Label>
                <Form.Select
                  required
                  name="displayFont"
                  onChange={handleBrandingChange}
                  value={brandingFormData.displayFont}
                  aria-label="Default select example"
                  style={{ fontFamily: brandingFormData.displayFont }}
                >
                  <option value="Poppins">Poppins</option>
                  <option value="Open Sans">Open Sans</option>
                  <option value="Roboto">Roboto</option>
                  <option value="Arial">Arial</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} className="mb-3">
                <Form.Label>Body Font</Form.Label>
                <Form.Select
                  required
                  name="copyFont"
                  onChange={handleBrandingChange}
                  value={brandingFormData.copyFont}
                  aria-label="Default select example"
                  style={{ fontFamily: brandingFormData.copyFont }}
                >
                  <option value="Poppins">Poppins</option>
                  <option value="Open Sans">Open Sans</option>
                  <option value="Roboto">Roboto</option>
                  <option value="Arial">Arial</option>
                  <option value="Titillium">Titillium</option>
                  <option value="Lato">Lato</option>
                  <option value="Paytone One">Paytone One</option>
                  <option value="Inter">Inter</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} className="mb-3">
                <Form.Label>Button style</Form.Label>
                <Form.Select
                  required
                  name="buttonStyle"
                  onChange={handleBrandingChange}
                  value={brandingFormData.buttonStyle}
                  aria-label="Default select example"
                >
                  <option>Choose button style</option>
                  <option value="Square">Square</option>
                  <option value="Rounded">Rounded</option>
                  <option value="Slightly Rounded">Slightly rounded</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} className="mb-3">
                <Form.Label>
                  Preference link
                  <span className="account-branding-optional">(optional)</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="preferenceLink"
                  onChange={handleBrandingChange}
                  value={brandingFormData.preferenceLink}
                />
              </Form.Group>
              <Form.Group as={Col} xs={6} md={3} className="mb-3">
                <Form.Label htmlFor="exampleColorInput">
                  Highlight Color
                </Form.Label>
                <Form.Control
                  type="color"
                  id="exampleColorInput"
                  title="Choose your color"
                  name="highlightColor"
                  onChange={handleBrandingChange}
                  value={brandingFormData.highlightColor}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xs={6} md={3} className="mb-3">
                <Form.Label htmlFor="exampleColorInput">Light Color</Form.Label>
                <Form.Control
                  type="color"
                  id="exampleColorInput"
                  title="Choose your color"
                  name="lightColor"
                  onChange={handleBrandingChange}
                  value={brandingFormData.lightColor}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xs={6} md={3} className="mb-3">
                <Form.Label htmlFor="exampleColorInput">Mid Color</Form.Label>
                <Form.Control
                  type="color"
                  id="exampleColorInput"
                  title="Choose your color"
                  name="midColor"
                  onChange={handleBrandingChange}
                  value={brandingFormData.midColor}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} xs={6} md={3} className="mb-3">
                <Form.Label htmlFor="exampleColorInput">Dark Color</Form.Label>
                <Form.Control
                  type="color"
                  id="exampleColorInput"
                  title="Choose your color"
                  name="darkColor"
                  onChange={handleBrandingChange}
                  value={brandingFormData.darkColor}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Logo</Form.Label>
                <Form.Control
                  type="file"
                  name="logo"
                  onChange={handleBrandingChange}
                  required={!previewImage}
                />
              </Form.Group>
              {previewImage && (
                <Col xs="12">
                  <Row>
                    <Col xs="12" md="8" lg="3">
                      <div className="buid-branding-logo-div flex align justify-center">
                        <img
                          src={previewImage}
                          className="buid-branding-logo"
                          onError={handleImageError}
                          alt="logo"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col xs="8"></Col>
              <Form.Group as={Col} xs={12} md={6} className="mb-3">
                <Form.Label className="flex align">
                  <FaFacebook
                    color="#316FF6"
                    size="25px"
                    className="account-branding-social-icons"
                  />
                  Facebook
                  <span className="account-branding-optional">(optional)</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="facebook"
                  onChange={handleBrandingChange}
                  value={brandingFormData.facebook}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} className="mb-3">
                <Form.Label className="flex align">
                  <RxInstagramLogo
                    color="#E1306C"
                    size="25px"
                    className="account-branding-social-icons"
                  />
                  Instagram
                  <span className="account-branding-optional">(optional)</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="instagram"
                  onChange={handleBrandingChange}
                  value={brandingFormData.instagram}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} className="mb-3">
                <Form.Label className="flex align">
                  <SiLinkedin
                    color="#0077b5"
                    size="25px"
                    className="account-branding-social-icons"
                  />
                  LinkedIn
                  <span className="account-branding-optional">(optional)</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="linkedIn"
                  onChange={handleBrandingChange}
                  value={brandingFormData.linkedIn}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} className="mb-3">
                <Form.Label className="flex align">
                  <FaTwitter
                    color="#1DA1F2"
                    size="25px"
                    className="account-branding-social-icons"
                  />
                  X/Twitter
                  <span className="account-branding-optional">(optional)</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="twitter"
                  onChange={handleBrandingChange}
                  value={brandingFormData.twitter}
                />
              </Form.Group>
            </Row>
            {error && <ErrorComponent errorMessage={errorMessage} />}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="account-form-cancel"
              onClick={handleCloseEditModal}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              className="account-form-save"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export default EditBranding;
