import * as React from "react";
import "./signup.css";
import axios from "axios";
import qs from "qs";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { BiErrorCircle } from "react-icons/bi";
import { AiFillCheckCircle } from "react-icons/ai";
import { useMutation } from "@apollo/client";
import { SIGNUP_USER } from "../../utils/mutations.js";
import { AES } from "crypto-js";
import confirmationImage from "../../assets/img/img8.png";
import loadingGif from "../../assets/loading1.gif";
import { InputGroup } from "react-bootstrap";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
import { IoMdCheckmark } from "react-icons/io";

const SignUp = ({ changeBg }) => {
  const color = "transparent";
  React.useEffect(() => {
    changeBg(color);
  });

  const actionUrl = process.env.REACT_APP_SIGNUP_FORM_HANDLER;
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

  const [signup, { error, data }] = useMutation(SIGNUP_USER);
  const [loading, setLoading] = React.useState(false);
  const [resendLoading, setResendLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [resendError, setResendError] = React.useState(false);
  const [resendSuccess, setResendSuccess] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [prospectData, setProspectData] = React.useState();
  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    password: "",
  });
  const [agreedToTerms, setAgreedToTerms] = React.useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrorMessage("");
  };
  const handleAgreementChange = (e) => {
    setAgreedToTerms(e.target.checked);
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setLoading(false);
    }
    if (
      !formData.password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9\s])[A-Za-z\d\S]{8,}$/
      )
    ) {
      setErrorMessage(
        <div className="login-error">
          <BiErrorCircle size="30px" color="orange" /> Password must be at least
          8 characters, with at least 1 uppercase letter, 1 lowercase letter, 1
          number & 1 special character.
        </div>
      );
      setLoading(false);
    } else {
      try {
        await signup({
          variables: { ...formData },
        });
        const { firstName, lastName, companyName, email } = event.target;
        const signup_data = qs.stringify({
          firstName: firstName.value,
          lastName: lastName.value,
          company: companyName.value,
          email: email.value,
          date: Date.now(),
        });
        const encrypted_signup_data = AES.encrypt(
          signup_data,
          SECRET_KEY
        ).toString();
        const prospect_data = {
          firstName: firstName.value,
          lastName: lastName.value,
          company: companyName.value,
          email: email.value,
          encryptedConfirmationString: encodeURIComponent(
            encrypted_signup_data
          ),
        };
        setProspectData(prospect_data);
        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: actionUrl,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: prospect_data,
        };
        await axios.request(config);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        const errorCode = error.graphQLErrors[0].extensions.error.code;
        if (errorCode === 11000) {
          setErrorMessage(
            <div className="login-error">
              <BiErrorCircle size="30px" color="orange" /> An account with this
              email address already exists
            </div>
          );
        } else {
          setErrorMessage(
            <div className="login-error">
              <BiErrorCircle size="30px" color="orange" /> Error creating User.
              Please try again later or contact us for help.
            </div>
          );
        }
      }
    }
  };
  const handleResendEmail = async () => {
    setResendError(false);
    setResendSuccess(false);
    setResendLoading(true);
    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: actionUrl,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: prospectData,
      };
      try {
        await axios.request(config);
        setResendLoading(false);
        setResendSuccess(true);
      } catch (e) {
        setResendLoading(false);
        setResendSuccess(true);
      }
    } catch (error) {
      setResendError(true);
      setResendLoading(false);
      console.log(error);
    }
  };
  return (
    <div className="stars-bg">
      <Row className="div-width text-align-center flex justify-center signup">
        {data ? (
          <>
            <Col
              lg="6"
              md="6"
              sm="12"
              xs="12"
              className="flex justify-center align"
            >
              <div className="signup-confirmation">
                <h2>
                  <AiFillCheckCircle size="60px" className="password-check" />{" "}
                  Thank you for signing up!
                </h2>
                <p>
                  To activate your account, we have sent you a confirmation
                  email. Please check your email inbox (including the spam or
                  junk folder) for a message from us.
                </p>
                <p>
                  In the email, you will find a link to activate your account.
                  Once your account is activated, you will be able to log in.
                </p>
                <p>
                  If you don't receive the confirmation email within a few
                  minutes, please contact our support team for further
                  assistance. We're excited to have you on board!
                </p>
                {resendError && (
                  <div className="login-error">
                    <BiErrorCircle size="30px" color="orange" /> Error sending
                    confirmation email, please try again.
                  </div>
                )}
                {resendSuccess && (
                  <div className="login-error">
                    <IoMdCheckmark size="30px" color="#5abf3b" /> Confirmation
                    email resent successfully.
                  </div>
                )}
                {resendLoading ? (
                  <>
                    <img src={loadingGif} width="100px" alt="loading" />
                  </>
                ) : (
                  <>
                    <button
                      className="signup-resend-btn"
                      onClick={() => {
                        handleResendEmail();
                      }}
                    >
                      Resend email
                    </button>
                    <Link to="/ContactUs">
                      <button className="button4">Contact Us</button>
                    </Link>
                  </>
                )}
              </div>
            </Col>
            <Col
              lg="6"
              md="6"
              sm="12"
              xs="12"
              className="flex justify-center align"
            >
              <div>
                <img src={confirmationImage} width="350px" alt="confirmation" />
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col lg="5" md="8" sm="10" xs="10">
              <h2>Sign Up</h2>
              <div className="blue-line"></div>
              <p className="signup-subtitle">
                Create an account with MarCloud Technologies
              </p>
              <Form onSubmit={handleFormSubmit}>
                {errorMessage}
                <Form.Group className="mb-3" controlId="formFirstName">
                  <Form.Control
                    placeholder="First Name"
                    name="firstName"
                    className="signup-form-label"
                    required
                    onChange={handleChange}
                    value={formData.firstName}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formLastName">
                  <Form.Control
                    placeholder="Last Name"
                    name="lastName"
                    className="signup-form-label"
                    required
                    onChange={handleChange}
                    value={formData.lastName}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formCompanyName">
                  <Form.Control
                    placeholder="Company Name"
                    name="companyName"
                    className="signup-form-label"
                    required
                    onChange={handleChange}
                    value={formData.companyName}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="email"
                    className="signup-form-label"
                    required
                    onChange={handleChange}
                    value={formData.email}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      className="signup-form-label"
                      required
                      onChange={handleChange}
                      value={formData.password}
                    />
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      className="flex align signup-show-password"
                    >
                      {showPassword ? (
                        <IoMdEyeOff color="black" size="25px" />
                      ) : (
                        <IoMdEye color="black" size="25px" />
                      )}
                    </div>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="formAgreement">
                  <Form.Check
                    type="checkbox"
                    label={
                      <>
                        By signing up, you agree to our{" "}
                        <Link to="/TermsAndConditions" target="_blank">
                          Terms and Conditions
                        </Link>{" "}
                        and{" "}
                        <Link to="/PrivacyPolicy" target="_blank">
                          Privacy Policy
                        </Link>
                        . Learn how we collect, use and share your data in our
                        Privacy Policy.
                      </>
                    }
                    onChange={handleAgreementChange}
                    checked={agreedToTerms}
                    className="text-align-left signup-checkbox"
                    required
                  />
                </Form.Group>
                {loading ? (
                  <>
                    <img src={loadingGif} width="100px" alt="loading" />
                  </>
                ) : (
                  <>
                    <Button
                      variant="primary"
                      type="submit"
                      className="button4 signup-button"
                    >
                      Sign Up
                    </Button>
                  </>
                )}
              </Form>
              <p className="signup-bottom-subtitle">
                Already have an account?{" "}
                <Link className="signup-link" to="/login">
                  Log in
                </Link>
              </p>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default SignUp;
