import * as React from "react";
import "./dashboard.css";
import hero from "../../assets/ss/pps.png";
import Subscription from "../../container/ppsDashboard/subscription/subscription";
import Banner from "../../container/ppsDashboard/banner/banner";
import CustomFields from "../../container/ppsDashboard/customFields/customFields";
import Auth from "../../utils/auth";
import jwtDecode from "jwt-decode";
import { useQuery } from "@apollo/client";
import { GET_USER } from "../../utils/queries";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IoWarningOutline } from "react-icons/io5";

const Dashboard = ({ changeBg }) => {
  const color = "transparent";
  const [plan, setPlan] = React.useState();
  const [userId, setUserId] = React.useState(null);
  const [isSubcribed, setIsSubscribed] = React.useState();
  const [isProfileIncomplete, setIsProfileIncomplete] = React.useState();
  const [subscriptionId, setSubcriptionId] = React.useState();
  const [totalScans, setTotalScans] = React.useState()

  const fetchData = async (stripeId) => {
    try {
      const response = await fetch("/app-subscription", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          subscriptionId: stripeId,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonR = await response.json();
      setPlan(jsonR.plan);
    } catch (error) {
      console.error(error);
    }
  };
  const decodeToken = () => {
    const token = localStorage.getItem("id_token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserId(decoded.data._id);
      } catch (error) {
        console.error("Error decoding token:", error.message);
      }
    }
  };
  const { error, data } = useQuery(GET_USER, {
    variables: { userId },
  });
  const resubscribe = async () => {
    try {
      const session = await fetch(`/app-create-checkout-session`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      const sessionJSON = await session.json();
      window.location.href = sessionJSON.url;
    } catch (error) {
      console.error(error);
    }
  };
  React.useEffect(() => {
    const isLoggedIn = Auth.loggedIn();
    if (!isLoggedIn) {
      const prevPage = encodeURIComponent(window.location.href);
      window.location.assign(`/login?prev=${prevPage}`);
    }
    changeBg(color);
    decodeToken();
  }, []);
  React.useEffect(() => {
    if (data) {
      if (
        !data.getUser.appSubscriptions ||
        data.getUser.appSubscriptions.length === 0
      ) {
        setIsSubscribed(false);
      } else {
        setIsSubscribed(true);
        const index = data.getUser.appSubscriptions.length - 1;
        const stripeId = data.getUser.appSubscriptions[index].stripeReference;
        setTotalScans(data.getUser.appSubscriptions[index].totalCredits)
        if (
          !data.getUser.appSubscriptions[index].buidId ||
          !data.getUser.instanceUrl ||
          !data.getUser.clientId ||
          !data.getUser.clientSecret ||
          !data.getUser.buids ||
          data.getUser.buids.length === 0
        ) {
          setIsProfileIncomplete(true);
        } else {
          setIsProfileIncomplete(false);
        }
        setSubcriptionId(stripeId);
        fetchData(stripeId);
      }

    }
  }, [data]);

  return (
    <>
      <div className="stars-bg">
        <div className="div-width">
          <div className="text-align-center dashboard-hero-div">
            <h2>Pardot Prospect Scanner App</h2>
            <h3>Manage Subscription</h3>
            <img src={hero} />
          </div>
          {!isSubcribed && (
            <div className="flex align justify-center">
              <div className="text-align-center">
                <div>
                  You are not subscribed to the Pardot Prospect Scanner App
                </div>
                <Link to="/PardotProspectScanner">
                  <button className="button4 dashboard-subscribe-btn">
                    Subscribe now
                  </button>
                </Link>
              </div>
            </div>
          )}
          {isSubcribed && isProfileIncomplete && (
            <div className="flex align dashboard-profile-warning">
              <div className="warning">
                <IoWarningOutline color="red" size="60px" />
              </div>
              <div>
                <span className="bold">Incomplete Profile! </span>
                In order to use the app, you will need to provide your{" "}
                <span className="bold">Consumer Details</span> and to choose a{" "}
                <span className="bold">Business Unit</span>. Please navigate to
                your{" "}
                <Link to="/Account" className="bold">
                  Account page
                </Link>{" "}
                and complete your profile before logging in to the Prospect
                Scanner App.
              </div>
            </div>
          )}
          {isSubcribed && plan && (
            <>
              <Banner plan={plan} totalScans={totalScans}/>
              <Subscription
                plan={plan}
                subscriptionId={subscriptionId}
                resubscribe={resubscribe}
                refreshPlan={fetchData}
              />
            </>
          )}
          {/* <CustomFields /> */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
