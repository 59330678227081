import * as React from "react";
import "./pardotProspectScanner.css";
import Hero from "../../../components/solution/hero";
import Banner from "../../../container/pps/banner/banner";
import Intro from "../../../container/pps/intro/intro";
import Icons from "../../../container/pps/icons/icons";
import Screenshots from "../../../container/pps/screenshots/screenshots";
import FlippedCurveColor from "../../../components/curve/flippedCurveColor";
import SolutionContact from "../../../components/contact/solutionContact";
import BottomNavigation from "../../../components/bottomNavigation/bottomNavigation";
import Curve from "../../../components/curve/curveColor";
import hero from "../../../assets/ss/phone.png";
import Auth from "../../../utils/auth";

const PardotProspectScanner = ({ changeBg }) => {
  const color = "#e4ebf5";
  const [products, setProducts] = React.useState();
  const isLoggedIn = Auth.loggedIn();

  React.useEffect(() => {
    changeBg(color);
    fetch("/products", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ filter: "Pardot Prospect Scanner" }),
    })
      .then((r) => r.json())
      .then((jsonResponse) => setProducts(jsonResponse.productInfo));
  });
  function scrollToSection() {
    const section2 = document.getElementById("intro");
    section2.scrollIntoView({ behavior: "smooth" });
  }
  const checkout = async () => {
    if (!isLoggedIn) {
      window.location.assign(`/login?prev=${encodeURIComponent(window.location.href)}`);
    }
    const session = await fetch(`/app-create-checkout-session`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
    const sessionJSON = await session.json();
    window.location.href = sessionJSON.url;
  };
  return (
    <>
      <div className="overflow-x pps">
        <Hero
          subtitle="The first business card scanner app for Pardot"
          title="Pardot Prospect Scanner"
          content={
            <>
              <div>
                Need an intuitive solution for integrating in-person events with
                your Marketing Cloud Account Engagement (Pardot) platform? Fed
                up of bad data coming in from networking events? Bogged-down in
                data entry tasks when you should be projecting forward,
                analysing and optimising your marketing journeys? MarCloud’s
                Pardot Prospect Scanner is your ideal solution.
              </div>
            </>
          }
          heroImg={hero}
          imgClass="pps-heroImg"
          actionButtons={
            <>
              {products && (
                <div className="pps-price">
                  Price:{" "}
                  <span>
                    {products[0].cost} {products[0].currency.toUpperCase()}
                  </span>
                  / month
                </div>
              )}
              <button
                className="button4 solution-hc-left-button1"
                onClick={scrollToSection}
              >
                Read more
              </button>
              {/* <Link to="/ContactUs">
                <button className="button4">Enquire now</button>
              </Link> */}
              <button className="button4" onClick={checkout}>
                Buy now
              </button>
            </>
          }
        />
        <div className="lightblue-bg ">
          <div className="div-width">
            <Banner />
            <div id="intro">
              <Intro />
            </div>
          </div>
        </div>
        <FlippedCurveColor color="#e4ebf5" bgColor="#f7f7f7" />
        <div className="offwhite-bg  ">
          <div className="div-width">
            <Icons />
          </div>
        </div>
        <FlippedCurveColor color="#f7f7f7" bgColor="#e4ebf5" />
        <div className="lightblue-bg">
          <div className="div-width">
            <Screenshots />
          </div>
        </div>
        <FlippedCurveColor color="#e4ebf5" />
        <SolutionContact solution="Pardot Prospect Scanner" />
        <Curve color="#e4ebf5" />
        <div className="lightblue-bg">
          <div className="div-width">
            <BottomNavigation />
          </div>
        </div>
      </div>
    </>
  );
};

export default PardotProspectScanner;
