import * as React from "react";
import "./buidCard.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Branding from "../branding/branding";
import EditBuid from "./editBuid";
import DeleteBuid from "./deleteBuid";
import AddBranding from "../branding/addBranding";
import Form from "react-bootstrap/Form";
import { useMutation } from "@apollo/client";
import { CHANGE_APP_BU } from "../../utils/mutations";

const BuidCard = (props) => {
  const buid = props.buid || [];
  const brandingsFromProps = buid.brandings || [];
  const [changeAppBu, { data, error }] = useMutation(CHANGE_APP_BU);
  const initialBrandings = brandingsFromProps.map((branding) => {
    return {
      _id: branding._id,
      name: branding.name || "",
      displayFont: branding.displayFont || "",
      copyFont: branding.copyFont || "",
      buttonStyle: branding.buttonStyle || "",
      preferenceLink: branding.preferenceLink || "",
      highlightColor: branding.highlightColor || "#ed8f38",
      lightColor: branding.lightColor || "#8fe4ff",
      midColor: branding.midColor || "#0f75bc",
      darkColor: branding.darkColor || "#052e57",
      logo: branding.logo || "",
      facebook: branding.facebook || "",
      instagram: branding.instagram || "",
      twitter: branding.twitter || "",
      linkedIn: branding.linkedIn || "",
    };
  });
  const brandingsLength = initialBrandings.length;
  const handleCheckboxChange = () => {
    props.onCheckboxChange(buid._id);
    changeAppBu({ variables: { buidId: buid._id } });
  };
  return (
    <>
      <Card className="buid-card">
        <Card.Body className="padding-0 flex flex-column">
          <Row>
            <Col
              lg="6"
              md="6"
              xs="12"
              className="d-flex flex-column buid-card-left"
            >
              <Card.Title>{buid.shortName}</Card.Title>
              <Card.Title>{buid.longName}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                {buid.address1}, {buid.address2}
              </Card.Subtitle>
              <div className="flex buid-card-buttons">
                <EditBuid buid={buid} />
                <DeleteBuid buid={buid} />
              </div>
            </Col>

            <Col lg="6" md="6" xs="12" className="text-align-center">
              <Row>
                {initialBrandings.map((branding, index) => (
                  <>
                    <Col
                      key={index}
                      lg="4"
                      md="6"
                      xs="12"
                      className="padding-0"
                    >
                      <Branding
                        branding={branding}
                        buidId={buid._id}
                        buid={buid.buid}
                        length={brandingsLength}
                      />
                    </Col>
                  </>
                ))}
                <Col lg="4" md="6" xs="12" className="padding-0">
                  <AddBranding buid={buid} />
                </Col>
              </Row>
            </Col>
            {props.isSubscribed && (
              <Form>
                <div className="mb-3">
                  <Form.Check
                    label={
                      <div>
                        Use this business unit for the {" "}
                        <span className="bold">
                          Pardot Prospect Scanner App
                        </span>
                      </div>
                    }
                    checked={props.checked}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </Form>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
export default BuidCard;
