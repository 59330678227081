import React from "react";
import "./branding.css";
import { MdDeleteOutline } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useMutation } from "@apollo/client";
import { DELETE_BRANDING } from "../../utils/mutations";
import ErrorComponent from "../../components/error/error";

const DeleteBranding = (props) => {
  const branding = props.branding;

  const [deleteBranding] = useMutation(DELETE_BRANDING);

  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();
  const [deleteModal, setDeleteModal] = React.useState(false);
  const handleDeleteModal = () => setDeleteModal(true);
  const handleCloseDeleteModal = () => {
    setError(false);
    setErrorMessage(<></>);
    setDeleteModal(false);
  };

  const handleDeleteBranding = async (index) => {
    try {
      await deleteBranding({
        variables: {
          buidId: props.buidId,
          brandingId: props.branding._id,
        },
      });
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message || "Error deleting branding!");
      setError(true);
    }
  };

  return (
    <>
      <div>
        <MdDeleteOutline color="red" size="20px" onClick={handleDeleteModal} />
      </div>

      <Modal
        show={deleteModal}
        onHide={handleCloseDeleteModal}
        className="buid-card-delete-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="title">
            Delete <span className="bold">{branding.name}</span> Branding
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you would like to delete{" "}
          <span className="bold">{branding.name}</span> branding?
          {error && <ErrorComponent errorMessage={errorMessage} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteBranding}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default DeleteBranding;
