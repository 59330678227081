import * as React from "react";
import "./termsAndConditions.css";

const TermsAndConditions = ({ changeBg }) => {
  const color = "transparent";
  React.useEffect(() => {
    changeBg(color);
  });

  return (
    <div>
      <div className="div-width flex justify-center align ">
        <div className="tc">
          <h2 className="title">Terms&Conditions</h2>
          <div className="blue-line"></div>
          <div className="legal-div">
            <div>
              This agreement applies as between you, the User of this Website,
              and
              <span className="bold"> MarCloud Limited</span>, the owner(s) of
              this Website. Your agreement to comply with and be bound by
              Clauses 1, 2, 4–11 and 15–25 of these Terms and Conditions is
              deemed to occur upon your first use of the Website. Clauses 3 and
              12–14 apply only to the sale of Services. If you do not agree to
              be bound by these Terms and Conditions, you should stop using the
              Website immediately. No part of this Website is intended to
              constitute a contractual offer capable of acceptance. Your order
              constitutes a contractual offer and Our acceptance of that offer
              is deemed to occur upon Our sending a confirmation email to you
              indicating that your order has been accepted.
            </div>
            <h3 className="bold dark-blue-text">Information about us</h3>
            <p>
              Our Website is owned and operated by MarCloud Limited, a limited
              company registered in England under company number 11505110.
            </p>
            <ul className="margin-0 padding-0">
              <li>
                <span className="bold">Company name:</span> MarCloud Limited
              </li>
              <li>
                <span className="bold">Site:</span>{" "}
                https://marcloudconsulting.com
              </li>
              <li>
                <span className="bold">ICO registration number:</span> ZB060968
              </li>
              <li>
                <span className="bold">Address:</span> C/O Uhy Hacker Young St
                James Building, 79 Oxford Street, Manchester, United Kingdom, M1
                6HT
              </li>
              <li>
                <span className="bold">Email:</span>{" "}
                privacy@marcloudconsulting.com
              </li>
            </ul>

            <ul className="margin-0 padding-0 list">
              <li>
                <p className="bold">Definitions and interpretations</p>
                <p>
                  In this Agreement the following terms shall have the following
                  meanings:
                </p>
                <table>
                  <tbody>
                    <tr>
                      <th>Account</th>
                      <td>
                        collectively the personal information, payment
                        information, and credentials used by users to access
                        paid content and/or any communications system on the
                        Website;
                      </td>
                    </tr>
                    <tr>
                      <th>Content</th>
                      <td>
                        any text, graphics, images, audio, video, software, data
                        compilations, and any other form of information capable
                        of being stored in a computer, that appears on or forms
                        part of the Website;
                      </td>
                    </tr>
                    <tr>
                      <th>Facilities</th>
                      <td>
                        any online facilities, tools, services, or information
                        that MarCloud Consulting makes available through the
                        Website now or in the future;
                      </td>
                    </tr>
                    <tr>
                      <th>Services</th>
                      <td>
                        the services available to you through this Website,
                        specifically use of the MarCloud Consulting proprietary
                        e-learning platform;
                      </td>
                    </tr>
                    <tr>
                      <th>Payment information</th>
                      <td>
                        any details required for the purchase of Services from
                        this Website. This includes, but is not limited to,
                        credit/debit card numbers, bank account numbers, and
                        sort codes;
                      </td>
                    </tr>
                    <tr>
                      <th>Premises</th>
                      <td>
                        Our place of business, located at C/O Uhy Hacker Young
                        St James Building, 79 Oxford Street, Manchester, United
                        Kingdom, M1 6HT
                      </td>
                    </tr>
                    <tr>
                      <th>System</th>
                      <td>
                        any online communications infrastructure that MarCloud
                        Consulting makes available through the Website, either
                        now or in the future. This includes, but is not limited
                        to, web-based email, message boards, live chat
                        facilities, and email links;
                      </td>
                    </tr>
                    <tr>
                      <th>User(s)</th>
                      <td>
                        any third party that accesses the Website and is not
                        employed by MarCloud Consulting, and acting in the
                        course of their employment;
                      </td>
                    </tr>
                    <tr>
                      <th>Website</th>
                      <td>
                        the website that you are currently using
                        (marcloudtechnologies.com) and any subdomains of this
                        site (e.g. subdomain.marcloudconsulting.com), unless
                        expressly excluded by their own terms and conditions.
                      </td>
                    </tr>
                    <tr>
                      <th>We/Us/Our</th>
                      <td>
                        means MarCloud Consulting, henceforth known as
                        "MarCloud", a company incorporated in England, with
                        Company Registration Number 11505110, located at C/O Uhy
                        Hacker Young St James Building, 79 Oxford Street,
                        Manchester, United Kingdom, M1 6HT
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li>
                <p className="bold">Age restrictions</p>
                <p>
                  Persons under the age of 18 should use this Website only with
                  the supervision of an adult. Payment information must be
                  provided by or with the permission of an adult.
                </p>
              </li>
              <li>
                <p className="bold">Business customers</p>
                <p>
                  These Terms and Conditions also apply to customers procuring
                  Services in the course of business.
                </p>
              </li>
              <li>
                <p className="bold">Intellectual property</p>
                <p>
                  <span className="bold">4.1 </span>Subject to the exceptions in
                  Clause 5 of these Terms and Conditions, all Content included
                  on the Website, unless uploaded by Users, including, but not
                  limited to, text, graphics, logos, icons, images, sound clips,
                  video clips, data compilations, page layout, underlying code
                  and software is the property of{" "}
                  <span className="bold">MarCloud</span>, our affiliates or
                  other relevant third parties. By continuing to use the Website
                  you acknowledge that such material is protected by applicable
                  United Kingdom and International intellectual property and
                  other laws.
                </p>

                <p>
                  <span className="bold">4.2 </span>Subject to Clause 6 you may
                  not reproduce, copy, distribute, store, or in any other
                  fashion re-use material from the Website, unless otherwise
                  indicated on the Website or unless given Our express written
                  permission to do so.
                </p>
              </li>
              <li>
                <p className="bold">Third-party intellectual property</p>
                <p>
                  <span className="bold">5.1 </span>Unless otherwise expressly
                  indicated, all Intellectual Property rights including, but not
                  limited to, Copyright and Trademarks, in product images and
                  descriptions, belong to the manufacturers or distributors of
                  such products as may be applicable.
                </p>

                <p>
                  <span className="bold">5.2 </span>Subject to Clause 6 you may
                  not reproduce, copy, distribute, store, or in any other
                  fashion re-use such material, unless otherwise indicated on
                  the Website, or unless given express written permission to do
                  so by the relevant manufacturer or supplier.
                </p>
              </li>
              <li>
                <p className="bold">Fair use of intellectual property</p>
                <p>
                  Material from the Website may be re-used without written
                  permission where any of the exceptions detailed in Chapter III
                  of the Copyright Designs and Patents Act 1988 apply.
                </p>
              </li>
              <li>
                <p className="bold">Links to other websites</p>
                <p>
                  This Website may contain links to other sites. Unless
                  expressly stated, these sites are not under the control of
                  <span className="bold"> MarCloud</span> or that of Our
                  affiliates. We assume no responsibility for the content of
                  such websites and disclaim liability for any and all forms of
                  loss or damage arising out of the use of them. The inclusion
                  of a link to another site on this Website does not imply any
                  endorsement of the sites themselves or of those in control of
                  them.
                </p>
              </li>
              <li>
                <p className="bold">Links to this website</p>
                <p>
                  Those wishing to place a link to this Website on other sites
                  may do so only to the home page of the site
                  <span className="bold"> marcloudtechnologies.com</span>{" "}
                  without Our prior permission. Deep linking (i.e. links to
                  specific pages within the site) requires Our express written
                  permission. To find out more, please contact Us by email at
                  <span className="bold">
                    {" "}
                    support@marcloudtechnologies.com
                  </span>
                  .
                </p>
              </li>
              <li>
                <p className="bold">Use of communications facilities</p>
                <p>
                  <span className="bold">9.1 </span>When using any System on the
                  Website, you should do so in accordance with the following
                  rules. Failure to comply with these rules may result in your
                  Account being suspended or closed:
                </p>
                <div className="tc-sublist">
                  <p>
                    <span className="bold">9.1.1 </span>You must not use obscene
                    or vulgar language;
                  </p>
                  <p>
                    <span className="bold">9.1.2 </span>You must not submit
                    Content that is unlawful or otherwise objectionable. This
                    includes, but is not limited to, Content that is abusive,
                    threatening, harassing, defamatory, ageist, sexist or
                    racist;
                  </p>
                  <p>
                    <span className="bold">9.1.3 </span>You must not submit
                    Content that is intended to promote or incite violence;
                  </p>
                  <p>
                    <span className="bold">9.1.4 </span>It is advised that
                    submissions are made using the English language, as We may
                    be unable to respond to enquiries submitted in any other
                    languages;
                  </p>
                  <p>
                    <span className="bold">9.1.5 </span>The means by which you
                    identify yourself must not violate these Terms and
                    Conditions or any applicable laws;
                  </p>
                  <p>
                    <span className="bold">9.1.6 </span>You must not impersonate
                    other people, particularly employees and representatives of
                    <span className="bold"> MarCloud</span> or Our affiliates;
                    and
                  </p>
                  <p>
                    <span className="bold">9.1.7 </span>You must not use Our
                    System for unauthorised mass-communication, such as "spam"
                    or "junk mail".
                  </p>
                </div>
                <p>
                  <span className="bold">9.2 </span>You acknowledge that
                  <span className="bold"> MarCloud</span> reserves the right to
                  monitor any and all communications made to Us or using Our
                  System.
                </p>
                <p>
                  <span className="bold">9.3 </span>You acknowledge that
                  <span className="bold"> MarCloud</span> may retain copies of
                  any and all communications made to Us or using Our System.
                </p>
                <p>
                  <span className="bold">9.4 </span>You acknowledge that any
                  information you send to Us through Our System may be modified
                  by Us in any way, and you hereby waive your moral right to be
                  identified as the author of such information. Any restrictions
                  you may wish to place upon Our use of such information must be
                  communicated to Us in advance, and We reserve the right to
                  reject such terms and associated information.
                </p>
              </li>
              <li>
                <p className="bold">Accounts</p>
                <p>
                  <span className="bold">10.1 </span>In order to procure
                  Services on this Website and to use certain other parts of the
                  System, you are required to create an Account which will
                  contain certain personal details and Payment Information,
                  which may vary based upon your use of the Website as We may
                  not require payment information until you wish to make a
                  purchase. By continuing to use this Website, you represent and
                  warrant that:
                </p>
                <div className="tc-sublist">
                  <p>
                    <span className="bold">10.1.1 </span>All information you
                    submit is accurate and truthful;
                  </p>
                  <p>
                    <span className="bold">10.1.2 </span>You have permission to
                    submit Payment Information where permission may be required;
                    and
                  </p>
                  <p>
                    <span className="bold">10.1.3 </span> You will keep this
                    information accurate and up-to-date. Your creation of an
                    Account is further affirmation of your representation and
                    warranty.
                  </p>
                </div>
                <p>
                  <span className="bold">10.2 </span>It is recommended that you
                  do not share your Account details, particularly your username
                  and password. We accept no liability for any losses or damages
                  incurred as a result of your Account details being shared by
                  you. If you use a shared computer, it is recommended that you
                  do not save your Account details in your internet browser.
                </p>
                <p>
                  <span className="bold">10.3 </span>If you have reason to
                  believe that your Account details have been obtained by
                  another person without consent, you should contact Us
                  immediately to suspend your Account and cancel any
                  unauthorised orders or payments that may be pending. Please be
                  aware that orders or payments can only be cancelled up until
                  provision of Services has commenced. In the event that an
                  unauthorised provision commences prior to your notifying Us of
                  the unauthorised nature of the order or payment, then you
                  shall be charged for the period from the commencement of the
                  provision of services until the date you notified us, and may
                  be charged for a billing cycle of one month.
                </p>
                <p>
                  <span className="bold">10.4 </span>When choosing your username
                  you are required to adhere to the terms set out above in
                  Clause 9. Any failure to do so could result in the suspension
                  and/or deletion of your Account.
                </p>
              </li>
              <li>
                <p className="bold">Termination and cancellation of accounts</p>
                <p>
                  <span className="bold">11.1 </span>Either{" "}
                  <span className="bold">MarCloud</span> or you may terminate
                  your Account. If We terminate your Account, you will be
                  notified by email and an explanation for the termination will
                  be provided. Notwithstanding the foregoing, We reserve the
                  right to terminate without giving reasons.
                </p>

                <p>
                  <span className="bold">11.2 </span>If We terminate your
                  Account, any current or pending orders or payments on your
                  Account will be cancelled, and provision of Services will not
                  commence.
                </p>
              </li>
              <li>
                <p className="bold">Services, pricing, and availability</p>
                <p>
                  <span className="bold">12.1 </span>Whilst every effort has
                  been made to ensure that all general descriptions of Services
                  available from <span className="bold">MarCloud</span>{" "}
                  correspond to the actual Services that will be provided to
                  you, We are not responsible for any variations from these
                  descriptions, as the exact nature of the Services may vary
                  depending on your individual requirements and circumstances.
                  This does not exclude Our liability for mistakes due to
                  negligence on Our part, and refers only to variations of the
                  correct Services, not different Services altogether. Please
                  refer to sub-Clause 13.8 for incorrect Services.
                </p>

                <p>
                  <span className="bold">12.2 </span>Where appropriate, you may
                  be required to select the required Plan of Services.
                </p>

                <p>
                  <span className="bold">12.3 </span>We neither represent nor
                  warrant that such Services will be available at all times and
                  cannot necessarily confirm availability until confirming your
                  Order. Availability indications are not provided on the
                  Website.
                </p>
                <p>
                  <span className="bold">12.4 </span>All pricing information on
                  the Website is correct at the time of going online. We reserve
                  the right to change prices and alter or remove any special
                  offers from time to time and as necessary.
                </p>
                <p>
                  <span className="bold">12.5 </span>In the event that prices
                  are changed during the period between an order being placed
                  for Services and Us processing that order and taking payment,
                  then the price that was valid at the time of the order shall
                  be used.
                </p>
              </li>
              <li>
                <p className="bold">Orders and provision of services</p>
                <p>
                  <span className="bold">13.1 </span>No part of this Website
                  constitutes a contractual offer capable of acceptance. Your
                  order constitutes a contractual offer that We may, at Our sole
                  discretion, accept. Our acceptance is indicated by Us sending
                  to you an order confirmation email. Only once We have sent you
                  an order confirmation email will there be a binding contract
                  between MarCloud and you.
                </p>
                <p>
                  <span className="bold">13.2 </span>Order confirmations under
                  sub-Clause 13.1 will be sent to you before the Services begin,
                  and shall contain the following information:
                </p>
                <div className="tc-sublist">
                  <p>
                    <span className="bold">13.2.1 </span>Confirmation of the
                    Services ordered including full details of the main
                    characteristics of those Services;
                  </p>
                  <p>
                    <span className="bold">13.2.2 </span>Fully itemised pricing
                    for the Services ordered including, where appropriate,
                    taxes, delivery and other additional charges;
                  </p>
                  <p>
                    <span className="bold">13.2.3 </span> Relevant times and
                    dates for the provision of the Services;
                  </p>{" "}
                  <p>
                    <span className="bold">13.2.4 </span> User credentials and
                    relevant information for accessing those services.
                  </p>
                </div>
                <p>
                  <span className="bold">13.3 </span> If We, for any reason, do
                  not accept your order, no payment shall be taken under normal
                  circumstances. In any event, any sums paid by you in relation
                  to that order will be refunded within 14 calendar days.
                </p>
                <p>
                  <span className="bold">13.4 </span>Payment for the Services
                  shall be taken via your chosen payment method, immediately for
                  any setup fee that corresponds to the service plan you
                  purchased, and at the same day of each subsequent month
                  (“billing cycle”) for charges accrued during the previous
                  month (“billing cycle”) and/or as indicated in the order
                  confirmation you received.
                </p>
                <p>
                  <span className="bold">13.5 </span>We aim to fulfill your
                  Order immediately or if not, within a reasonable period
                  following your Order, unless there are exceptional
                  circumstances. If we cannot fulfill your Order within a
                  reasonable period, we will inform you at the time you place
                  the Order by a note on the relevant web page or by contacting
                  you directly after you place your Order. Time is not of the
                  essence of the Contract, which means we will aim to fulfill
                  your Order within any agreed timescales but this is not an
                  essential term of the Contract and we will not be liable to
                  you if we do not do so. If the Services are to begin within 14
                  calendar days of Our acceptance of your order, at your express
                  request, you will be required to expressly acknowledge that
                  your statutory cancellation rights, detailed below in Clause
                  14, will be affected.
                </p>
                <p>
                  <span className="bold">13.6 </span>{" "}
                  <span className="bold">MarCloud</span> shall use all Our
                  reasonable endeavours to provide the Services with reasonable
                  skill and care, commensurate with best trade practice.
                </p>
                <p>
                  <span className="bold">13.7 </span> In the event that Services
                  are provided that are not in conformity with your order and
                  thus incorrect, you should contact Us immediately to inform Us
                  of the mistake. We will ensure that any necessary corrections
                  are made within five (5) working days. Additional terms and
                  conditions may apply to the provision of certain Services. You
                  will be asked to read and confirm your acceptance of any such
                  terms and conditions when completing your Order.
                </p>
              </li>
              <li>
                <p className="bold">Cancellation of orders and services</p>
                <p>
                  We want you to be completely satisfied with the Products or
                  Services you order from <span className="bold">MarCloud</span>
                  . If you need to speak to us about your Order, then please
                  contact Us by email at support@marcloudtechnologies.com.
                </p>
                <p>
                  <span className="bold">14.1 </span>As specified in sub-Clause
                  13.6, if the Services are to begin within the cooling off
                  period, you are required to make an express request to that
                  effect. By requesting that the Services begin within the 14
                  calendar day cooling off period, you acknowledge and agree to
                  the following:
                </p>
                <p>
                  <span className="bold">14.2 </span>
                </p>
                <div className="tc-sublist">
                  <p>
                    <span className="bold">14.2.1 </span>If the Services are
                    fully performed within the 14 calendar day cooling off
                    period, you will lose your right to cancel after the
                    Services are complete.
                  </p>
                  <p>
                    <span className="bold">14.2.2 </span> If you cancel the
                    Services after provision has begun but is not yet complete,
                    you will still be required to pay for the Services supplied
                    up until the point at which you inform Us that you wish to
                    cancel. The amount due shall be calculated in proportion to
                    the full price of the Services and the actual Services
                    already provided. Any sums that have already been paid for
                    the Services shall be refunded, subject to deductions
                    calculated in accordance with the foregoing. Refunds, where
                    applicable, will be issued within 5 working days and in any
                    event no later than 14 calendar days after you inform Us
                    that you wish to cancel.
                  </p>
                </div>
                <p>
                  <span className="bold">14.3 </span> Cancellation of Services
                  after the 14 calendar day cooling off period has elapsed shall
                  be subject to the specific terms governing those Services, and
                  may be subject to a minimum contract duration.
                </p>
              </li>
              <li>
                <p className="bold">Privacy</p>
                <p>
                  Use of the Website is also governed by Our{" "}
                  <a
                    href="https://training.marcloudconsulting.com/privacy"
                    target="_black"
                  >
                    Privacy Notice
                  </a>
                  , which is incorporated into these Terms and Conditions by
                  this reference. To view the Privacy Notice, please click on
                  the link above.
                </p>
              </li>
              <li>
                <p className="bold">How we use your personal information</p>
                <p>
                  <span className="bold">16.1 </span>All personal information
                  that We may collect (including, but not limited to, your name
                  and address) will be collected, used and held in accordance
                  with the provisions of the Data Protection Act 1998 and your
                  rights under that Act.
                </p>
                <p>
                  <span className="bold">16.2 </span> We may use your personal
                  information to:
                </p>
                <div className="tc-sublist">
                  <p>
                    <span className="bold">16.2.1 </span> Provide Our Services
                    to you;
                  </p>
                  <p>
                    <span className="bold">16.2.2 </span>Process your payment
                    for the Services; and
                  </p>
                  <p>
                    <span className="bold">16.2.3 </span> Inform you of new
                    products and services available from Us. You may request
                    that We stop sending you this information at any time.
                  </p>
                </div>
                <p>
                  <span className="bold">16.3 </span>In certain circumstances
                  (if, for example, you wish to purchase Services on credit),
                  and with your consent, We may pass your personal information
                  on to credit reference agencies. These agencies are also bound
                  by the Data Protection Act 1998 and should use and hold your
                  personal information accordingly.
                </p>
                <p>
                  <span className="bold">16.4 </span>We will not pass on your
                  personal information to any other third parties without first
                  obtaining your express permission.
                </p>
              </li>
              <li>
                <p className="bold">Disclaimers</p>
                <p>
                  <span className="bold">17.1 </span>We make no warranty or
                  representation that the Website will meet your requirements,
                  that it will be of satisfactory quality, that it will be fit
                  for a particular purpose, that it will not infringe the rights
                  of third parties, that it will be compatible with all systems,
                  that it will be secure and that all information provided will
                  be accurate. We make no guarantee of any specific results from
                  the use of our Service or Services.
                </p>
                <p>
                  <span className="bold">17.2 </span> No part of this Website is
                  intended to constitute advice, and the Content of this Website
                  should not be relied upon when making any decisions or taking
                  any action of any kind.
                </p>

                <p>
                  <span className="bold">17.3 </span> No part of this Website is
                  intended to constitute a contractual offer capable of
                  acceptance.
                </p>
                <p>
                  <span className="bold">17.4 </span>Whilst We use reasonable
                  endeavours to ensure that the Website is secure and free of
                  errors, viruses and other malware, you are strongly advised to
                  take responsibility for your own internet security, that of
                  your personal details and your computers.
                </p>
              </li>
              <li>
                <p className="bold">
                  Changes to the facilities and these terms and conditions
                </p>
                <p>
                  We reserve the right to change the Website, its Content, or
                  these Terms and Conditions at any time. You will be bound by
                  any changes to the Terms and Conditions from the first time
                  you use the Website following the changes. If We are required
                  to make any changes to these Terms and Conditions by law,
                  these changes will apply automatically to any orders currently
                  pending in addition to any orders placed by you in the future.
                </p>
                <p>
                  We recommend that you check this page regularly to keep
                  up-to-date. These Terms and Conditions were last updated on
                  05/04/24
                </p>
              </li>
              <li>
                <p className="bold">Availability of the website</p>
                <p>
                  <span className="bold">19.1 </span>The Website is provided “as
                  is” and on an “as available” basis.{" "}
                  <span className="bold"> MarCloud</span> uses industry best
                  practices to provide a high uptime, including a fault-tolerant
                  architecture hosted in cloud servers. We give no warranty that
                  the Website or Facilities will be free of defects and/or
                  faults, and we do not provide any kind of refund for outages.
                  We provide no warranties (express or implied) of fitness for a
                  particular purpose, accuracy of information, compatibility and
                  satisfactory quality.
                </p>
                <p>
                  <span className="bold">19.2 </span> We accept no liability for
                  any disruption or non-availability of the Website resulting
                  from external causes including, but not limited to, ISP
                  equipment failure, host equipment failure, communications
                  network failure, power failure, natural events, acts of war,
                  or legal restrictions and censorship.
                </p>
              </li>
              <li>
                <p className="bold">Limitation of liability</p>
                <p>
                  <span className="bold">20.1 </span> To the maximum extent
                  permitted by law, We accept no liability for any direct or
                  indirect loss or damage, foreseeable or otherwise, including
                  any indirect, consequential, special or exemplary damages
                  arising from the use of the Website or any information
                  contained therein. You should be aware that you use the
                  Website and its Content at your own risk.
                </p>
                <p>
                  <span className="bold">20.2 </span> Nothing in these Terms and
                  Conditions excludes or restricts{" "}
                  <span className="bold">MarCloud</span>'s liability for any
                  direct or indirect loss or damage arising out of the incorrect
                  provision of Services, or out of reliance on incorrect
                  information included on the Website.
                </p>
                <p>
                  <span className="bold">20.3 </span> In the event that any of
                  these terms are found to be unlawful, invalid or otherwise
                  unenforceable, that term is to be deemed severed from these
                  Terms and Conditions and shall not affect the validity and
                  enforceability of the remaining Terms and Conditions. This
                  term shall apply only within jurisdictions where a particular
                  term is illegal.
                </p>
              </li>
              <li>
                <p className="bold">No waiver</p>
                <p>
                  In the event that any party to these Terms and Conditions
                  fails to exercise any right or remedy contained herein, this
                  shall not be construed as a waiver of that right or remedy.
                </p>
              </li>
              <li>
                <p className="bold">Previous terms and conditions</p>
                <p>
                  In the event of any conflict between these Terms and
                  Conditions and any prior versions thereof, the provisions of
                  these Terms and Conditions shall prevail unless it is
                  expressly stated otherwise.
                </p>
              </li>
              <li>
                <p className="bold">Third party rights</p>
                <p>
                  Nothing in these Terms and Conditions shall confer any rights
                  upon any third party. The agreement created by these Terms and
                  Conditions is between you and{" "}
                  <span className="bold">MarCloud</span>.
                </p>
              </li>
              <li>
                <p className="bold">Communications</p>
                <p>
                  <span className="bold">24.1 </span> All notices /
                  communications shall be given to Us either by post to Our
                  Premises (see address above), or by email to
                  support@marcloudtechnologies.com.. Such notice will be deemed
                  received 3 days after posting if sent by first class post, the
                  day of sending if the email is received in full on a business
                  day, and on the next business day if the email is sent on a
                  weekend or public holiday.
                </p>
                <p>
                  <span className="bold">24.2 </span> We may from time to time,
                  if you opt to receive it, send you information about Our
                  products and/or services. If you do not wish to receive such
                  information, please click on the ‘Unsubscribe’ link in any
                  email which you receive from Us.
                </p>
              </li>
              <li>
                <p className="bold">Law and jurisdiction</p>
                <p>
                  These Terms and Conditions and the relationship between you
                  and <span className="bold">MarCloud</span> shall be governed
                  by and construed in accordance with the Law of England and
                  Wales, and <span className="bold">MarCloud</span> and you
                  agree to submit to the exclusive jurisdiction of England and
                  Wales. .
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermsAndConditions;
