import { gql } from "@apollo/client";
export const UserFragment = gql`
  fragment UserFragment on User {
    _id
    firstName
    lastName
    companyName
    email
    password
    clientId
    clientSecret
    instanceUrl
    lastLogin
    createdAt
    updatedAt
    confirmed
    passwordToken
    buids {
      _id
      buid
      shortName
      longName
      address1
      address2
      copyRightText
      privacyPolicy
      brandings {
        _id
        name
        buttonStyle
        highlightColor
        lightColor
        midColor
        darkColor
        copyFont
        displayFont
        preferenceLink
        logo
        facebook
        instagram
        twitter
        linkedIn
      }
    }
    purchases {
      _id
      purchaseDate
      stripeReference
      total
      currency
      products {
        _id
        name
        category
        internalReference
        price
        stripeId
        publishCount
        previewCount
      }
    }
    appSubscriptions {
      _id
      stripeReference
      totalCredits
      buidId
      lastLogin
    }
  }
`;
export const SIGNUP_USER = gql`
  mutation Mutation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $companyName: String
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      companyName: $companyName
    ) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;
export const CONFIRM_USER = gql`
  mutation CONFIRM_USER($email: String!) {
    confirmUser(email: $email)
  }
`;
export const CONFIRMATION_LOGIN_USER = gql`
  mutation CONFIRMATION_LOGIN_USER($email: String!, $password: String!) {
    confirmationLogin(email: $email, password: $password) {
      token
      user {
        __typename
        ...UserFragment
      }
    }
  }
  ${UserFragment}
`;
export const LOGIN_USER = gql`
  mutation Mutation($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      token
      user {
        __typename
        ...UserFragment
      }
    }
  }
  ${UserFragment}
`;

export const SAVE_PASSWORD_TOKEN = gql`
  mutation savePasswordToken($email: String!, $token: String!) {
    savePasswordToken(email: $email, token: $token) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const FORGET_PASSWORD = gql`
  mutation Mutation(
    $email: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    forgetPassword(
      email: $email
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const CHANGE_PASSWORD = gql`
  mutation Mutation(
    $email: String!
    $currentPassword: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    changePassword(
      email: $email
      currentPassword: $currentPassword
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const EDIT_DETAILS = gql`
  mutation EditDetails(
    $firstName: String
    $lastName: String
    $companyName: String
  ) {
    editDetails(
      firstName: $firstName
      lastName: $lastName
      companyName: $companyName
    ) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const EDIT_CONSUMER_DETAILS = gql`
  mutation Mutation(
    $clientId: String
    $clientSecret: String
    $instanceUrl: String
  ) {
    editConsumerDetails(
      clientId: $clientId
      clientSecret: $clientSecret
      instanceUrl: $instanceUrl
    ) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;
export const ADD_BUID = gql`
  mutation AddBuid($buid: AddBuidInput!) {
    addBuid(buid: $buid) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const UPDATE_BUID = gql`
  mutation Mutation($buidId: ID!, $buid: UpdateBuidInput!) {
    updateBuid(buidId: $buidId, buid: $buid) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;
export const DELETE_BUID = gql`
  mutation DeleteBuid($buidId: ID!) {
    deleteBuid(buidId: $buidId) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;
export const ADD_BRANDING = gql`
  mutation Mutation($buidId: ID!, $brandingInput: AddBrandingInput!) {
    addBranding(buidId: $buidId, brandingInput: $brandingInput) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;
export const UPDATE_BRANDING = gql`
  mutation Mutation(
    $buidId: ID!
    $brandingId: ID!
    $brandingInput: UpdateBrandingInput!
  ) {
    updateBranding(
      buidId: $buidId
      brandingId: $brandingId
      brandingInput: $brandingInput
    ) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;
export const DELETE_BRANDING = gql`
  mutation Mutation($buidId: ID!, $brandingId: ID!) {
    deleteBranding(buidId: $buidId, brandingId: $brandingId) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;
export const NEW_PREVIEW = gql`
  mutation Mutation($purchaseId: ID!, $productId: ID!) {
    newPreview(purchaseId: $purchaseId, productId: $productId) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;
export const NEW_PUBLISH = gql`
  mutation NewPublish($purchaseId: ID!, $productId: ID!) {
    newPublish(purchaseId: $purchaseId, productId: $productId) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const NEW_PURCHASE = gql`
  mutation Mutation($purchaseInput: PurchaseInput!) {
    newPurchase(purchaseInput: $purchaseInput) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const NEW_APP_SUBSCRIPTION = gql`
  mutation Mutation($stripeReference: String!) {
    newAppSubscription(stripeReference: $stripeReference) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const CHANGE_APP_BU = gql`
  mutation Mutation($buidId: String!) {
    changeAppBu(buidId: $buidId) {
      __typename
      ...UserFragment
    }
  }
  ${UserFragment}
`;
