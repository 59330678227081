import React from "react";
import "./hero.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const Hero = (props) => {
  return (
    <>
      <div className="stars-bg solution-hc">
        <Row className="section-top-padding div-width solution-hc-div ">
          <Col
            lg="8"
            sm="7"
            className="flex justify-center align solution-hc-left"
          >
            <div>
              <h1 className="solution-hc-left-h1-1">{props.subtitle}</h1>
              <h1 className="solution-hc-left-h1-2">{props.title}</h1>
              <p>{props.content}</p>
              {props.actionButtons}
            </div>
          </Col>
          <Col
            lg="4"
            sm="5"
            className="flex justify-center align solution-hc-right"
          >
            <div>
              <img src={props.heroImg} className={props.imgClass} alt="hero" />
            </div>
          </Col>
        </Row>
      </div>

      {/* ***********HERO SMALL SCREEN START*********** */}

      <div className="stars-bg solution-hc-ss">
        <Row className="div-width solution-hc-div">
          <Col className="flex justify-center align solution-hc-left">
            <div>
              <h1 className="solution-hc-left-h1-1">{props.subtitle}</h1>
              <h1 className="solution-hc-left-h1-2">{props.title}</h1>
              <div className="flex justify-center align solution-hc-right">
                <div>
                  <img
                    src={props.heroImg}
                    className={props.imgClass}
                    alt="hero"
                  />
                </div>
              </div>
              <p>{props.content}</p>
              {props.actionButtons}
            </div>
          </Col>
        </Row>
      </div>

      {/* ***********HERO SMALL SCREEN END*********** */}
    </>
  );
};

export default Hero;
