import React from "react";
import "./banner.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import darkMode from "../../../assets/logo/dark-mode.png";
import accountEngagement from "../../../assets/logo/account-engagement.png";
import mobileResponsive from "../../../assets/logo/mobile.png";
const Banner = () => {
  return (
    <>
      <div className="flex align justify-center">
        <Row className="et-icons-div">
          <Col lg="4" className="flex align et-icon-div">
            <img src={accountEngagement} size="50px" />
            <p>
              Configured for easy deployment and maintenance within Account
              Engagement
            </p>
          </Col>
          <Col lg="4" className="flex align et-icon-div">
            <img src={darkMode} size="50px" />
            <p>
              Optimised for dark mode to ensure comfortable viewing in low-light
              environments
            </p>
          </Col>

          <Col lg="4" className="flex align et-icon-div">
            <img src={mobileResponsive} size="50px" />
            <p>Fully responsive design for modern browsers and devices</p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Banner;
