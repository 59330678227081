import decode from "jwt-decode";

class AuthService {
  getProfile() {
    return decode(this.getToken());
  }

  loggedIn() {
    const token = this.getToken();
    return token && !this.isTokenExpired(token) ? true : false;
  }

  isTokenExpired(token) {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      localStorage.removeItem("id_token");
      sessionStorage.removeItem("accessToken");
      sessionStorage.removeItem("businessUnits");
      window.location.assign("/login");
      return true;
    }
    return false;
  }

  getToken() {
    return localStorage.getItem("id_token");
  }

  login(idToken, prevPage) {
    localStorage.setItem("id_token", idToken);
    if (prevPage) {
      window.location.assign(prevPage)
    } else {
      window.location.assign("/");
    }
    // window.history.back();
  }

  logout() {
    localStorage.removeItem("id_token");
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("businessUnits");
    window.location.reload();
  }
}

export default new AuthService();
