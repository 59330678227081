import * as React from "react";
import "./password.css";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useMutation } from "@apollo/client";
import { FORGET_PASSWORD } from "../../utils/mutations";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { AES, enc } from "crypto-js";
import { BiErrorCircle } from "react-icons/bi";
import loadingGif from "../../assets/loading1.gif";
import confirmation from "../../assets/img/img13.png";
import { InputGroup } from "react-bootstrap";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

const Reset = ({ changeBg }) => {
  const color = "transparent";
  React.useEffect(() => {
    changeBg(color);
  });
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
  const { user } = useParams();
  const decrypted_reset_data = AES.decrypt(
    decodeURIComponent(user),
    SECRET_KEY
  ).toString(enc.Utf8);
  const params = new URLSearchParams(decrypted_reset_data);
  const date = params.get("date");
  const email = params.get("email");
  const [valid, setValid] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  React.useEffect(() => {
    if (new Date().getTime() - date > 2 * 60 * 60 * 1000) {
      setValid(false);
    }
  }, []);
  const actionUrl =
    process.env.REACT_APP_PASSWORD_RESET_CONFIRMATION_FORM_HANDLER;
  const [forgetPassword, { error, data }] = useMutation(FORGET_PASSWORD);
  const [formData, setFormData] = React.useState({
    email: "",
    newPassword: "",
    confirmPasword: "",
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await forgetPassword({
        variables: {
          email: email,
          newPassword: formData.newPassword,
          confirmPassword: formData.confirmPasword,
        },
      });
      const prospect_data = {
        email: email,
        encryptedConfirmationString: "",
      };
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: actionUrl,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: prospect_data,
      };
      await axios.request(config);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };
  return valid ? (
    <div className="stars-bg">
      <Row className="div-width text-align-center flex justify-center password">
        <Col lg="5" md="8" sm="10" xs="10">
          <h2>Reset Password</h2>
          <div className="blue-line"></div>
          {data ? (
            <>
              <div className="password-subtitle">
                <p className="password-subtitle">Password reset successful</p>
                <img
                  src={confirmation}
                  width="200px"
                  className="password-subtitle"
                />
                <p>You can now log in with your new password.</p>
                <Link to="/login">
                  <button className="button4">Login</button>
                </Link>
              </div>
            </>
          ) : (
            <>
              <p className="password-subtitle">
                Please provide a new password below to reset your password.
              </p>
              <Form onSubmit={handleFormSubmit}>
                {error && (
                  <div className="flex justify-center align password-error">
                    <BiErrorCircle
                      size="40px"
                      color="orange"
                      className="password-error-icon"
                    />
                    {error.message}
                  </div>
                )}
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <InputGroup>
                    <Form.Control
                      type={showNewPassword ? "text" : "password"}
                      placeholder="New Password"
                      name="newPassword"
                      className="password-form-label"
                      required
                      onChange={handleChange}
                      value={formData.newPassword}
                    />
                    <div
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      className="flex align signup-show-password"
                    >
                      {showNewPassword ? (
                        <IoMdEyeOff color="black" size="25px" />
                      ) : (
                        <IoMdEye color="black" size="25px" />
                      )}
                    </div>
                  </InputGroup>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="formBasicConfirmPassword"
                >
                  <InputGroup>
                    <Form.Control
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      name="confirmPasword"
                      className="password-form-label"
                      required
                      value={formData.confirmPasword}
                      onChange={handleChange}
                    />
                    <div
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      className="flex align signup-show-password"
                    >
                      {showConfirmPassword ? (
                        <IoMdEyeOff color="black" size="25px" />
                      ) : (
                        <IoMdEye color="black" size="25px" />
                      )}
                    </div>
                  </InputGroup>
                </Form.Group>

                {loading ? (
                  <img src={loadingGif} width="100px" />
                ) : (
                  <Button
                    variant="primary"
                    type="submit"
                    className="button4 password-button"
                  >
                    Change Password
                  </Button>
                )}
              </Form>
            </>
          )}
        </Col>
      </Row>
    </div>
  ) : (
    <div className="stars-bg">
      <Row className="div-width text-align-center flex justify-center confirmation">
        <Col lg="5" md="8" sm="10" xs="11">
          <h2>Reset Password</h2>
          <div className="blue-line"></div>
          <p className="confirmation-subtitle">
            <BiErrorCircle size="30px" color="orange" /> This link is no longer
            valid.
          </p>
          <Link to="/ForgotPassword">
            <button className="button4">Send a new link</button>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default Reset;
