import * as React from "react";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import "./productCard.css"

const ProductCard = (props) => {
  return (
    <>
      <Col lg={6} xs={11} className="home-product-card-col">
        <div className="home-product-card">
          <Link to={props.link} target={props.target}>
          <div className="home-product-img">
            <img src={props.img} className={props.imgClass} alt="product"/>
          </div>
          <h3>
            {props.title}
            <IoIosArrowForward size="15px" />
          </h3>
          <p>{props.description}</p>
          </Link>
        </div>
      </Col>
    </>
  );
};
export default ProductCard;
